import {api} from "./api";

export const flowersApi = {
    createFlower: (flower) => {
        return api.post("/api/flowers", flower)
    },
    updateFlower: (id, flower) => {
        return api.put("/api/flower/" + id, flower)
    },
    removeFlower: (id) => {
        return api.delete("/api/flower/" + id);
    },
    getFlowers: (data) => {
        return api.post("/api/list-flowers", data);
    },
    getCombos: () => {
        return api.get("/api/combos")
    },
    upsertCombo: (data) => {
        return api.post("/api/combo/upsert", data)
    },
    removeCombo: (id) => {
        return api.delete(`/api/combo/${id}`)
    },
    viewCombo: (body) => {
        return api.post(`/api/combo/view`, body)
    }
};
