import React, { Fragment } from "react";
import sortBy from "lodash/sortBy";
import { formatNumber, getTotalBill, getTotalBillWithoutVAT } from "../../../common/common";
import { viaTypes } from "../../../common/constance";
import { getCSVData } from "../../order/excel";
import { CSVLink } from "react-csv";
import moment from "moment";
import { ColumnViewMore } from "../../../components/column-view-more/column-view-more";
import { DataTable } from "../../../components/data-table/data-table";
import sumBy from "lodash/sumBy";
import XLSX from "xlsx";
import {ExportSheet} from "react-xlsx-sheet";
export class ReportBillFrom extends React.Component {

    constructor(props) {
        super(props);
    }
    getCSVData(bills) {
        let header = [
            "Mã đơn hàng",
            "Ngày",
            "Tổng"  
        ];

        let csvData = [header];
        (bills || []).forEach(bill => {
            csvData.push([
                bill.bill_number,
                moment(bill.deliverTime).format("DD/MM/YYYY HH:mm"),
                getTotalBill(bill)
            ])
        }) ;

        return csvData;
    }
    render() {

        let { bills, loading } = this.props;

        let types = viaTypes.map((type) => ({
            name: type,
            bills: bills.filter(b => b.to && b.to.buyerFrom == type)
        }));

        let csvData = [[
            "Kênh mua hàng",
            "Số đơn"
        ]];

        for (let item of sortBy(types, c => -c.total)) {
            csvData.push([
                item.name,
                item.total
            ])
        }



        let columns = [{
            label: "Kênh Mua Hàng",
            display: (row) => row.name,
            width: "25%",
            minWidth: "150",
            sortBy: (row) => row.name
        }, {
            label: "Số Lượng",
            display: (row) => row.bills.length,
            width: "15%",
            minWidth: "150",
            sortBy: (row) => row.bills.length
        }, {
            label: "Doanh Thu (Có VAT)",
            display: (row) => formatNumber(sumBy(row.bills, b => getTotalBill(b))),
            width: "15%",
            minWidth: "50",
        }
        , {
            label: "Doanh Thu (Chưa VAT)",
            display: (row) => formatNumber(sumBy(row.bills, b => getTotalBillWithoutVAT(b))),
            width: "15%",
            minWidth: "50",
        }
        , {
            label: "Mã Đơn",
            display: (row) => row.bills.length > 0 && (
                <ColumnViewMore
                    viewMoreText={`Xem toàn bộ ${row.bills.length} đơn`}
                    isShowViewMoreText={true}
                    renderViewMoreBody={() =>
                        row.bills.map((bill, index) => (
                            <ColumnViewMore
                                key={index}
                                header={bill.bill_number}
                                renderViewMoreBody={() => (
                                    <Fragment>
                                        <div>
                                            Thời gian: {moment(bill.deliverTime).format("DD/MM/YYYY HH:mm")}
                                        </div>

                                        {bill.items.map((item, index) => (
                                            <div key={index}>
                                                <b>{item.quantity}</b> {item.flowerType} {item.name} {item.sale &&
                                                    <span className="text-primary">({item.sale}%)</span>} {item.vat ?
                                                        <span className="text-primary"> - {item.vat}% VAT</span> : ""}
                                                {item.color && (
                                                    <div
                                                        className="text-small">Màu: {item.color.split(", ").map((c, i) => (
                                                            <div key={i}
                                                                style={{
                                                                    background: c,
                                                                    display: "inline-block",
                                                                    marginRight: "5px",
                                                                    width: "15px",
                                                                    height: "10px"
                                                                }}
                                                            />
                                                        ))}</div>)}
                                                {item.size && (
                                                    <div className="text-small">Size: <b>{item.size}</b></div>)}
                                            </div>
                                        ))}

                                        {bill.vipSaleType && (
                                            <div>VIP: <b>{bill.vipSaleType}</b></div>
                                        )}

                                        {bill.promotion && (
                                            <span>{bill.promotion.name}: <b>{bill.promotion.discount}%</b></span>
                                        )}

                                        <div style={{
                                            marginTop: "10px"
                                        }}>
                                            {bill.to.paymentType == "Nợ" ?
                                                <span
                                                    className="text-danger"> Nợ: <b>{formatNumber(getTotalBill(bill))}</b></span> :
                                                <span>Tổng tiền: <b>{formatNumber(getTotalBill(bill))}</b></span>}
                                        </div>

                                        <div>Hình thức thanh toán: {bill.to.paymentType}</div>

                                        <div>
                                            Ghi chú: {bill.to.notes}
                                        </div>

                                        <div>
                                            Nội dung thiệp: {bill.to.cardContent}
                                        </div>

                                        <div style={{
                                            marginTop: "10px"
                                        }}>
                                            <div>Sale: <b>{bill.sales.length > 0 ? bill.sales.map(s => s.username).join(", ") : (bill.to || {}).saleEmp}</b>
                                            </div>
                                            <div>Florist: <b>{bill.florists.length > 0 ? bill.florists.map(s => s.username).join(", ") : (bill.to || {}).florist}</b>
                                            </div>
                                            <div>Ship: <b>{bill.ships.length > 0 && bill.ships.map(s => s.username).join(", ")}</b>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                viewMoreText="Chi Tiết"
                                isShowViewMoreText={true}
                            />
                        ))
                    }

                />
            ),
            width: "30%",
            minWidth: "300"
        },
        {
            label: "Tải xuống",
            display: (row) => (<div className="export-to-csv">
            <ExportSheet
                    dataType="Array-of-Arrays"
                    fileName={row.name}
                    dataSource={this.getCSVData(row.bills)}
                    xlsx={XLSX}
                >
                    <button className="btn btn-primary btn-small">
                        <span className="btn-text">Tải xuống</span>
                        <span className="loading-icon"><i className="fa fa-file-excel-o"/></span>
                    </button>
                </ExportSheet>
            </div>
                
           ),
           width: "10%",
           minWidth: "100"
        }
    ];


        return (
            <div>
                <div>Tổng doanh thu (chưa VAT): <strong className="text-primary text-bold"> {formatNumber(sumBy(types, b =>  sumBy(b.bills, bi=> getTotalBillWithoutVAT(bi))))}</strong></div>
                <div>Tổng doanh thu (có VAT): <strong className="text-primary text-bold"> {formatNumber(sumBy(types, b =>  sumBy(b.bills, bi=> getTotalBill(bi))))}</strong></div>
                <div>  Tổng số đơn hàng: <strong  className="text-primary text-bold">{formatNumber(sumBy(types, b => b.bills.length ))}</strong> </div>
                <DataTable
                    loading={loading}
                    columns={columns}
                    rows={sortBy(types, c => -c.bills.length)}
                />
            </div>

        );

    }
}
