import moment from "moment";
import classnames from "classnames";
import { ColumnViewMore } from "../../components/column-view-more/column-view-more";
import { ImgPreview } from "../../components/img-repview/img-preview";
import React, { Fragment } from "react";
import { securityFe } from "../../security/security-fe";
import { formatNumber } from "../../common/common";
import { premisesInfo } from "../../security/premises-info";
let oneDay = 24 * 60 * 60 * 1000;
let diffDays = (firstDate, secondDate, selectedBase) => {
    let _firstDate = new Date(firstDate);
    _firstDate.setHours(0, 0, 0, 0);

    let _secondDate = new Date(secondDate);
    _secondDate.setHours(0, 0, 0, 0);
    return Math.round((_firstDate.getTime() - _secondDate.getTime()) / (oneDay));

};

export const getReturned = (product, requests) => {
    let count = 0;
    for (let request of requests) {
        if (request.requestType == "return-to-supplier") {
            for (let item of request.items) {
                if (item.baseProductID == product._id) {
                    count += item.quantity
                }

                if (item.id == product._id) {
                    count += item.quantity
                }
            }
        }
    }

    return count;
}

export const getExported = (product, requests) => {
    let count = 0;
    for (let request of requests) {
        if (["return-to-base", "transfer-to-subwarehouse"].indexOf(request.requestType) > -1) {
            for (let item of request.items) {

                if (item.baseProductID == product._id) {
                    count -= item.quantity
                }

                if (item.id == product._id) {
                    count += item.quantity
                }
            }
        }
    }

    return count;
};


export const getError = (product, type, requests, selectedBase) => {
    let count = 0;

    for (let request of requests) {
        if ([type].indexOf(request.requestType) > -1) {
            for (let item of request.items) {
                if (item.id == product._id) {
                    count += item.quantity
                }
            }
        }
    }

    return count;
};


export const getUsed = (product, bills, selectedBase) => {
    let count = 0;
    let logic = (selectedBase == "all" || selectedBase == "super") ? (item) => item.baseProductID == product._id : (item) => item.baseProductID == product.baseProductID;

    for (let bill of bills) {
        for (let item of bill.selectedFlower) {
            if (logic(item)) count += item.quantity
        }
    }
    return count;
};




export const getColumns = (selectedBase, suppliers, requests = [], onViewHistory, bills = [], onUpdatePriceRow) => {
    // Ngày nhập kho - Mã - Tên- SLg Tồn - Giá gốc - Giá bán - Tổng Gtri Tồn - NCC - Loại
    let columns = [
        //1. Ngày nhập kho
        {
            label: "Ngày Nhập Kho",
            width: "5%",
            display: (row) => moment(row.created).format("DD/MM/YYYY HH:mm"),
            sortBy: (row) => row.created,
            minWidth: "150"
        },
        //2. Mã
        {
            label: "Mã",
            width: "5%",
            display: (row) => row.productID,
            sortBy: (row) => row.productID,
            minWidth: "150"
        },

        //3. Tên
        {
            label: "Tên",
            width: "25%",
            display: (row) => (
                <ColumnViewMore
                    header={(
                        <div className="product-name">
                            <ImgPreview src={row.image} /> {row.name}
                        </div>
                    )}
                    isShowViewMoreText
                    renderViewMoreBody={() => (
                        <Fragment>
                            <div className="info-item">
                                Màu: {row.colors.map((color, index) => (
                                    <div key={index}
                                        style={{
                                            background: color,
                                            height: "15px",
                                            width: "25px",
                                            display: "inline-block",
                                            marginRight: "5px",
                                            border: "1px solid #dedede"
                                        }}
                                    />
                                ))}
                            </div>

                            <div className="info-item">
                                Đơn Vị Tính: {row.unit}
                            </div>

                            <div className="info-item">
                                Dài: {row.lengthiness}
                            </div>
                        </Fragment>
                    )}
                />
            ),
            sortBy: (row) => row.name,
            minWidth: "250"
        },
       
        //4. Số lượng tồn
        {
            label: "Tồn",
            width: "5%",
            display: (row) => row.quantity,
            sortBy: (row) => row.quantity,
            minWidth: "100"
        },
        //5. Giá gốc
        {
            label: "Giá Gốc",
            width: "10%",
            display: (row) => securityFe.isHavePermission(["warehouse.view-ori-price"]) && formatNumber(row.oriPrice),
            sortBy: securityFe.isHavePermission(["warehouse.view-ori-price"]) ? (row) => row.oriPrice : null,
            minWidth: "100"
        },
        //6. Giá bán
        {
            label: "Giá Bán",
            width: "10%",
            display: (row) => formatNumber(row.price),
            sortBy: (row) => row.price,
            minWidth: "100"
        },
        //7. Tổng giá trị tồn
        {
            label: "Giá Trị Tồn",
            width: "10%",
            display: (row) => formatNumber(row.price * row.quantity),
            sortBy: (row) => row.price * row.quantity,
            minWidth: "100",
        },
        //8. NCC
        {
            label: "NCC",
            width: "15%",
            display: (row) => suppliers.find(s => s._id == row.supplierID).name,
            minWidth: "150",
            sortBy: (row) => suppliers.find(s => s._id == row.supplierID).name
        },
         // 9. Loại
         {
            label: "Loại",
            width: "5%",
            display: (row) => row.catalog,
            sortBy: (row) => row.catalog,
            minWidth: "100"
        },
        {
            label: "Hạn Sử Dụng",
            width: "5%",
            display: (row) => row.expireDate && (
                <div>
                    {moment(row.expireDate).format("DD/MM/YYYY")}
                    <br />
                    <span
                        className={classnames(diffDays(new Date(row.expireDate), new Date()) < 0 && "text-danger")}>{diffDays(new Date(row.expireDate), new Date())} ngày</span>
                </div>
            ),
            sortBy: (row) => row.expireDate,
            minWidth: "150"
        },
        //     {
        //     label: "Nhập",
        //     width: "5%",
        //     display: (row) => row.importedQuantity,
        //     sortBy: (row) => row.importedQuantity,
        //     minWidth: "100",
        //     hidden: () => selectedBase != "all" && selectedBase != "company"
        // },

        //     {
        //     label: "Xuất",
        //     width: "5%",
        //     display: (row) => row.exported,
        //     minWidth: "100"
        // },
        //     {
        //     label: "Hao Hụt",
        //     width: "5%",
        //     display: (row) => row.missing,
        //     minWidth: "120"
        // }, {
        //     label: "Hủy Hỏng",
        //     width: "5%",
        //     display: (row) => row.error,
        //     minWidth: "120"
        // }, {
        //     label: "Đã Bán",
        //     width: "5%",
        //     display: (row) => row.used,
        //     minWidth: "100"
        // },

       
        
        {
            label: "",
            width: "10%",
            display: (row) => <button className="btn btn-primary btn-small" onClick={() => onViewHistory(row)}>Lịch sử</button>,
            minWidth: "100",
        }];

    if (securityFe.isHavePermission(["warehouse.update-price"])) {
        columns.push({
            label: "",
            width: "5%",
            display: (row) => <button
                onClick={() => onUpdatePriceRow(row)}
                className="btn btn-primary btn-small"><i className="fa fa-pencil " /></button>,
            minWidth: "50",
        })
    }

    return columns;
};
