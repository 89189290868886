import React, { Fragment } from "react";
import { Form } from "../../components/form/form";
import { minLength, required } from "../../components/form/validations";
import { Input } from "../../components/input/input";
import { GoogleMaps } from "../../components/google-maps/google-maps";
export class ManageGeoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            location: null
        }
    }

    render() {

        let { onClose, onDismiss, content } = this.props;
        let address = `${content.ten}, ${content.thuoc_ten}, ${content.thuoc_ten2}, Việt nam`;
        let { location } = this.setState;


        return (
            <div className="app-modal-box ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{content.name}</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div style={{ marginBottom: '10px' }}>Địa chỉ:{address}</div>
                        <GoogleMaps
                            location={location}
                            address={address}
                            onChange={(location) => this.setState({ location })}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                        <button type="button" onClick={() => {
                            location && onClose(this.setState);
                        }} className="btn btn-primary">Lưu</button>
                    </div>


                </div>
            </div>
        );
    }
}
