import {api} from "./api";

export const collectionApi = {
    getCollections: () => {
        return api.get("/api/web-collections");
    },
    getCollections2: () => {
        return api.get("/api/web-collections2");
    },
    createCollection: (data) => {
        return api.post("/api/web-collection", data)
    },
    updateBulkCollection: (data) => {
        return api.put("/api/web-collection-update-bulk", data)
    },
    updateCollection: (id, data) => {
        return api.put(`/api/web-collection/${id}`, data)
    },
    getCollection: (id) => {
        return api.get(`/api/web-collection/${id}`)
    },
    removeCollection: (id) => {
        return api.delete(`/api/web-collection/${id}`)
    },
    getCollectionByKeyword: (keyword) => {
        return api.post("/api/find-collection-by-keyword", {keyword})
    },
    upsertProductToCollection: (data) => {
        return api.put("/api/upsert-product-to-collection", data);
    },
    getCollectionsViaProduct: (productID) => {
        return api.get(`/api/product-collections/${productID}`)
    }
};
