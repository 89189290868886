import React, {Fragment} from 'react';
import {Layout} from "../../components/layout/layout";
import {giftBillsWaitingApi} from "../../api/gift-bills-waiting-api";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import moment from "moment";
import {ColumnViewMore} from "../../components/column-view-more/column-view-more";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {formatNumber} from "../../common/common";
import sumBy from "lodash/sumBy";
import {ButtonGroup} from "../../components/button-group/button-group";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {shopApi} from "../../api/shop-api";
import {premisesInfo} from "../../security/premises-info";

export class GiftsExchangeRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customers : null ,
            giftBills: null,
            total: 0,
        };

    }

    accept(row){
        confirmModal.show({
            title : "Xác nhận phiếu",
            description : "Xác nhận phiếu này ?"
        }).then(()=>{
            giftBillsWaitingApi.acceptBill(row._id).then(({error})=>{
                if(error) {
                    confirmModal.alert(error);
                }
                this.table.refresh();
            })
        })
    }

    cancel(row){
        confirmModal.show({
            title : "Hủy phiếu",
            description : "Xác nhận hủy phiếu này ?"
        }).then(()=>{
            giftBillsWaitingApi.rejectBill(row._id).then(({error})=>{
                this.table.refresh();
            })
        })
    }
    convertAddress = (address) => address && address.length > 0 ? `, ${address}` : " ";

    getAddressString = (row) =>  `${row.to.receiverPlace} ${ this.convertAddress(row.to.district)} ${ this.convertAddress(row.to.city)}`

    render() {
        let { giftBills, total, customers} = this.state;
        let premises = premisesInfo.getPremises() ;

        const status = [{
            value: "pending",
            background: "",
            label: "Chờ xử lý",
            color: ""
        }, {
            value: "accepted",
            background: "rgb(29,201,183, .1)",
            label: "Xác nhận",
            color: "#1dc9b7"
        }, {
            value: "reject",
            background: "rgb(253,57,122, .1)",
            label: "Từ chối",
            color: "#fd397a"
        }];

        let columns =[
            {
                label: "Mã đơn hàng",
                width: "15%",
                display: (row) => row.gift_bill_number
            },
            {
                label : "Tên khách hàng",
                width: "15%",
                display : (row) => customers.find(c => c._id == row.customerId).customerName
            },
            {
                label : "Số điện thoại",
                width: "15%",
                display : (row) => customers.find(c => c._id == row.customerId).customerPhone
            }, {
                label: "Sản phẩm",
                width: "35%",
                display: (row) => (
                    <Fragment>
                        {row.items.map((item, index) => {
                            return (
                                <ColumnViewMore
                                    key={index}
                                    header={
                                        <div className="product-name">
                                            <ImgPreview src={item.image}/> {item.quantity} - {item.name}
                                        </div>
                                    }
                                    renderViewMoreBody={() => (
                                        <Fragment>

                                            <div className="info-item">
                                                Đơn Vị Tính: {item.point}
                                            </div>

                                            { item.lengthiness && (
                                                <div className="info-item">
                                                    Chiều Dài Cành Hoa: {item.lengthiness}
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                    viewMoreText="Chi Tiết"
                                    isShowViewMoreText
                                />
                            )
                        })}

                        <div style={{
                            fontSize: "11px",
                            marginTop: "10px"
                        }}>
                            Tổng điểm: {formatNumber(sumBy(row.items, item => item.quantity * item.point))}
                        </div>
                    </Fragment>
                ),
                minWidth: "250",
            },{
                label : "Địa điểm",
                width: "15%",
                display : (row) => <div>
                    { row.to.paymentType  === "COD" ? this.getAddressString(row) : premises.find(f => f._id === row.premisesID).name }
                </div>
            },
            {
                label: "Hình thức",
                width: "15%",
                display : (row) => <div>
                    {row.to.paymentType}
                </div>

            },
            {
                label : "Thời gian",
                width: "15%",
                display : (row) => <div>
                    {moment(row.created).format("DD/MM/YYYY HH:mm")}
                    <br/>
                </div>
            }, {
                label: "Trạng Thái",
                width: "10%",
                display: (row) => (
                     <span>{row.status === "pending" ? "Chờ xử lý" : row.status === "accepted" ? "Đã xác nhận" : "Đã hủy"} </span>
                ),
                minWidth: "100",
            }, {
                label: "",
                width: "5%",
                display: (row) => row.status === "pending" ? (
                    <ButtonGroup
                        actions={[{
                            name: "Xác nhận",
                            icon: <i className="fa fa-pencil"/>,
                            click: () => this.accept(row)
                        },{
                            name : "Huỷ đơn",
                            icon : <i className="fa fa-times text-danger"/>,
                            click : () => this.cancel(row)
                        }

                        ]}
                    />) : null
                ,
                minWidth: "50"
            }

        ]

        return(
            <Layout
                activeRoute="Đổi quà"
            >
                <div className="card gift-exchange-route">
                    <div className="card-title">
                        Đổi quà
                    </div>

                    <PaginationDataTable
                        total={total}
                        columns={columns}
                        rows={giftBills}
                        rowStyling={(row) => ({background: status.find(r => r.value == row.status).background})}
                        ref={elem => this.table = elem}
                        api={({keyword, page, sortKey, isDesc}) => {
                            return giftBillsWaitingApi.getBills({
                                keyword,
                                skip: (page - 1) * 15,
                                sortKey,
                                isDesc
                            }).then(({customers, giftBills, total}) => {
                                this.setState({customers, giftBills, total});
                                return Promise.resolve();
                            })
                        }}
                    />
                </div>
            </Layout>
        )
    }
}