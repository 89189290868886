import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {MyCustomUploadAdapterPlugin} from "./ck-editor-image";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import classnames from "classnames";

export class WYSIWYGEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focus: false
        }
    }

    render() {

        let {label, value, onChange, className, error} = this.props;
        let {focus} = this.state;

        return (
            <div className={classnames("wysiwyg-editor", className, error && "has-error", focus && "focus")}>

                <div
                    className="label"
                >
                    {label}
                </div>

                <CKEditor
                    editor={ClassicEditor}
                    data={value}
                    config={{
                      
                        extraPlugins: [MyCustomUploadAdapterPlugin]}}
                    onChange={(event, editor) => onChange(editor.getData())}
                    onBlur={(event, editor) => this.setState({focus: false})}
                    onFocus={(event, editor) => this.setState({focus: true})}
                    
                />
            </div>
        );
    }
}
