import React from "react";
import {DatePicker} from "../../components/date-picker/date-picker";
export class ExtendWalletModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: props.customer
        }
    }

    render() {

        let {customer, saving} = this.state;
        let {onClose, onDismiss} = this.props;

        return (
            <div className="app-modal-box">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title">Gia hạn thẻ cho {customer.customerName}</h6>
                        <button type="button" className="close" onClick={() => onClose()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <DatePicker
                            label="Thời hạn mới"
                            value={new Date(customer.e_wallet_expire_date)}
                            onChange={(v) => this.setState({customer: {...customer, e_wallet_expire_date: v}})}
                        />
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-link  ml-auto" data-dismiss="modal" onClick={() => onDismiss()}>Thôi</button>
                        <button type="button" className="btn btn-primary"
                                disabled={saving}
                                onClick={() => {
                            this.setState({saving: true});
                            onClose(customer);
                        }}>
                            <span className="btn-text">Xác nhận</span>
                            {saving && <span className="loading-icon"><i
                                className="fa fa-spinner fa-pulse"/></span>}
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}
