import {api} from "./api";

export const bannerApi = {
    getBanners: () => {
        return api.get("/api/banners-dvh");
    },
    createBanner: (data) => {
        return api.post("/api/banner-dvh", data)
    },
    updateBulkBanners: (data) => {
        return api.put("/api/banner-dvh-update-bulk", data)
    },
    updateBanner: (id, data) => {
        return api.put(`/api/banner-dvh/${id}`, data)
    },
    removeBanner: (id) => {
        return api.delete(`/api/banner-dvh/${id}`)
    }
};
