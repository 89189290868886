const sumBy = require("lodash/sumBy");


const getTotalBillCommon = (bill) => {

    if (bill.to && bill.to.paymentType && bill.to.paymentType.indexOf("Free") > -1) return 0;

    if (bill.status == "Huỷ Đơn") return 0;

    if (bill.e_wallet_used) return 0;

    let totalBillItems = sumBy(bill.items, item => {
        let price = item.price * item.quantity;
        if (item.sale) {
            price = price - price * item.sale / 100
        }

        if (item.vat) {
            price = price + price * item.vat / 100
        }

        return price;
    });

    if (bill.payOwe && bill.customerInfo) {
        totalBillItems += bill.customerInfo.spend.totalOwe
    }

    let discount = 0;

    if (bill.vipSaleType == "Giảm giá 5%") {
        discount += 5;
    }

    if (bill.vipSaleType == "Giảm giá 10%") {
        discount += 10;
    }

    if (bill.vipSaleType == "Giảm giá 20%") {
        discount += 20;
    }


    if (bill.vipSaleType == "Giảm giá 30%") {
        discount += 30;
    }

    if (bill.promotion && bill.promotion.discount) {
        discount += bill.promotion.discount;
    }

    if (bill.discountCode && bill.discountCode.codeType == "percent") {
        discount += bill.discountCode.discountNumber
    }

    let ret = Math.round(totalBillItems - totalBillItems * Math.min(discount, 100) / 100);


    if (bill.discountCode && bill.discountCode.codeType == "number") {
        ret = Math.max(0, ret - bill.discountCode.discountNumber);
    }

    ret = ret - ((bill.wallet_points_used || 0) * 1000)

    return Math.max(0, ret)

};

exports.getTotalBillCommon = getTotalBillCommon;
