import React, { Fragment } from "react";
import { Input } from "../../components/input/input";
import { AutoComplete } from "../../components/auto-complete/auto-complete";
import { webManageProductApi } from "../../api/web-manage-product-api";
import { uniqBy } from "lodash";
import { formatNumber } from "../../common/common";
import { DataTable } from "../../components/data-table/data-table";
import { PictureUpload } from "../../components/picture-upload/picture-upload";
import { blogApi } from "../../api/blog-api";
import { bannerApi } from "../../api/banner-api";
import { Checkbox } from "../../components/checkbox/checkbox";
import { productApi } from "../../api/product-api";
import { collectionApi } from "../../api/collection-api";
import { Select } from "../../components/select/select";
import { customerApi } from "../../api/customer-api";
export class BannerManageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            banner: props.banner,
            linkKeyword: "",
            selectedLink: {},
            loading: props.banner.linkType == "Hyper link" ? false : props.banner.linkType
        };

        if (this.state.banner.linkType && this.state.banner.linkType !== "Hyper link") {
            let detailApi = this.options.find(o => o.value == this.state.banner.linkType).detailApi;
            detailApi(this.state.banner.linkID).then((resp) => {

                if (resp.collection) {
                    this.setState({
                        linkKeyword: resp.collection.title,
                        loading: false,
                        selectedLink: resp.collection
                    })
                } else {
                    this.setState({
                        linkKeyword: resp.title,
                        loading: false,
                        selectedLink: resp
                    })
                }
            })
        }
    }

    submit() {
        let { banner } = this.state;
        let { onClose } = this.props;

        this.setState({ saving: true });

        if (banner._id) {
            bannerApi.updateBanner(banner._id, banner).then(() => {
                onClose()
            })
        } else {
            bannerApi.createBanner(banner).then(() => {
                onClose()
            });
        }
    }

    options = [{
        value: null
    }, {
        value: "Bộ sưu tập",
        api: collectionApi.getCollectionByKeyword,
        detailApi: collectionApi.getCollection
    }, {
        value: "Blog",
        api: blogApi.getBlogByKeyword,
        detailApi: blogApi.getBlog
    }, {
        value: "Hyper link",
        text: true,
    }];

    render() {

        let { banner, saving, loading } = this.state;
        let { onDismiss } = this.props;

        return (
            <div className="blog-preview-modal app-modal-box">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{banner._id ? "Sửa" : "Thêm"} banner</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Input
                            label="Tên"
                            value={banner.title}
                            onChange={(e) => this.setState({ banner: { ...banner, title: e.target.value } })}
                        />

                        <PictureUpload
                            label="Ảnh"
                            value={banner.imageSrc}
                            onChange={(imageSrc) => this.setState({ banner: { ...banner, imageSrc } })}
                            noResize
                        />


                        {loading ? (
                            <span className="loading-icon"><i className="fa fa-spinner fa-pulse" /></span>
                        ) : (
                            <Fragment>
                                <Select
                                    label="Loại Link"
                                    value={banner.linkType || null}
                                    list={this.options.map(o => o.value)}
                                    onChange={(linkType) => this.setState({ banner: { ...banner, linkType, linkID: null }, linkKeyword: "" })}
                                    displayAs={(linkType) => {
                                        if (!linkType) return "Không";
                                        return linkType;
                                    }}
                                />

                                {banner.linkType === "Hyper link" ?
                                    <div>
                                        <Input
                                            label="Hyper link"
                                            value={banner.hyper_link}
                                            onChange={(e) => this.setState({ banner: { ...banner, hyper_link: e.target.value } })}
                                        />
                                        <Input
                                            label="Name button"
                                            value={banner.name}
                                            onChange={(e) => this.setState({ banner: { ...banner, name: e.target.value } })}
                                        />
                                    </div>
                                    : banner.linkType && (
                                        <AutoComplete
                                            label={`Chọn ${banner.linkType.toLowerCase()}`}
                                            asyncGet={(keyword) => {
                                                this.setState({ banner: { ...banner, linkID: null } });
                                                if (keyword.length > 0) {
                                                    return this.options.find(o => banner.linkType == o.value).api(keyword).then((resp) => resp)
                                                }
                                                return Promise.resolve([])
                                            }}
                                            ref={input => this.input = input}
                                            onSelect={(selectedLink) => {
                                                this.setState({
                                                    selectedLink,
                                                    banner: { ...banner, linkID: selectedLink._id }
                                                });
                                                this.input.setValue(selectedLink.title)
                                            }}
                                            onChange={(value) => {
                                                this.setState({ selectedLink: {}, linkKeyword: value })
                                            }}
                                            objectKey="linkKeyword"
                                            object={this.state}
                                            displayAs={(selectedLink) => selectedLink.title}
                                            noPopup
                                        />
                                    )}
                            </Fragment>
                        )}

                    </div>


                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                        <button
                            onClick={() => this.submit()}
                            disabled={banner.title.length == 0 || !banner.imageSrc || saving || (banner.linkType && !banner.linkID && !banner.hyper_link) || loading}
                            className="btn btn-primary">
                            <span className="btn-text">Lưu</span>
                            {saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse" /></span>}
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}
