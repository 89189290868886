import React from "react";
import {Input} from "../../components/input/input";
import {AutoComplete} from "../../components/auto-complete/auto-complete";
import {webManageProductApi} from "../../api/web-manage-product-api";
import {formatNumber} from "../../common/common";
import {DataTable} from "../../components/data-table/data-table";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {uniqBy} from "lodash";
import {collectionApi} from "../../api/collection-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";

export class CollectionManageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collection: props.collection,
            keyword: ""
        }
        
    }

    submit() {
        this.setState({saving: true});

        let {collection} = this.state;

        if (collection._id) {
            collectionApi.updateCollection(collection._id, {
                ...this.state.collection,
                productIDSObject: this.state.collection.productIDSObject.map((p, index) => ({
                    productID: p._id,
                    sortIndex: index
                })),
            }).then((resp) => {
                this.setState({saving: false});

                if (resp.error) {
                    confirmModal.alert(resp.error);

                } else {
                    this.props.onClose();
                }

            })
        } else {
            collectionApi.createCollection({
                ...this.state.collection,
                productIDSObject: this.state.collection.productIDSObject.map((p, index) => ({
                    productID: p._id,
                    sortIndex: index
                })),
            }).then((resp) => {
                this.setState({saving: false});

                if (resp.error) {
                    confirmModal.alert(resp.error);
                } else {
                    this.props.onClose();
                }
            })
        }


    }

    render() {

        let {collection, saving, keyword} = this.state;
        let {onDismiss} = this.props;

        const columns = [{
            label: "Mã SP",
            width: "30%",
            display: (row) => (
                <div>{row.productID}
                    <div>
                        <ImgPreview
                            src={row.images[0]}
                            style={{
                                width: "100%",
                                marginTop: "10px"
                            }}
                        />
                    </div>
                </div>
            ),
            minWidth: "150"
        }, {
            label: "Tên",
            width: "40%",
            display: (row) => (
                <div>
                    <div><b>{row.name}</b></div>
                    <div style={{fontWeight: "normal", fontSize: "13px", marginTop: "10px"}}>{row.description}</div>
                </div>
            ),
            minWidth: "150"
        }, {
            label: "Giá",
            width: "20%",
            display: (row) => formatNumber(row.price),
            sortKey: "price",
            minWidth: "100"
        }, {
            label: "",
            width: "10%",
            display: (row) => <button className="btn btn-danger btn-small" onClick={() => {
                this.setState({collection: {...collection, productIDSObject: collection.productIDSObject.filter(p => p._id != row._id)}})
            }}><i className="fa fa-trash"/></button>,
            minWidth: "50"
        }];


        return (
            <div className="blog-preview-modal app-modal-box">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{collection._id ? "Sửa" : "Thêm"} bộ sưu tập</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Input
                            label="Tên"
                            value={collection.title}
                            onChange={(e) => this.setState({collection: {...collection, title: e.target.value}})}
                        />

                        <AutoComplete
                            label="Tên, mã sản phẩm"
                            asyncGet={(keyword) => {
                                if (keyword.length > 0) {
                                    return webManageProductApi.getProducts({
                                        keyword,
                                        skip: 0
                                    }).then(({flowers}) => flowers)
                                }
                                return Promise.resolve([])
                            }}
                            onSelect={(foundProduct) => {
                                this.setState({keyword: "",
                                    collection: {
                                        ...collection,
                                        productIDSObject: uniqBy(collection.productIDSObject.concat(foundProduct), c => c._id)
                                    }
                                })
                            }}
                            onChange={(value) => this.setState({keyword: value})}
                            objectKey="keyword"
                            object={this.state}
                            displayAs={(product) => `${product.productID} - ${product.name} - ${formatNumber(product.price)}`}
                            noPopup
                        />

                        <div className="label">
                            Danh sách sản phẩm
                        </div>
                    </div>

                    <DataTable
                        rows={collection.productIDSObject}
                        columns={columns}
                        sortable
                        onChangeRows={(productIDSObject) => {

                            this.setState({collection: {...collection, productIDSObject}});
                        }}
                    />


                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                        <button
                            onClick={() => this.submit()}
                            disabled={collection.title.length == 0 || collection.productIDSObject.length == 0 || saving}
                            className="btn btn-primary">
                            <span className="btn-text">Lưu</span>
                            {saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}
