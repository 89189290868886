import React from "react";
import {DataTable} from "../../../components/data-table/data-table";
import {ColumnViewMore} from "../../../components/column-view-more/column-view-more";
import sortBy from "lodash/sortBy";
import {getCSVData} from "../../order/excel";
import {CSVLink} from "react-csv";
import {sumBy} from "lodash";
import {getTotalBill} from "../../../common/common";
import {premisesInfo} from "../../../security/premises-info";
import sum from "lodash/sum";
import XLSX from "xlsx";
import {ExportSheet} from "react-xlsx-sheet";
export class ReportCustomerBuyCount extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let {groupedBills, loading, customers} = this.props;

        const groups = [
            {label: "0 -> 1", logic: (b) => b.value.length <= 1},
            {label: "2 -> 4", logic: (b) => b.value.length <= 4 && b.value.length >= 2},
            {label: "5 -> 10", logic: (b) => b.value.length <= 10 && b.value.length >= 5},
            {label: "11+", logic: (b) => b.value.length >= 11}
        ];


        let columns = [{
            label: "Số Lần Mua",
            display: (row) => row.label,
            width: "25%",
            minWidth: "150",
            sortBy: (row) => row.label
        }, {
            label: "Số Khách",
            display: (row) => <span>{groupedBills.filter(b => row.logic(b)).length} <span className="text-primary">({(groupedBills.filter(b => row.logic(b)).length / (customers.length || 1)).toFixed(2) * 100}%)</span></span>,
            sortBy: (row) => groupedBills.filter(b => row.logic(b)).length,
            width: "25%",
            minWidth: "150",
        }, {
            label: "Chi Tiết",
            display: (row) => groupedBills.filter(b => row.logic(b)).length > 0 && (
                <ColumnViewMore
                    viewMoreText={`Xem toàn bộ ${groupedBills.filter(b => row.logic(b)).length} khách`}
                    renderViewMoreBody={() => sortBy(groupedBills.filter(b => row.logic(b)), c => -c.value.length).map((customer, index) => {
                        let c = customers.find(c => c._id == customer.key);
                        if (c) {
                            return (
                                <div key={index} className="info-item">
                                    {c.customerName} - {c.customerPhone} <span className="text-primary">({customer.value.length} lần)</span>
                                </div>
                            )

                        }
                        return null;
                    })}
                    isShowViewMoreText
                />
            ),
            width: "25%",
            minWidth: "150",
        }, {
            label: "Xuất Excel",
            display: (row) => {

                return (
                    <ExcelButton
                        customers={sortBy(groupedBills.filter(b => row.logic(b)), c => -c.value.length)}
                        customersInfo={customers}
                    />
                )
            },
            width: "25%",
            minWidth: "150",
        }];


        return (
            <DataTable
                rows={groups}
                columns={columns}
                loading={loading}
            />
        );
    }
}


class ExcelButton extends React.Component {

    render() {

        let {customers, customersInfo} = this.props;
        let premises = premisesInfo.getPremises();

        const getPayOfPremises = (premises_id, bills) => {
            const customerBills = bills.filter(b => b.premises_id == premises_id);
            return sum(customerBills.map(b => getTotalBill(b)))
        };


        let csvData = [[
            "Tên khách",
            "SĐT",
            "Số lần mua",
            "Tổng chi",
            ...premises.map(p => p.name)
        ]];

        for (let customer of customers) {
            let c = customersInfo.find(c => c._id == customer.key);
            if (c) {
                csvData.push([
                    c.customerName,
                    c.customerPhone,
                    customer.value.length,
                    sumBy(customer.value, b => getTotalBill(b)),
                    ...premises.map(p => getPayOfPremises(p._id, customer.value))
                ])
            }
        }

        return (
            <>
                <ExportSheet
                    dataType="Array-of-Arrays"
                    fileName={`bao-cao`}
                    dataSource={csvData}
                    xlsx={XLSX}
                >
                    <button className="btn btn-primary btn-small">
                        <span className="btn-text">Tải xuống</span>
                        <span className="loading-icon"><i className="fa fa-file-excel-o"/></span>
                    </button>
                </ExportSheet>
            </>
        );
    }
}
