import React from "react";
import {Layout} from "../../components/layout/layout";
import {flowersApi} from "../../api/flowers-api";
import {DataTable} from "../../components/data-table/data-table";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {modals} from "../../components/modal/modals";
import {ManageComboModal} from "./manage-combo-modal";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
export class ManageComboRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            combos: null
        }

       this.refresh();
    }

    refresh = async () => {
        const {combos, flowers} = await flowersApi.getCombos()
        this.setState({combos, flowers})
    }


    add() {
        const modal = modals.openModal({
            content: (
                <ManageComboModal
                    combo={{name: "", flowerIDs: []}}
                    onClose={async (combo) => {
                        await flowersApi.upsertCombo(combo);
                        await this.refresh();
                        modal.close();
                    }}
                    onDismiss={() => {
                        modal.dismiss();
                    }}
                />
            )
        })
    }

    edit(row) {
        let {flowers} = this.state;
        const modal = modals.openModal({
            content: (
                <ManageComboModal
                    combo={{...row, flowerIDs: row.flowerIDs.map(id => flowers.find(f => f._id == id))}}
                    onClose={async (combo) => {
                        await flowersApi.upsertCombo(combo);
                        await this.refresh();
                        modal.close();
                    }}
                    onDismiss={() => {
                        modal.dismiss();
                    }}
                />
            )
        })

    }

    render() {
        let {combos, flowers} = this.state;

        const columns = [{
            label: "Tên",
            display: (v) => v.name,
            width: "40%"
        }, {
            label: "Sản phẩm",
            display: (v) => v.flowerIDs.map((id, index) => {
                let flower = flowers.find(f => f._id == id)

                return (
                    <div className="product-name" style={{marginBottom: "10px"}} key={index}>
                        <ImgPreview src={flower.image}/> {flower.name}
                    </div>
                )
            }),
            width: "50%"
        }, {
            label: "",
            width: "10%",
            display: (row) => (
                <>
                    <button
                        style={{marginRight: "10px"}}
                        onClick={() => this.edit(row)}
                        className="btn btn-primary btn-small"><i className="fa fa-pencil "/></button>

                    <button
                        onClick={() => {
                            confirmModal.show({title: "Xóa combo", description: "Bạn muốn xóa combo này không?"}).then(async () => {
                                this.setState({combos: combos.filter(c => c._id != row._id)})
                                await flowersApi.removeCombo(row._id);
                            })
                        }}
                        className="btn btn-danger btn-small"><i className="fa fa-trash "/></button>
                </>
            ),
            minWidth: "50",
        }]

        return (
            <Layout
                activeRoute="Cài đặt combo"
            >
                <div className="card warehouse-route products-route">
                    <div className="card-title">
                        Cài đặt Combo
                    </div>

                    <div className="card-body">
                        <button className="btn btn-primary"
                                onClick={() => this.add()}
                        >
                            <span className="btn-text">Thêm Combo</span>
                        </button>
                    </div>

                    <DataTable
                        rows={combos}
                        columns={columns}
                        loading={!combos}
                    />
                </div>
            </Layout>
        );
    }
}
