import React from "react";
import {sortBy} from "lodash";
import {timeParser} from "../../common/common";
import classnames from "classnames";

export class MessageList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let {onSelect, conversations, selectedConversation} = this.props;

        return (
            <div className="message-list-side">
                { !conversations ? (
                    <div className="message-item"
                         onClick={() => onSelect({item: "hehe"})}
                    >
                        <div className="loading-text"/>
                        <div className="loading-text truncate"/>
                    </div>
                ) : (
                    sortBy(conversations, c => c.lastUpdate).map((conversation, index) => (
                        <div className={classnames("message-item", conversation.conversationID == selectedConversation?.conversationID && "selected")}
                             key={index}
                             onClick={() => onSelect(conversation)}
                        >
                            <div className="message-name">
                                {conversation.name} - {conversation.phone}
                            </div>
                            <div className="message-phone">
                        <span className="message-time">
                            {timeParser(conversation.lastUpdate)}
                        </span>
                            </div>

                            <div className="message-detail">
                                {conversation.lastMessage}
                            </div>
                        </div>
                    ))
                )}




            </div>
        );
    }
}
