import React from 'react';
import ReactDOM from 'react-dom';
import {AppRouter} from "./app/router/app-router";
import {securityFe} from "./app/security/security-fe";
import "./style/style.scss";
securityFe.init().then(() => {
    ReactDOM.render(<AppRouter />, document.getElementById('root'));
});





