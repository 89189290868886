import {api} from "./api";

export const webProductTypeApi = {
    get: () => {
        return api.get("/api/web-product-type");
    },
    update: (id, data) => {
        return api.put(`/api/web-product-type/${id}`, data);
    },
    create: (data) => {
        return api.post("/api/web-product-type", data);
    },
    delete: (id) => {
        return api.delete(`/api/web-product-type/${id}`);
    },
    createCatalogFather: (data) => {
        return api.post(`/api/catalog-father`, data);
    },
    updateCatalogFather: (id, data) => {
        return api.put(`/api/catalog-father/${id}`, data);
    },
    removeCatalogFather: (id) => {
        return api.delete(`/api/catalog-father/${id}`)
    }
};
