import React, {Fragment} from "react";
import {Form} from "../../components/form/form";
import {Input} from "../../components/input/input";
import {required} from "../../components/form/validations";

export class ManageFatherModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            catalog: props.catalog
        }
    }

    render() {

        let {catalog, saving} = this.state;
        let {onDismiss, onClose} = this.props;

        let validations = [
            {"name" : [required("Tên danh mục")]},
            {"description" : [required("Mô tả danh mục")]},
        ];

        return (
            <div className="app-modal-box ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{catalog._id ? `Sửa danh mục` : "Thêm danh mục"}</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <Form
                        onSubmit={() => {
                            this.setState({saving: true});
                            onClose(catalog);
                        }}
                        formValue={catalog}
                        validations={validations}
                        render={(getInvalidByKey) => (
                            <Fragment>
                                <div className="modal-body">
                                    <Input
                                        value={catalog.name}
                                        onChange={(e) => this.setState({catalog: {...catalog, name: e.target.value}})}
                                        label="Tên danh mục"
                                        error={getInvalidByKey("name")}
                                    />

                                    <Input
                                        value={catalog.description}
                                        onChange={(e) => this.setState({catalog: {...catalog, description: e.target.value}})}
                                        label="Mô tả"
                                        textArea
                                        error={getInvalidByKey("description")}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                                    <button type="submit" className="btn btn-primary">
                                        <span className="btn-text">Lưu</span>
                                        { saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    />


                </div>
            </div>
        );
    }
}
