import React, {Fragment} from "react";
import {flowersApi} from "../../../api/flowers-api";
import {premisesInfo} from "../../../security/premises-info";
import {DataTable} from "../../../components/data-table/data-table";
import {Checkbox} from "../../../components/checkbox/checkbox";
import moment from "moment";
import {ImgPreview} from "../../../components/img-repview/img-preview";
import {formatNumber} from "../../../common/common";
import {ColumnViewMore} from "../../../components/column-view-more/column-view-more";
export class ComboViewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: null,
            flowers: null,
            selectedProducts: []
        }

        flowersApi.viewCombo({id: props.combo._id, premisesID: premisesInfo.getActivePremise()._id}).then(({products, flowers}) => {
            this.setState({products: products.map((product) => {
                const flower = flowers.find(f => f._id == product.parentID);
                return {
                    ...flower,
                    ...product,
                }
            })})
        })
    }

    render() {

        let {combo, onDismiss, onClose} = this.props;
        let {products, flowers, selectedProducts} = this.state;

        const columns = [{
            label: "",
            display: (row) => {
                const checked = selectedProducts.find(p => p._id == row._id);
                return (
                    <Checkbox
                        value={checked}
                        onChange={() => {
                            if (checked) this.setState({selectedProducts: selectedProducts.filter(p => p._id != row._id)});
                            else this.setState({selectedProducts: selectedProducts.concat(row)});
                        }}
                    />
                )
            },
            width: "10%"
        }, {
            label: "Thông tin",
            display: (row) => {
                return (
                    <>
                        <div className="product-name">
                            <ImgPreview src={row.image}/> {row.name}
                        </div>

                        <div className="info-item">
                            Giá: {formatNumber(row.price)}
                        </div>

                        <div className="info-item">
                            Ngày nhập: {moment(row.created).format("DD/MM/YYYY")}
                        </div>
                    </>
                )
            },
            width: "70%"
        }, {
            label: "Tồn",
            display: (row) => row.quantity,
            width: "20%"
        }]

        return (
            <div className="app-modal-box florist-working-route request-from-supplier">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{combo.name}</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <DataTable
                        columns={columns}
                        rows={products}
                        loading={!products}
                        onClickRow={(row) => {
                            const checked = selectedProducts.find(p => p._id == row._id);
                            if (checked) this.setState({selectedProducts: selectedProducts.filter(p => p._id != row._id)});
                            else this.setState({selectedProducts: selectedProducts.concat(row)});
                        }}
                    />

                    <div className="modal-footer">
                        <button type="button"
                                disabled={selectedProducts.length == 0}
                                className="btn btn-primary" onClick={() => {
                            onClose(selectedProducts)
                        }}>Thêm</button>
                    </div>
                </div>
            </div>
        );
    }
}
