import React from "react";
import ReactDOM from "react-dom";
import {formValidator} from "./form-validator";
/*global $ */
export class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showError: false
        }
    }

    setShowError(value) {
        this.setState({showError: value})
    }


    scrollToError() {
        let $elem = $(ReactDOM.findDOMNode(this));
        $("html, body").animate({
            scrollTop: $elem.find(".has-error").offset().top - 100
        }, 300);
    }

    render() {

        let {validations, formValue, render, onSubmit, className, scrollToFirstInvalid} = this.props;
        let {showError} = this.state;

        let invalidPaths = formValidator.getInvalidPaths(formValue, validations);

        let getInvalidByKey = (key) => {
            let path = invalidPaths.find(path => path.invalidKey == key);
            if (showError && path) {
                return path.text;
            }

            return false;
        };


        return (
            <form
                className={className}
                onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({showError: true}, () => {
                    if (scrollToFirstInvalid && invalidPaths.length > 0) {
                        this.scrollToError();
                    }
                    invalidPaths.length == 0 && onSubmit && onSubmit();
                });

            }}>
                {render(getInvalidByKey, invalidPaths)}
            </form>
        );
    }
}
