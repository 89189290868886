import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import {customerApi} from "../../api/customer-api";
import {AutoComplete} from "../../components/auto-complete/auto-complete";
import {vipApi} from "../../api/vip-api";
import moment from "moment";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {formatNumber, getTotalBill} from "../../common/common";
import {securityFe} from "../../security/security-fe";
import {Select} from "../../components/select/select";
import {ButtonGroup} from "../../components/button-group/button-group";
import {Checkbox} from "../../components/checkbox/checkbox";
import {DatePicker} from "../../components/date-picker/date-picker";
export class CustomerLastBillRoute extends React.Component {

    constructor(props) {
        super(props);

        let from = new Date();
        from.setHours(0, 0, 0, 0);

        let to = new Date();
        to.setHours(23, 59, 59, 99);

        this.state = {
            customer: {
                customerPhone: ""
            },
            bills: null,
            total: 0,
            from,
            to,
            isAllTime: true
        }
    }

    handleSelect(customer) {
        this.setState({loading: true, customer});
        customerApi.getLastBillCustomer({customerID: customer._id, skip: 0}).then((bills) => {
            this.setState({bills, loading: false})
        })
    }


    render() {

        let {customer, total, bills, from, to, isAllTime, totalPay, loading} = this.state;

        let columns = [{
            label: "Thời gian",
            display: (bill) => (
                <Fragment>
                    <div><b>{bill.bill_number}</b></div>
                    {moment(bill.deliverTime).format("DD/MM/YYYY HH:mm")}
                    <br/>
                    <br/>
                    <div>Sale: <b>{bill.sale || bill.created_by}</b>
                    </div>
                    {bill.florist && (
                        <div>Florist: <b>{bill.florist}</b></div>
                    )}

                    {bill.ship && (
                        <div>Ship: <b>{bill.ship}</b></div>
                    )}

                    <br/>
                    <div>Giờ In: {moment(bill.created).format("DD/MM/YYYY HH:mm")}</div>
                    <br/>


                    {bill.image && (
                        <ImgPreview src={bill.image} className="bill-image" alt=""/>
                    )}

                    {bill.selectedFlower.length > 0 && (
                        <div className="text-primary" style={{cursor: "pointer"}}
                             onClick={() => this.showFlower(bill.selectedFlower)}>
                            Chi tiết hoa
                        </div>
                    )}
                </Fragment>
            ),
            width: "30%",
            minWidth: "150",
        }, {
            label: "Thông tin đơn",
            display: (bill) => (
                <div>
                    {bill.items.map((item, index) => (
                        <div key={index}>
                            <b>{item.quantity}</b> {item.flowerType} {item.name} {item.sale &&
                        <span className="text-primary">({item.sale}%)</span>} {item.vat ?
                            <span className="text-primary"> - {item.vat}% VAT</span> : ""}
                            {item.color && (
                                <div className="text-small">Màu: {item.color.split(", ").map((c, i) => (
                                    <div key={i}
                                         style={{
                                             background: c,
                                             display: "inline-block",
                                             marginRight: "5px",
                                             width: "15px",
                                             height: "10px"
                                         }}
                                    />
                                ))}</div>)}
                            {item.size && (<div className="text-small">Size: <b>{item.size}</b></div>)}
                        </div>
                    ))}

                    { bill.upgradeFlower && <b>{bill.upgradeFlower == "true" ? "Tăng 10% định lượng" : `Tăng ${bill.upgradeFlower}`}</b>}


                    {bill.vipSaleType && (
                        <div>VIP: <b>{bill.vipSaleType}</b></div>
                    )}


                    {bill.promotion && (
                        <span>{bill.promotion.name}: <b>{bill.promotion.discount}%</b></span>
                    )}

                    {bill.discountCode && (
                        <div>
                            Mã Giảm giá: <b className="text-primary">{bill.discountCode.code} ({formatNumber(bill.discountCode.discountNumber)}{bill.discountCode.codeType == "percent" ? "%" : ""})</b>
                        </div>
                    )}

                    <div style={{
                        marginTop: "10px"
                    }}>
                        {bill.isOwe ?
                            <span className="text-danger"> Nợ: <b>{formatNumber(getTotalBill(bill))}</b></span> :
                            <span>Tổng tiền: <b>{formatNumber(getTotalBill(bill))}</b></span>}
                    </div>

                    {bill.to.shipMoney > 0 && <div>Phí ship: <b>{formatNumber(bill.to.shipMoney)}</b></div>}
                    {bill.surcharge > 0 && <div>Phụ thu: <b>{formatNumber(bill.surcharge)}</b></div>}
                    {bill.surchargeMember.length > 0 && (
                        <div>
                            Người hưởng: <b>{bill.surchargeMember.map(u => u.username).join(", ")}</b>
                        </div>
                    )}

                    <br/>

                    <div>Hình thức thanh toán: {bill.to.paymentType} { bill.to.paymentType == "Nợ" && !bill.isOwe && <span className="text-success">(Đã trả nợ)</span>}</div>

                    { bill.to.mcc && (
                        <div>MCC: {bill.to.mcc}</div>
                    )}


                    <div>
                        Ghi chú: {bill.to.notes}
                    </div>

                    <div>
                        Nội dung thiệp: {bill.to.cardContent}
                    </div>
                </div>
            ),
            width: "40%",
        }, {
            label: "Bên nhận",
            display: (bill) => (
                <div style={{marginTop: "10px"}}>
                    <b>
                        <div>
                            {bill.to.receiverName}
                        </div>
                        <div>
                            {bill.to.receiverPhone}
                        </div>
                        <div>
                            {bill.to.receiverPlace}
                        </div>
                    </b>
                </div>
            ),
            width: "30%",
        }];


        return (
            <Layout
                activeRoute="Đơn hàng gần nhất"
            >

                <div className="card customers-route bill-report-route bill-report-route-2">
                    <div className="card-title">
                        Đơn hàng gần nhất
                    </div>

                    <div className="card-body card-body-pagination">
                        <AutoComplete
                            asyncGet={(phone) => {
                                if (phone.length > 3) {
                                    return customerApi.getCustomerByPhone(phone).then((resp) => resp.customers)
                                }

                                return Promise.resolve([])
                            }}
                            onSelect={(customer) => this.setState({customer})}
                            objectKey="customerPhone"
                            object={customer}
                            onChange={(value) => {
                                this.setState({
                                    customer: {
                                        customerPhone: value,
                                    }, skip: 0, bills: null
                                })
                            }}
                            displayAs={(customer) => `${customer.customerPhone} - ${customer.customerName}`}
                            noPopup
                            label="Số Điện Thoại Khách"
                        />

                        { customer._id && (
                            <Fragment>
                                <Checkbox
                                    style={{
                                        marginBottom: "24px"
                                    }}
                                    label="Toàn thời gian"
                                    value={isAllTime}
                                    onChange={(isAllTime) => {
                                        this.setState({isAllTime}, () => {
                                            this.table.submitChange()
                                        })
                                    }}
                                />

                                { !isAllTime && (
                                    <div className="row"
                                    >
                                        <DatePicker
                                            className="col"
                                            label="Từ Ngày"
                                            value={from}
                                            onChange={(from) => {
                                                this.setState({from})
                                            }}
                                        />

                                        <DatePicker
                                            className="col"
                                            label="Tới Ngày"
                                            value={to}
                                            onChange={(to) => {
                                                this.setState({to})
                                            }}
                                        />

                                        <button className="btn btn-primary"
                                                onClick={() => {
                                                    this.table.submitChange();
                                                    this.setState({loading: true})
                                                }}
                                                disabled={loading}
                                        >
                                            <span className="btn-text">Lọc</span>
                                        </button>
                                    </div>
                                )}

                                <div className="text-primary">
                                    Tổng chi tiêu: <b>{formatNumber(totalPay || 0)}</b>
                                </div>
                            </Fragment>
                        )}



                    </div>


                    { customer._id && (
                        <Fragment>
                            <PaginationDataTable
                                ref={table => this.table = table}
                                total={total}
                                columns={columns}
                                rows={bills}
                                noSearch
                                api={({keyword, page}) => {
                                    return customerApi.getLastBillCustomer({
                                        customerID: customer._id,
                                        skip: (page - 1) * 15,
                                        from,
                                        to,
                                        isAllTime
                                    }).then(({bills, total, totalPay}) => {
                                        this.setState({bills, total, totalPay, loading: false});
                                        return Promise.resolve();
                                    })
                                }}
                            />
                        </Fragment>
                    )}
                </div>
            </Layout>
        );
    }
}
