import React from "react";
export class LoadingInline extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>
        );
    }
}
