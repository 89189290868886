import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {premisesInfo} from "../../security/premises-info";
import {Select} from "../../components/select/select";
import {warehouseApi} from "../../api/warehouse-api";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {formatNumber} from "../../common/common";
import {ButtonGroup} from "../../components/button-group/button-group";
import {DataTable} from "../../components/data-table/data-table";
import sortBy from "lodash/sortBy";
import {Input} from "../../components/input/input";
import {SelectTagsColor} from "../../components/select-tags-color/select-tags-color";
import {SelectTags} from "../../components/select-tags/select-tags";
import {catalogs} from "../../common/constance";
import {productApi} from "../../api/product-api";
import {securityFe} from "../../security/security-fe";
import moment from "moment";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import {DatePicker} from "../../components/date-picker/date-picker";
import {ColumnViewMore} from "../../components/column-view-more/column-view-more";
import {PaginationDataTableOffline} from "../../components/data-table/pagination-data-table-offline";
import {AutoCompleteNormal} from "../../components/auto-complete/auto-complete-normal";
import uniq from "lodash/uniq";
import {modals} from "../../components/modal/modals";
import {ManagePriceModal} from "./manage-price-modal";
import classnames from "classnames"
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {RequestHistoryModal} from "./request-history-modal";
import {getCSVData} from "../order/excel";
import {CSVLink} from "react-csv";
import {subwWarehouseCSVData, warehouseCSVData} from "./warehouse-csv-data";
import {getColumns, getError, getExported, getUsed} from "./warehouse-columns";
import {customerApi} from "../../api/customer-api";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import {ExcelWarehouseModal} from "./excel-warehouse-modal";
import {cache} from "../../common/cache";
import { APP_API } from "../../api/api";




export class WarehouseRoute extends React.Component {

    constructor(props) {
        super(props);

        let from = new Date();
        from.setHours(0, 0, 0 ,0);

        let to = new Date();
        to.setHours(23, 59, 59, 99);

        this.state = {
            items: null,
            selectedBase: "super",
            keyword: "",
            filteredColors: [],
            filteredTypes: [],
            suppliers: [],
            from,
            to,
            filteredSuppliers: "all",
            loading: false,
            filterDateTye: "Toàn bộ ngày",
            requests: null
        };

        productApi.suppliers().then((suppliers) => this.setState({suppliers}));
    }


    prepareData({products, flowers, total}) {
        this.setState({
            products: products.map(p => {
                let flower = flowers.find(f => f._id == p.parentID);
                return {
                    ...flower,
                    ...p,
                }
            }),
            total,
        });
    }

    updatePriceRow(row) {

        let {selectedBase, products} = this.state;

        const modal = modals.openModal({
            content: (
                <ManagePriceModal
                    product={row}
                    onDismiss={() => modal.close()}
                    isSubWarehouse={selectedBase != "all"}
                    onClose={(product) => {
                        this.setState({products: products.map(i => i._id == product._id ? product : i)});
                        modal.close();
                        confirmModal.alert("Điều chỉnh thành công")
                    }}
                />
            )
        })
    }

    viewHistory(row) {

        let {suppliers, selectedBase} = this.state;


        const modal = modals.openModal({
            content: (
                <RequestHistoryModal
                    onClose={() => modal.close()}
                    suppliers={suppliers}
                    product={row}
                    baseID={(selectedBase != "all" && selectedBase != "super") ? row.baseProductID : row._id}
                    selectedBase={selectedBase}
                />
            )
        })
    }

    exportExcel() {
        let {selectedBase, filteredColors, filteredTypes, filterDateTye, from, to} = this.state;

        const filter = {
            isSuperWarehouse: selectedBase == "super",
            premisesID: selectedBase != "all" && selectedBase != "super" ? selectedBase : null,
            canViewOriPrice: securityFe.isHavePermission(["warehouse.view-ori-price"]),
            filteredColors,
            filteredTypes,
            from: filterDateTye == "Toàn bộ ngày" ? null : from,
            to: filterDateTye == "Toàn bộ ngày" ? null : to,
        }
        window.open(`${APP_API}/api/report/warehouse?filter=${JSON.stringify(filter)}&access_token=${cache.get("token")}`, "__blank");
    }

    render() {


        let premises = premisesInfo.getPremises();


        let {selectedBase, keyword, filteredColors, filteredTypes, items, from, to, filteredSuppliers, suppliers, loading, filterDateTye, total, products, requests, bills} = this.state;

        let bases = [{
            value: "super",
            label: "Kho Tổng"
        }, {
            value: "all",
            label: "Kho Công Ty"
        }, ...premises.map((p) => ({
            value: p._id,
            label: `Kho ${p.name}`
        }))];


        return (
            <Layout
                activeRoute="Tồn Kho"
            >
                <div className="warehouse-route products-route">
                    <div className="card">
                        <div className="card-title">
                            Tồn Kho
                        </div>

                        <div className="card-body">
                            <Select
                                label="Kho"
                                className="first-margin"
                                value={selectedBase}
                                list={bases.map(b => b.value)}
                                displayAs={(base) => bases.find(b => b.value == base).label}
                                onChange={(selectedBase) => {
                                    this.setState({selectedBase}, () => {
                                        this.table.submitChange();
                                    });
                                }}
                            />

                            <div className="filter-wrapper">
                                <div className="filter-col">
                                    <SelectTagsColor
                                        label="Lọc Theo Màu"
                                        tags={filteredColors}
                                        onChange={(filteredColors) => this.setState({filteredColors})}
                                    />
                                </div>

                                <div className="filter-col">
                                    <SelectTags
                                        label="Lọc Theo Loại"
                                        tags={filteredTypes}
                                        onChange={(filteredTypes) => this.setState({filteredTypes})}
                                        list={catalogs}
                                        placeholder="Chọn Loại"
                                    />
                                </div>
                            </div>

                            <div style={{marginTop: "24px"}}>
                                <Select
                                    label="Lọc theo ngày"
                                    className="first-margin"
                                    value={filterDateTye}
                                    list={["Toàn bộ ngày", "Lọc theo ngày"]}
                                    displayAs={(v) => v}
                                    onChange={(filterDateTye) => {
                                        this.setState({filterDateTye})
                                    }}
                                />
                            </div>

                            {filterDateTye == "Lọc theo ngày" && (
                                <div className="filter-wrapper" style={{marginBottom: "12px"}}>
                                    <div className="filter-col">
                                        <DatePicker
                                            label="Từ ngày"
                                            value={from}
                                            onChange={(from) => this.setState({from})}
                                        />
                                    </div>

                                    <div className="filter-col">
                                        <DatePicker
                                            label="Tới ngày"
                                            value={to}
                                            onChange={(to) => this.setState({to})}
                                        />
                                    </div>
                                </div>
                            )}


                            <Input
                                style={{marginBottom: "24px"}}
                                value={keyword}
                                onChange={(e) => this.setState({keyword: e.target.value})}
                                label="Tìm kiếm"
                                info="Tên, mã"
                            />

                            <button className="btn btn-primary" disabled={loading}  onClick={() => {
                                this.setState({loading: true});
                                this.table.submitChange();
                            }}>Lọc</button>

                            <button style={{marginLeft: "12px"}} className="btn btn-danger"
                                    onClick={() => this.exportExcel()}>Xuất excel</button>


                        </div>


                        <PaginationDataTable
                            ref={elem => this.table = elem}
                            total={total}
                            columns={getColumns(selectedBase, suppliers, requests, (row) => this.viewHistory(row), bills, (row) => this.updatePriceRow(row))}
                            rows={products}
                            api={({page}) => {
                                let query = (selectedBase == "all") ? warehouseApi.getItemInBase : selectedBase == "super" ? warehouseApi.getItemInSuper : warehouseApi.getItemInSubWarehouse;
                                return query({
                                    keyword,
                                    skip: (page - 1) * 15,
                                    filteredColors,
                                    filteredTypes,
                                    from: filterDateTye == "Toàn bộ ngày" ? null : from,
                                    to: filterDateTye == "Toàn bộ ngày" ? null : to,
                                    premisesID: selectedBase,
                                    isCompany: selectedBase == "company"
                                }).then((resp) => {
                                    this.prepareData(resp);
                                    this.setState({loading: false});
                                    return Promise.resolve();
                                })
                            }}
                            noSearch
                        />
                    </div>
                </div>

            </Layout>
        );
    }
}
