import React, {Fragment} from "react";
import { Route, BrowserRouter, Switch} from 'react-router-dom';
import {LoginRoute} from "./login/login-route";
import {BillRoute} from "./bill/bill-route";
import {userInfo} from "../security/user-info";
import {PremisesRoute} from "./premises-route/premises-route";
import {responsive} from "../common/responsive/responsive";
import {ModalsRegistry} from "../components/modal/modals";
import {ManageUserRoute} from "./manage-user-route/manage-user-route";
import {BillOrderRoute} from "./order/bill-order";
import {BillEditRoute} from "./bill-edit-route/bill-edit-route";
import {Customers} from "./customers/customers";
import {VipRoute} from "./vip/vip-route";
import {WarehouseRoute} from "./warehouse/warehouse-route";
import {PromotionRoute} from "./promotion/promotion-route";
import {PromotionCodeRoute} from "./promotion-code/promotion-code-route";
import {FloristRoute} from "./florist-route/florist-route";
import {FloristWorkingRoute} from "./florist-working-route/florist-working-route";
import {ShipRoute} from "./ship-route/ship-route";
import {MySalaryRoute} from "./salary-route/my-salary-route";
import {OrderDraft} from "./draft/order-draft";
import {BillDraft} from "./draft/bill-draft";
import {RevenueReportRoute} from "./report/revenue/revenue-report-route";
import {ReportCustomerRoute} from "./report/customer/report-customer-route";
import {ReportDiscountRoute} from "./report/discount/report-discount-route";
import {ReportBillRoute} from "./report/bill/report-bill-route";
import {GalleryRoute} from "./gallery-route/gallery-route";
import {ManageRole} from "./manage-role/manage-role";
import {SettingRoute} from "./setting/setting-route";
import {SupplierRoute} from "./supplier/supplier-route";
import {ProductsRoute} from "./products/products-route";
import {RequestWarehouseRoute} from "./request-warehouse/request-warehouse-route";
import {RequestFromSupplier} from "./request-warehouse/request-from-supplier/request-from-supplier";
import {ReturnToSupplier} from "./request-warehouse/return-to-supplier/return-to-supplier";
import {TransferToSubWarehouse} from "./request-warehouse/transfer-to-subwarehouse/transfer-to-subwarehouse";
import {ReturnToBaseRoute} from "./request-warehouse/return-to-base/return-to-base";
import {ReportFlower} from "./request-warehouse/report-flower/report-flower";
import {MemoriesRoute} from "./memories/memories-route";
import {ReportSupplier} from "./report/supplier/report-supplier";
import {FloristEditRoute} from "./florist-working-route/florist-edit-route";
import {getStartAndLastDayOfWeek} from "../common/common";
import {ReportFloristWorkingRoute} from "./report/flower/report-florist-working-route";
import {OrderFlower} from "./request-warehouse/order-flower/order-flower";
import {DiscountCodesRoute} from "./discount-codes/discount-codes-route";
import {CustomerLastBillRoute} from "./customer-last-bill/customer-last-bill-route";
import {WebManageProduct} from "./web-manage-product/web-manage-product";
import {BlogRoute} from "./blog/blog-route";
import {BlogManageRoute} from "./blog/blog-manage-route";
import { DichVuHoaRoute } from "./dich-vu-hoa/dich-vu-hoa-route";
import {CollectionRoute} from "./collection/collection-route";
import {BannerRoute} from "./banner/banner-route";
import {BannerRoute as BannerDichVuHoaRouter} from "./banner-dvh/banner-route";
import {CustomerChangePhone} from "./customer-change-phone/customer-change-phone";
import {MessageRoute} from "./message-route/message-route";
import {OrderReviewRoute} from "./order-review/order-review-route";
import {TabConfigRoute} from "./tab-config/tab-config-route";
import {FooterSettingRoute} from "./footer-setting/footer-setting";
import {BannerSettingRoute} from "./banner-setting/banner-setting";
import {GiftsExchangeRoute} from "./gifts-exchange/gifts-exchange";
import {WebManageProductTypeRoute} from "./web-product-type/web-manage-product-type-route";
import {CustomerNotBuy} from "./customer-not-buy/customer-not-buy";
import {ManageComboRoute} from "./manage-combo/manage-combo-route";
import {WebConfig} from './web-config/web-config';
import { ManageGeoRoute } from "./manage-geo-router/manage-geo-route";
let defaultReportDays = getStartAndLastDayOfWeek();
export const defaultReportDayService = {
    get: () => defaultReportDays,
    set: (updated) => {
        defaultReportDays = {...updated}
    }
};

export class AppRouter extends React.Component {

    constructor(props) {
        super(props);

        userInfo.onChange(() => {
            this.forceUpdate();
        });

        responsive.onChange(() => {
            this.forceUpdate();
        });

       /*
       //firebase
       try {
            const messaging = firebase.messaging();
            messaging.usePublicVapidKey("BJDFZLZ9baPgAmoIva_1vd1btVcHG0mmyIEdN8mgYAa_YY796DmLdZGWi7Dj0oMcj9IFis1pbXf7_OkJkN16fjY");
        } catch (e) {
            console.log(e);
        }
        */
    }


    render() {

        let user = userInfo.getUser();
        const requireAuthen = (comp) => user == null ? redirect("/login") : comp;
        const requireUnauthen = (comp) => user != null ? redirect("/") : comp;
        const requireAuthenAdmin = (comp) => {
            if (user == null) return redirect("/login");
            if (user.role === "admin") return comp;
            return redirect("/")
        };

        return (
            <BrowserRouter>
                <Fragment>
                    <Route exact path="/" component={requireAuthen(BillRoute)} />
                    <Route exact path="/login" component={requireUnauthen(LoginRoute)} />
                    <Route exact path="/manage-premises" component={requireAuthenAdmin(PremisesRoute)} />
                    <Route exact path="/manage-user" component={requireAuthenAdmin(ManageUserRoute)} />
                    <Route exact path="/orders" component={requireAuthen(BillOrderRoute)} />
                    <Route exact path="/edit-bill/:id" component={requireAuthen(BillEditRoute)} />
                    <Route exact path="/customers" component={requireAuthen(Customers)} />
                    <Route exact path="/customer-last-bill" component={requireAuthen(CustomerLastBillRoute)} />
                    <Route exact path="/vip" component={requireAuthen(VipRoute)} />
                    <Route exact path="/warehouse" component={requireAuthen(WarehouseRoute)} />
                    <Route exact path="/discount-codes" component={requireAuthen(DiscountCodesRoute)} />
                    <Route exact path="/promotion" component={requireAuthen(PromotionRoute)} />
                    <Route exact path="/promotion-code" component={requireAuthen(PromotionCodeRoute)} />

                    <Route exact path="/florist" component={requireAuthen(FloristRoute)} />
                    <Route exact path="/ship" component={requireAuthen(ShipRoute)} />
                    <Route exact path="/florist-working/:id" component={requireAuthen(FloristWorkingRoute)} />
                    <Route exact path="/florist-edit/:id" component={requireAuthen(FloristEditRoute)} />
                    <Route exact path="/salary" component={requireAuthen(MySalaryRoute)} />
                    <Route exact path="/draft" component={requireAuthen(OrderDraft)} />
                    <Route exact path="/edit-bill-draft/:id" component={requireAuthen(BillDraft)} />
                    <Route exact path="/report-revenue" component={requireAuthen(RevenueReportRoute)} />
                    <Route exact path="/report-customer" component={requireAuthen(ReportCustomerRoute)} />
                    <Route exact path="/report-discount" component={requireAuthen(ReportDiscountRoute)} />
                    <Route exact path="/report-bill" component={requireAuthen(ReportBillRoute)} />
                    <Route exact path="/report-supplier" component={requireAuthen(ReportSupplier)} />
                    <Route exact path="/report-florist-working" component={requireAuthen(ReportFloristWorkingRoute)} />
                    <Route exact path="/gallery" component={requireAuthen(GalleryRoute)} />
                    <Route exact path="/manage-role" component={requireAuthenAdmin(ManageRole)} />
                    <Route exact path="/setting" component={requireAuthen(SettingRoute)} />
                    <Route exact path="/manage-supplier" component={requireAuthen(SupplierRoute)} />
                    <Route exact path="/products" component={requireAuthen(ProductsRoute)} />
                    <Route exact path="/request-warehouse" component={requireAuthen(RequestWarehouseRoute)} />
                    <Route exact path="/request-warehouse/request-from-supplier" component={requireAuthen(RequestFromSupplier)} />
                    <Route exact path="/request-warehouse/return-to-supplier" component={requireAuthen(ReturnToSupplier)} />
                    <Route exact path="/request-warehouse/transfer-to-subwarehouse" component={requireAuthen(TransferToSubWarehouse)} />
                    <Route exact path="/request-warehouse/return-to-base" component={requireAuthen(ReturnToBaseRoute)} />
                    <Route exact path="/request-warehouse/report-flower" component={requireAuthen(ReportFlower)} />
                    <Route exact path="/request-warehouse/order-flower" component={requireAuthen(OrderFlower)} />
                    <Route exact path="/memories" component={requireAuthen(MemoriesRoute)} />
                    <Route exact path="/web-manage-product" component={requireAuthen(WebManageProduct)} />
                    <Route exact path="/web-dich-vu-hoa" component={requireAuthen(DichVuHoaRoute)} />
                    <Route exact path="/web-manage-blog" component={requireAuthen(BlogRoute)} />
                    <Route exact path="/web-manage-blog/new" component={requireAuthen(BlogManageRoute)} />
                    <Route exact path="/web-manage-blog/edit/:id" component={requireAuthen(BlogManageRoute)} />

                    <Route exact path="/web-manage-collection" component={requireAuthen(CollectionRoute)} />
                    <Route exact path="/web-manage-banner" component={requireAuthen(BannerRoute)} />
                    <Route exact path="/web-manage-banner-dich-vu-hoa" component={requireAuthen(BannerDichVuHoaRouter)} />
                    <Route exact path="/web-config" component={requireAuthen(WebConfig)} />
                    <Route exact path="/tab-config" component={requireAuthen(TabConfigRoute)} />
                    <Route exact path="/footer-setting" component={requireAuthen(FooterSettingRoute)} />
                    <Route exact path="/banner-setting" component={requireAuthen(BannerSettingRoute)} />
                    <Route exact path="/customer-change-phone" component={requireAuthen(CustomerChangePhone)} />
                    <Route exact path="/manage-combo" component={requireAuthen(ManageComboRoute)} />
                    <Route exact path="/order-review" component={requireAuthen(OrderReviewRoute)} />
                    <Route exact path="/gifts-exchange" component={requireAuthen(GiftsExchangeRoute)} />
                    <Route exact path="/web-product-type" component={requireAuthen(WebManageProductTypeRoute)} />
                    <Route exact path="/customer-not-buy" component={requireAuthen(CustomerNotBuy)} />
                    <Route exact path="/geo-dia-chi" component={requireAuthen(ManageGeoRoute)} />
                    {/*<Route exact path="/message" component={requireAuthen(MessageRoute)} />*/}
                    <ModalsRegistry />
                </Fragment>
            </BrowserRouter>
        );
    }
}

function redirect(location) {
    return class RedirectRoute extends React.Component {
        constructor(props, context) {
            super(props, context);
            props.history.push(location);
        }
        render() {
            return null;
        }
    }
}
