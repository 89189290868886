import React from "react";
import {DataTable} from "../../../components/data-table/data-table";
import {ColumnViewMore} from "../../../components/column-view-more/column-view-more";
import {listAgesRange} from "../../../common/constance";

export class ReportCustomerAgesRange extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let {loading, customers, bills, colors} = this.props;


        let itemsColor = listAgesRange.map(t => ({
            name: t,
            customers: customers.filter((customer) => customer.ageRanges == t)
        }));


        let columns = [{
            label: "Nhóm tuổi",
            display: (row) => row.name,
            width: "33.33%",
            minWidth: "150",
            sortBy: (row) => row.name
        }, {
            label: "Số khách",
            display: (row) => <span>{row.customers.length}</span>,
            sortBy: (row) => row.customers.length,
            width: "33.33%",
            minWidth: "150",
        }, {
            label: "Chi Tiết",
            display: (row) => row.customers.length > 0 && (
                <ColumnViewMore
                    viewMoreText={`Xem toàn bộ ${row.customers.length} khách`}
                    renderViewMoreBody={() => row.customers.map((c, index) => {
                        return (
                            <div key={index} className="info-item">
                                {c.customerName} - {c.customerPhone}
                            </div>
                        )
                    })}
                    isShowViewMoreText
                />
            ),
            width: "33.33%",
            minWidth: "150",
        }];


        return (
            <DataTable
                rows={itemsColor}
                columns={columns}
                loading={loading}
            />
        );
    }
}


