export const salaryFormula = [{
    value: "ship1",
    name: "Ship chế độ cũ",
    formulaText: "15k/ đơn trong giờ, sau 18h là 30k/ đơn, đơn trên 10km là 20k/ đơn",
    formula: (bill) => {
        if (!bill.ships || bill.ships.length == 0) return {
            money: 0,
            percent: null
        };

        const getSalary = () => {

            if (bill.ships[0].shipType !== undefined) {
                if (bill.ships[0].shipType === null) return 15000;
                if (bill.ships[0].shipType.toString() === "1") return 30000;
                if (bill.ships[0].shipType.toString() === "2") return 20000;
                if (bill.ships[0].shipType.toString() === "3") return 50000;
            }


            if (new Date(bill.to.deliverTime).getHours() >= 18 && new Date(bill.to.deliverTime).getMinutes() >= 30) {
                return 30000
            }

            if ((bill.to.distance || 0) < 10) {
                return 15000;
            }

            return 20000;
        };


        return {
            money: Math.ceil(getSalary()),
            percent: null
        };

    }
}, {
    value: "ship2",
    name: "Ship chế độ mới",
    formulaText: "2km đầu là 15k/ đơn những km tiếp theo cộng thêm 5k/ km.",
    formula: (bill) => {
        if (!bill.ships || bill.ships.length == 0) return {
            money: 0,
            percent: null
        };

        const getSalary = () => {
            let money = 15000;
            if (bill.to.distance) {
                let plusDistance = Math.max(0, bill.to.distance - 2);
                money = money + 5000 * plusDistance;
            }
            return money;
        };


        return {
            money: Math.ceil(getSalary()),
            percent: null
        };

    }
}];
