import React, {Fragment} from "react";
import {premisesAllInfo, premisesInfo} from "../../../security/premises-info";
import moment from "moment";
import {formatNumber, getTotalBill} from "../../../common/common";
import sumBy from "lodash/sumBy";
export class BillEWalletPrint extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {bill} = this.props;

        let premises = premisesAllInfo.getPremises();
        const getPriceBill = (bill) => sumBy(bill.items, item => item.price * item.quantity);
        const getTotalSale = () => sumBy(bill.items, item => {
            let price = item.price * item.quantity;
            if (item.sale) {
                return price * item.sale / 100
            }

            return 0;
        });

        return (
            <div>
                <b style={{float: "right", fontSize: "14px", marginTop: "10px"}}>
                    {premisesInfo.getActivePremise().name}
                </b>
                <div className="row" style={{marginLeft: "5px"}}>

                    <div className="col-md-6 col-xs-7">
                        <div className="row">
                            <img src="/assets/img/liti-logo.png" style={{width: "70px", height: "70px", marginLeft: "10px"}}/>
                        </div>


                        <div className="row" style={{marginTop: "5px"}}>
                            <span style={{fontSize: "10px"}}>

                                {premises.filter(p => p.isActiveFD).map((p, index) => (
                                    <div key={index}>
                                        Cơ sở {index + 1}: {p.address}
                                    </div>
                                ))}

                                <div>Website: litiflorist.com</div>
                                <div><b>Tel: 0435766338 | 0964263355</b></div>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-5">
                        <div className="row">
                            <span style={{fontSize: "14px"}}>
                                  <i>
                                      Số đơn hàng: <b>{bill.bill_number}</b>
                                  </i>
                            </span>
                        </div>
                        <div className="row" style={{marginTop: "50px"}}>
                            <b style={{fontSize: "20px"}}>HOÁ ĐƠN BÁN LẺ</b>
                        </div>
                    </div>
                </div>


                <table style={{border: "1px"}} className="table">
                    <thead>
                    <tr style={{fontSize: "12px"}}>
                        <th>Nội dung</th>
                        <th className="text-right">SL</th>
                        <th className="text-right">Đơn Giá</th>
                    </tr>
                    </thead>

                    <tbody>
                    {bill.items.map((item, index) => (
                        <tr key={index} style={{fontSize: "12px"}}>
                            <td>{item.name}</td>
                            <td className="text-right">{item.quantity}</td>
                            <td className="text-right">{formatNumber(item.price)} </td>
                        </tr>
                    ))}
                    </tbody>

                </table>

                <div className="row">

                    <div className="col-md-10 col-xs-8 text-right" style={{fontSize: "12px"}}>
                        Thành tiền:
                        <br/>
                        {bill.to.paymentType == "Nợ" ? "Nợ" : "Tổng tiền: "}
                    </div>
                    <div className="col-md-2 col-xs-4 text-right" style={{fontSize: "12px"}}>
                        <b>{formatNumber(getPriceBill(bill))}</b>
                        <br/>
                        <b>{formatNumber(getTotalBill(bill))}</b>
                    </div>
                </div>


                <div className="row" style={{marginTop: "10px", fontSize: "12px"}}>
                    <div className="col-md-6 col-xs-6">
                        Tên khách:
                        <br/>
                        <b>{bill.customer.customerName}</b>
                    </div>

                    <div className="col-md-6 col-xs-6">
                        Số điện thoại:
                        <br/>
                        <b>{bill.customer.customerPhone}</b>
                    </div>

                    <div className="col-md-6 col-xs-6">
                        Người tạo đơn:
                        <br/>
                        <b>{bill.created_by}</b>
                    </div>

                    {bill.codes ? (
                        <div className="col-md-6 col-xs-6">
                            {bill.codes.length} mã giảm giá 100k
                            <br/>
                            <b>{bill.codes.join(", ")}</b>
                        </div>
                    ) : (
                        <div className="col-md-6 col-xs-6">
                            Mã giảm giá
                            <br/>
                            <b>Ẩn khi in lại</b>
                        </div>
                    )}



                    <div className="col-md-6 col-xs-6">
                        Hình thức thanh toán: <b>{bill.to.paymentType}</b>
                    </div>

                </div>
            </div>
        );
    }
}
