import React from "react";
import {Input} from "../../components/input/input";
import {PictureUpload} from "../../components/picture-upload/picture-upload";
import {footerContentApi} from "../../api/footer-api";

export class FooterBannerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            banner: props.banner,
        };

    }

    submit() {
        let {banner} = this.state;
        let {onClose} = this.props;

        this.setState({saving: true});

        if (banner._id) {
            footerContentApi.editBannerSetting(banner._id, banner).then(() => {
                onClose()
            })
        } else {
            footerContentApi.createBannerSetting(banner).then(() => {
                onClose()
            });
        }
    }
    ;

    render() {

        let {banner, saving, loading} = this.state;
        let {onDismiss} = this.props;

        return (
            <div className="blog-preview-modal app-modal-box">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{banner._id ? "Sửa" : "Thêm"} banner footer</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Input
                            label="Tên"
                            value={banner.title}
                            onChange={(e) => this.setState({banner: {...banner, title: e.target.value}})}
                        />

                        <Input
                            label="Lời nhận xét"
                            value={banner.description}
                            onChange={(e) => this.setState({banner: {...banner, description: e.target.value}})}
                        />

                        <PictureUpload
                            label="Ảnh"
                            value={banner.image}
                            onChange={(image) => this.setState({banner: {...banner, image}})}
                            noResize
                        />

                    </div>


                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                        <button
                            onClick={() => this.submit()}
                            disabled={banner.title.length == 0 || banner.description.length == 0 || !banner.image || saving}
                            className="btn btn-primary">
                            <span className="btn-text">Lưu</span>
                            {saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}
