import React from "react";
import moment from "moment";
import {DatePicker} from "../../components/date-picker/date-picker";
import {Select} from "../../components/select/select";
import {DataTable} from "../../components/data-table/data-table";
import {premisesInfo} from "../../security/premises-info";
import {customerApi} from "../../api/customer-api";
export class RemindCustomer extends React.Component {

    constructor(props) {
        super(props);
        let today = new Date();
        today.setFullYear(today.getFullYear() - 1);

        this.state = {
            selectedDate: today,
            loading: true
        };

        this.getDays();
    }

    getDays = async () => {
        let {selectedDate} = this.state;
        this.setState({loading: true})
        const {days, customers} = await customerApi.getRemindCustomerSpecialDay({
            day: new Date(selectedDate).getDate(),
            month: new Date(selectedDate).getMonth() + 1
        });

        this.setState({days, customers, loading: false})
    }

    render() {

        let {selectedDate, days, customers, loading} = this.state;

        const columns = [{
            label: "Thông tin khách",
            display: (row) => {
                const customer = customers.find(r => r._id == row.customerID);
                return (
                    <>
                        <div>{customer.customerName}</div>
                        <div>{customer.customerPhone}</div>
                    </>
                )
            },
            width: "50%"
        }, {
            label: "Note",
            display: (row) => row.notes,
            width: "50%"
        }]

        return (
            <div className="card memories-route">
                <div className="card-title">
                    Ngày đặc biệt
                </div>

                <div className="card-body">
                    <DatePicker
                        className="first-margin"
                        label="Chọn Ngày"
                        value={selectedDate}
                        onChange={(selectedDate) => this.setState({selectedDate}, () => this.getDays())}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rows={days}
                    loading={loading}
                />
            </div>
        );
    }
}
