import React from "react";

export class BlogPreviewModal extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let {blog, onDismiss} = this.props;

        return (
            <div className="blog-preview-modal app-modal-box">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Chi tiết</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="content-item ck-content" dangerouslySetInnerHTML={{__html: blog.content}}/>
                    </div>

                    <div className="modal-footer">
                        <button
                                onClick={() => onDismiss()}
                                className="btn btn-primary">
                            <span className="btn-text">Đóng</span>
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}
