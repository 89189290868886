import React from "react";
import {Layout} from "../../components/layout/layout";
import {modals} from "../../components/modal/modals";
import {promotionCodeApi} from "../../api/promotion-code-api";
import moment from "moment";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {securityFe} from "../../security/security-fe";
import {ButtonGroup} from "../../components/button-group/button-group";
import {DataTable} from "../../components/data-table/data-table";
import { ManagePromotionCodeModal } from "./manage-promotion-code-modal";
import { formatNumber2 } from "../../common/common";
const DS_LOAI_KM = [{value: 'tm', label: 'Tiền mặt'}, {value:'ck', label: 'Chiết khấu %'}];
export class PromotionCodeRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            promotions: []
        };

        promotionCodeApi.get().then((promotions) => {
            this.setState({promotions})
        })
    }

    addItem() {

        let startDate = new Date();
        startDate.setHours(0,0,0,0);
        let endDate = new Date();
        endDate.setHours(23,59,59,0);

        const modal = modals.openModal({
            content: (
                <ManagePromotionCodeModal
                    promotion={{batDau: startDate, ketThuc: endDate, ten: "", loai: 'tm', giaTri: 0, soLuong: 1, ma: ''}}
                    onDismiss={() => modal.close()}
                    onClose={(promotion) => {
                       
                        let {promotions} = this.state;
                        promotionCodeApi.create(promotion).then((promotion) => {
                            this.setState({promotions: promotions.concat(promotion)});
                            modal.close();
                        })
                    }}
                />
            )
        })
    }

    edit(item) {
        const modal = modals.openModal({
            content: (
                <ManagePromotionCodeModal
                    promotion={item}
                    onDismiss={() => modal.close()}
                    onClose={(promotion) => {
                        let {promotions} = this.state;
                        promotionCodeApi.update(item._id, promotion).then(() => {
                            this.setState({promotions: promotions.map(p => p._id == item._id ? promotion : p)});
                            modal.close();
                        })
                    }}
                />
            )
        })
    }

    remove(item) {
        let {promotions} = this.state;

        confirmModal.show({
            title: `Xoá ${item.ten}?`,
            description: `<p> Bạn có đồng ý xoá mã <strong>${item.ma} </strong> này không? </p>
            <p> Chú ý:Mã này mà được sử dụng thì sẽ không thể xóa được! </p>`
        }).then(() => {
            this.setState({
                promotions: promotions.filter(p => p._id != item._id)
            });
            promotionCodeApi.delete(item._id);
        })
    }

    render() {

        let {promotions} = this.state;

        let columns = [{
            label: "Thời Gian Áp Dụng",
            width: "400px",
            display: (item) => <span>{moment(item.batDau).format("DD/MM/YYYY")} - {moment(item.ketThuc).format("DD/MM/YYYY")}</span>,
            sortKey: "catalog",
            minWidth: "150"
        }, {
            label: "Mã giảm giá",
            width: "100px",
            display: (row) => row.ma,
            sortBy: (row) => row.ma,
            minWidth: "150"
        }, {
            label: "Tên",
            width: "40%",
            display: (row) => row.ten,
            sortBy: (row) => row.ten,
            minWidth: "150"
        }, {
            label: "Số lượng",
            width: "100px",
            display: (row) => row.soLuong,
            sortBy: (row) => row.soLuong,
            minWidth: "100"
        },{
            label: "SL sử dụng",
            width: "100px",
            display: (row) => 0,           
            minWidth: "100"
        }, {
            label: "Loại giảm giá",
            width: "100px",
            display: (row) => `${ DS_LOAI_KM.find(f=> f.value == row.loai)?.label??''}`,
            sortBy: (row) => row.loai,
            minWidth: "100"
        }, {
            label: "Giá trị",
            width: "15%",
            display: (row) => `${formatNumber2(row.giaTri)}${row.loai == 'tm'? ' vnđ': ' %'}`,
            sortBy: (row) => row.giaTri,
            minWidth: "100"
        }, {
            label: "",
            width: "5%",
            display: (row) => securityFe.isHavePermission(["promotion.edit", "promotion.remove"]) && (
                <ButtonGroup
                    actions={[{
                        name: "Sửa",
                        icon: <i className="fa fa-pencil"/>,
                        click: () => this.edit(row),
                        hide: () => !securityFe.isHavePermission(["promotion.edit"])
                    }, {
                        name: "Xóa",
                        icon: <i className="fa fa-trash text-danger"/>,
                        click: () => this.remove(row),
                        hide: () => !securityFe.isHavePermission(["promotion.remove"])
                    }]}
                />
            ),
            minWidth: "50"
        }];

        return (
            <Layout
                activeRoute="Mã khuyến mại"

            >

                <div className="card">
                    <div className="card-title">Quản lý mã khuyến mại</div>

                    <div className="card-body">
                        { securityFe.isHavePermission(["promotion.create"]) && (
                            <button type="button" className="btn btn-primary" onClick={() => this.addItem()}>
                                Thêm mã khuyến mại
                            </button>
                        )}
                    </div>

                    { securityFe.isHavePermission(["promotion.view"]) && (
                        <DataTable
                            rows={promotions}
                            columns={columns}
                        />
                    )}
                </div>


            </Layout>
        );
    }
}
