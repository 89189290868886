import React from 'react';
import {WYSIWYGEditor} from "../../components/wysiwyg-editor/wysiwyg-editor";
import {footerContentApi} from "../../api/footer-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";

export class FooterContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            footers: null
        };

        footerContentApi.getFooters().then( (footers) =>{
            this.setState({footers})
        })
    }

    save() {
        footerContentApi.editFooter(this.state.footers).then(()=>{
            confirmModal.alert("Lưu Footer Content thành công!");
        })
    }

    render() {
        const {footers} = this.state;
        let footerContents = [
            
            {
                label: "Chính sách và quy định chung",
                key: 'rule'
            },
            {
                label: "Bảo mật thông tin",
                key: 'security'
            },
            {
                label: "QUY ĐỊNH VÀ HÌNH THỨC THANH TOÁN",
                key : "payment"
            },
            {
                label: "CHÍNH SÁCH VẬN CHUYỂN / GIAO NHẬN",
                key :"shipping"
            },
            {
                label: "CHÍNH SÁCH ĐỔI / TRẢ HÀNG VÀ HOÀN TIỀN",
                key : "refund"
            },
            {
                label: "CHÍNH SÁCH THẺ HỘI VIÊN",
                key : "membership"
            }
        ]
        return (
            <div className='footer-content'>
                <div className="card privacy">
                    <div className="card-title">
                        Footer Contents
                    </div>

                    <div className="card-body set-warning-text">
                        {footers && footerContents.map((item, index) => (
                                <WYSIWYGEditor
                                    key={index}
                                    label={item.label}
                                    value={this.state.footers[item.key]}
                                    onChange={(value) => this.setState({footers: {...footers ,[item.key]: value}})}
                                />
                        ))}


                        {footers && (
                            <button
                                className="btn btn-primary"
                                onClick={() => this.save()}
                            >
                                <span className="btn-text">
                                    Lưu
                                </span>
                            </button>
                        )}
                    </div>
                </div>



            </div>
        )
    }
}
