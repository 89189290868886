import React from "react";
import ReactDOM from "react-dom";
import {SendIcon} from "../../components/icons/icons";
import classnames from "classnames";
import {timeParser} from "../../common/common";
import {supportChatApi} from "../../api/support-chat-api";
import {userInfo} from "../../security/user-info";
/*global $ */
/*global firebase */
export class MessageDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chatValue: "",
            messages: null,
        }
    }

    initMessage(props) {
        let messagesRef = firebase.database().ref(`messages/${props.selectedConversation.conversationID}`);
        messagesRef.on('value', (snapshot) => {
            let messages = [];
            for (let key in snapshot.val()) {
                messages.push(snapshot.val()[key]);
            }

            this.setState({messages}, () => {
                this.scrollToBottom();
            })
        });
    }

    componentWillUnmount() {
        //firebase
       // firebase.database().ref(`conversations`).off('value');
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.selectedConversation && props.selectedConversation != this.props.selectedConversation) {
            this.initMessage(props);
        }
    }

    scrollToBottom() {
        setTimeout(() => {
            const chatBody = $(ReactDOM.findDOMNode(this)).find(".message-detail-body");
            $(chatBody).scrollTop(chatBody[0].scrollHeight);
        }, 100)
    }


    componentDidMount() {
        this.setState({
            bottomHeight: this.getBottomSize()
        });
        if (this.props.selectedConversation) {
            this.initMessage(this.props);
        }
    }

    getBottomSize() {
        return $(ReactDOM.findDOMNode(this)).find(".message-detail-bottom").outerHeight();
    }

    submit() {

        let {selectedConversation} = this.props;
        let {chatValue} = this.state;

        this.setState({
            messages: this.state.messages.concat({
                message: chatValue,
                isCustomer: false,
                supportName: userInfo.getUser().username
            }),
            chatValue: ""
        }, () => {
            this.scrollToBottom();
        });

        supportChatApi.sendMessage({
            message: chatValue,
            conversationID: selectedConversation.conversationID
        })
    }



    render() {

        let {chatValue, bottomHeight, messages} = this.state;
        let {onGoBack, selectedConversation} = this.props;

        return (
            <div className="message-detail">
                <div className="message-detail-header">

                    <i className="fa fa-angle-left"
                        onClick={() => onGoBack()}
                    />
                    { !selectedConversation ? (
                        <div className="loading-line"/>
                    ) : `${selectedConversation.name} - ${selectedConversation.phone}`}
                </div>

                <div className="message-detail-body"
                     style={{
                         height: `calc(100% - (51px + ${bottomHeight}px)`
                     }}
                >
                    { !messages ? (
                        <div className="chat-wrapper">
                            <div className="chat-bubble">
                                <div className="chat-value">
                                    <div className="loading-line"/>
                                    <div className="loading-line truncate"/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        messages.map((message, index) => (
                            <div className={classnames("chat-bubble", !message.isCustomer && "user-chat")} key={index}>

                                <div className="chat-value-wrapper">
                                    <div className="chat-value">

                                        <div className="chat-value-header">
                                            { !message.isCustomer && (
                                                <div className="user-name">
                                                    {message.supportName || "Tin nhắn tự động"}
                                                </div>
                                            )}
                                        </div>

                                        {message.message}

                                        <div className="chat-time">
                                            {timeParser(message.time)}
                                        </div>
                                    </div>
                                </div>




                            </div>
                        ))
                    )}

                </div>

                { selectedConversation && (
                    <div className="message-detail-bottom">
                        <pre>{chatValue}&nbsp;</pre>

                        <textarea
                            className="chat-bottom-input"
                            placeholder="Gửi tin nhắn"
                            value={chatValue}
                            onKeyDown={(e) => {
                                if (e.key == "Enter" && !e.shiftKey && chatValue.length > 0) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.submit();
                                }
                            }}
                            onChange={(e) => this.setState({chatValue: e.target.value}, () => {
                                this.setState({bottomHeight: this.getBottomSize()})
                            })}
                        />

                        <div className="send-icon">
                            <SendIcon/>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
