import React from "react";
import ReactDOM from "react-dom";
import {ClickOutside} from "../click-outside/click-outside";
import classnames from "classnames";

export class Select extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            top: 0,
            left: 0,
            open: false,
            width: 0,
            keyword: ""
        }
    }

    componentDidMount() {
        let {left, top, width} = ReactDOM.findDOMNode(this).getBoundingClientRect();
        this.setState({top, left, width})
    }

    render() {

        let {value, onChange, displayAs, list, label, error, className, removeAble, onRemove , disabled, style, allowSearch } = this.props;
        
        let {open, top, left, width, keyword} = this.state;

        return (
            <div className={classnames("liti-input select", className, error && "has-error", value !== undefined && "has-value", open && "focus", disabled && "disabled")} onClick={() => !disabled && this.setState({open: true})}
                style={style}
            >
                <div className="select-text">
                    {displayAs ? displayAs(value) : value}&nbsp;

                    <i className={classnames("fa sort-icon", !open ? "fa-angle-down" : "fa-angle-up")}/>

                    { removeAble && value && <i className="fa sort-icon remove-icon fa-trash" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemove();
                    }}/>}
                </div>


                <div className="label"

                >
                    {label}
                </div>

                <div className="error">
                    {error}
                </div>

                <div className="bar">
                    {label}
                </div>

                { open && (
                    <ClickOutside onClickOut={() => !disabled && this.setState({open: false})}>
                        <div className="select-dropdown"
                        >
                            { allowSearch && (
                                <input
                                    value={keyword}
                                    onChange={(e) => this.setState({keyword: e.target.value})}
                                    placeholder="Tìm kiếm"
                                />
                            )}

                            { list.filter(item => ((displayAs ? displayAs(item) : item) || "").toLowerCase().indexOf(keyword.toLowerCase()) > -1).map((item, index) => (
                                <div className={classnames("dropdown-item", item == value && "active")} key={index}
                                     onClick={() => {
                                         setTimeout(() => {
                                             this.setState({open: false});
                                         });
                                         onChange(item)
                                     }}
                                >
                                    {displayAs ? displayAs(item) : item}
                                </div>
                            ))}
                        </div>
                    </ClickOutside>
                )}


            </div>
        );
    }
}
