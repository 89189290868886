import sumBy from "lodash/sumBy";
import {salaryFormula} from "./salaray-formula";
import { getTotalBillCommon } from "../../common/bill-total-common";
/*global $ */
export let formatNumber = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
};

export let formatNumber2 = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
};

export const getTotalBill = getTotalBillCommon;

export const getTotalBill2 = (bill) => sumBy(bill.items, item => {
    return item.price * item.quantity;
});


export const getBillProfit = (bill) => {
    if (bill.selectedFlower && bill.selectedFlower.length > 0) {
        return getTotalBill(bill) - sumBy(bill.selectedFlower, (item) => (item.oriPrice || 0) * item.quantity);
    }

    return 0;
};

export const getTotalBillItems = (bill) => {
    return Math.round(sumBy(bill.items, item => {
        return item.price * item.quantity
    }));

};

export const getTotalBillDiscount = (bill) => {
    let totalBillItems = sumBy(bill.items, item => {
        let price = item.price * item.quantity;
        if (item.sale) {
            price = price - price * item.sale / 100
        }

        if (item.vat) {
            price = price + price * item.vat / 100
        }

        return price;
    });

    let totalDiscount = sumBy(bill.items, item => {
        let price = item.price * item.quantity;
        if (item.sale)  return price * item.sale / 100;
        return 0;
    });

    let discount = 0;

    if (bill.vipSaleType == "Giảm giá 5%") {
        discount += 5;
    }

    if (bill.vipSaleType == "Giảm giá 20%") {
        discount += 20;
    }

    if (bill.promotion) {
        discount += bill.promotion.discount
    };

    if (bill.discountCode && bill.discountCode.codeType == "percent") {
        discount += bill.discountCode.discountNumber
    }

    let ret = Math.round(totalDiscount + totalBillItems * Math.min(discount, 100) / 100);

    if (bill.discountCode && bill.discountCode.codeType == "number") {
        return ret + bill.discountCode.discountNumber;
    }

    return ret
};


export const getTotalBillVAT = (bill) => {
    return Math.round(sumBy(bill.items, item => {
        let price = item.price * item.quantity;
        if (item.sale) price = price * item.sale / 100;
        if (item.vat) return price * item.vat / 100;
        return 0
    }));

};


export const getTotalBillWithoutVAT = (bill, isSalary) => {


    if (bill.to && bill.to.paymentType && bill.to.paymentType.indexOf("Free") > -1 && !isSalary) {
        return 0;
    }


    if (isSalary && bill.to.paymentType == "Free DVKH") {
        return 0;
    }

    if (bill.status == "Huỷ Đơn") return 0;


    let totalBillItems = sumBy(bill.items, item => {
        let price = item.price * item.quantity;
        if (item.sale) {
            price = price - price * item.sale / 100
        }

        return price;
    });

    if (bill.payOwe && bill.customerInfo) {
        totalBillItems += bill.customerInfo.spend.totalOwe
    }

    let discount = 0;
    let d =  /Giảm giá (?<d>\d+)%/g.exec(bill.vipSaleType);
    if (d) {
        discount += parseInt(d.groups.d);  
    }
   
    if (bill.promotion) {
        discount += bill.promotion.discount
    }

    if (bill.discountCode && bill.discountCode.codeType == "percent") {
        discount += bill.discountCode.discountNumber
    }

    let ret = Math.round(totalBillItems - totalBillItems * Math.min(discount, 100) / 100);

    if (bill.discountCode && bill.discountCode.codeType == "number") {
        return ret + bill.discountCode.discountNumber;
    }

    return ret


};

export const getSubTotalBill = (bill) => {
    return sumBy(bill.items, item => {
        return item.price * item.quantity;
    });
}

export const getTotalBillWithoutDiscount = (bill) => {

    let totalBillItems = sumBy(bill.items, item => {
        let price = item.price * item.quantity;
        if (item.sale) {
            price = price - price * item.sale / 100
        }

        if (item.vat) {
            price = price + price * item.vat / 100
        }

        return price;
    });

    if (bill.payOwe && bill.customerInfo) {
        totalBillItems += bill.customerInfo.spend.totalOwe
    }

    let discount = 0;

    if (bill.vipSaleType == "Giảm giá 5%") {
        discount += 5;
    }

    if (bill.vipSaleType == "Giảm giá 20%") {
        discount += 20;
    }

    if (bill.promotion) {
        discount += bill.promotion.discount
    }

    if (bill.discountCode && bill.discountCode.codeType == "percent") {
        discount += bill.discountCode.discountNumber
    }

    let ret = Math.round(totalBillItems - totalBillItems * Math.min(discount, 100) / 100);

    if (bill.discountCode && bill.discountCode.codeType == "number") {
        return ret + bill.discountCode.discountNumber;
    }

    return ret;

};




export const getSalary = (user, bill) => {

    if (!user) return {
        money: 0
    };


    let billTotal = getTotalBillWithoutVAT(bill, true);
    let charge = 0;

    if (bill.status != "Done") {
        return {
            money: 0
        };
    }

    if (user.role == "ship") {
        let found = salaryFormula.find(s => s.value == (user.salaryFormula || "ship1"));
        return found.formula(bill);
    }


    if (bill.internal) {
        return {
            money: 0
        }
    }


    let isOnl = false;

    if (user.role == "florist" && bill.selectedFlower.length == 0) {
        return {
            money: 0
        };
    }

    if (user.role == "florist") {
        const found = bill.florists && bill.florists.find(u => u.user_id == user._id);
        const isUniqFlower = bill.items.find(i => ["cây thông đan mạch","hoa cắt cành"].includes((i.flowerType || "").trim().toLowerCase()) );
        const isUniqFQua = bill.items.find(i => [
            "quả",
            "gram",
            "chùm",
            "tháp quả",
            "hộp quả",
            "trái cây",
            "trái cây (bán lẻ)"
            
        ].includes((i.flowerType || '').trim().toLowerCase()));
        const IDs = ["5fcb8568107bc923f2f9e0c5", "5fe46f78107bc923f2fa55c1"];
        const point = IDs.indexOf(user._id) > -1 ? 2 : 3;
        const pointx = isUniqFlower ? 1.5 : (isUniqFQua? 0.5: point);
        if (found) charge += pointx / bill.florists.length;

        const isSale = bill.sales && bill.sales.find(u => u.user_id == user._id);
        if (isSale) {
            let myUser = bill.sales.find(u => u.username == user.username);
            if (myUser.isOnl) {
                isOnl = true;
            }
            charge += 0.9;
        }
    }


    if (user.role == "sale") {


        const IDs = ["5f76d8a85b07403a0da0471a", "5f76d8be5b07403a0da0471b", "5fdb8405107bc923f2fa2a2d"];

      

        let myUser = bill.sales.find(u => u.username == user.username);
        const isUniqFQua = bill.items.find(i => [
            "quả",
            "gram",
            "chùm",
            "tháp quả",
            "hộp quả",
            "trái cây",
            "trái cây (bán lẻ)"
        ].includes((i.flowerType || '').trim().toLowerCase()));
        
        const isUniqFThong = bill.items.find(i => [
            "cây thông đan mạch"
        ].includes((i.flowerType || '').trim().toLowerCase()));
        const point = isUniqFThong ? 2:  (isUniqFQua ? 0.5: (IDs.indexOf(user._id) > -1 ? 1 : 1.8));
        if (myUser.isOnl) {
            charge += point * 60 / 100;
            isOnl = true;
        } else {
            let isHaveSaleOnl = bill.sales.find(u => u.isOnl);
            if (isHaveSaleOnl) {
                charge += (point - (point * 60 / 100)) / (bill.sales.length - 1);
            } else {
                charge += point / (bill.sales.length)
            }
        }
    }


    return {
        money: Math.ceil(billTotal * charge / 100),
        percent: charge,
        isOnl
    };

};


export const getShipFees = (bill, distance) => {

    const billTotal = getTotalBill(bill);

    if (billTotal >= 600000) {
        if (bill.vipSaleType) {
            if (distance <= 8) return 0;
            return 5000 * distance;
        } else {
            if (distance <= 5) return 0;
            return 5000 *  distance;
        }
    } else {
        return 5000 *  distance
    }



};

export const formatValue = (value) => {
    if (value < 10) return `0${value}`;
    return value
};

export const filteredByKeys = (obj, keys, keyword) => {
    return obj.filter(item => {
        for (let key of keys) {
            if (key.indexOf(".") > -1) {
                const arr = key.split(".");
                if (item[arr[0]] && item[arr[0]][arr[1]] && item[arr[0]][arr[1]].toLowerCase().indexOf(keyword.toLowerCase()) > -1) return true;

            } else {
                if (item[key] && item[key].toLowerCase().indexOf(keyword.toLowerCase()) > -1) return true;
            }
        }

        return false;
    })
};

export const addDays = function (days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export const getDates = function (startDate, endDate) {
    let dates = [],
        currentDate = startDate,
        addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};

export const generateDatas = (item, qty) => {
    let ret = [];
    for (let i = 0; i < qty; i++) {
        ret.push(item)
    }

    return ret;
};

export const keysToArray = (obj) => {
    let ret = [];
    for (let key in obj) {
        ret.push({
            key,
            value: obj[key]
        })
    };

    return ret;
};


export const resizeImage = (file) => {
    return new Promise((resolve, reject)=>{
        resizeImages(file, function(dataUrl) {
            resolve(dataURItoBlob(dataUrl));
        });
    })
};

function resizeImages(file, complete) {
    // read file as dataUrl
    ////////  2. Read the file as a data Url
    var reader = new FileReader();
    // file read
    reader.onload = function(e) {
        // create img to store data url
        ////// 3 - 1 Create image object for canvas to use
        var img = new Image();
        img.onload = function() {
            /////////// 3-2 send image objeclt to function for manipulation
            complete(resizeInCanvas(img));
        };
        img.src = e.target.result;
    };
    // read file
    reader.readAsDataURL(file);

}

function resizeInCanvas(img){
    /////////  3-3 manipulate image
    var perferedWidth = 800;
    var ratio = perferedWidth / img.width;
    var canvas = $("<canvas>")[0];
    canvas.width = img.width * ratio;
    canvas.height = img.height * ratio;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0,0,canvas.width, canvas.height);
    //////////4. export as dataUrl
    return canvas.toDataURL();
}

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
};

export function getStartAndLastDayOfWeek(curr = new Date()) {
    let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6
    let from = new Date(curr.setDate(first));
    let to = new Date(curr.setDate(last));

    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 59, 99);


    if (from.getTime() > to.getTime()) {
        to.setMonth(to.getMonth() + 1);
    }

    return {
        from, to
    }
}

export const getRevenueEmployee = (bill, user) => {
    let totalBills = getTotalBillWithoutVAT(bill);

    if (bill.sales.find(u => u.username == user.username)) {
        return totalBills / bill.sales.length;
    }

    if (bill.florists.find(u => u.username == user.username)) {
        return totalBills / bill.florists.length;
    }

    return 0;
};

export let timeParser = (_time) => {
    let time = new Date(_time);
    let currentTime = new Date();

    let hours = Math.abs(currentTime.getTime() - time.getTime()) / 36e5;

    if (hours <= 24 * 7 && hours > 24) {
        return `${Math.floor(hours / 24)} ngày trước`
    }

    if (hours >= 24) {
        // lúc ${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()}
        return `${time.getDate()} tháng ${time.getMonth() + 1} ${currentTime.getFullYear() !== time.getFullYear() ? time.getFullYear() : ''}`
    }

    if (hours > 1 && hours < 24) {
        return Math.floor(hours) + " tiếng trước";
    }

    if (Math.floor(hours % 1 * 100) > 60) return "1 tiếng trước";

    const minute = Math.floor(hours % 1 * 100);

    if (minute > 0) return minute + " phút trước";

    return "Vừa xong"
};


export const cleanAccents = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Combining Diacritical Marks
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
};


export const buildUrl = (name, id) => `${cleanAccents(name.toLowerCase()).replace(/[^0-9a-z ]/g, '').split(" ").join("-")}-${id}`;
export const buildQuery = (object) => {
    let ret = [];
    for (let o in object) {
        if (object[o]) ret.push({
            key: o,
            value: encodeURIComponent(object[o])
        })
    }

    ret = ret.map(r => `${r.key}=${r.value}`);
    return `?${ret.join("&")}`
};

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
