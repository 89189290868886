import React, {Fragment} from "react";
import { Layout } from "../../components/layout/layout";
import { Form } from "../../components/form/form";
import { Input } from "../../components/input/input";
import { required } from "../../components/form/validations";
import { SetupHomeApi } from "../../api/setup-home-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
export class WebConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: { name: '', description: '', meta: '' , social: ''},
            saving: false
        }
    }
    componentDidMount() {
        SetupHomeApi.getSetupHome().then(t => {            
            this.setState({config:{...this.state.config, ...t}});           
        }).catch(ct => {
            console.log(ct);
        })
    }

    submit() {
        this.setState({ saving: true });
        let {config} = this.state;
        console.log(1, config);
        var configData = {...config};
        console.log(111,configData);
        delete configData["_id"];
        SetupHomeApi.updateSetupHome(config._id, configData).then(t => {   
            confirmModal.alert("Đã cập nhật thành công!");
            this.setState({ saving: false });
        }).catch(ct => {
            this.setState({ saving: true });
        });
    }

    render() {
        let { config, saving } = this.state;
        let validations = [
            { "name": [required("Tên")] },
            { "description": [required("Mô tả ")] },
        ];
        console.log(config);
        return (
            <Layout
                activeRoute="Cấu hình"
            >
                <div className="web-manage-catalog-route">
                    <div className="card">
                        <div className="card-title">
                            Cấu hình
                        </div>
                        <div className="card-body">
                            <Form
                                onSubmit={() => this.submit()}
                                formValue={config}
                                validations={validations}
                                render={(getInvalidByKey) => (
                                    <Fragment>
                                        <div className="form-body">
                                            <Input
                                                value={config.name}
                                                onChange={(e) => this.setState({ config: { ...config, name: e.target.value } })}
                                                label="Tên"
                                                error={getInvalidByKey("name")}
                                            />

                                            <Input
                                                value={config.description}
                                                onChange={(e) => this.setState({ config: { ...config, description: e.target.value } })}
                                                label="Mô tả"
                                                textArea
                                                error={getInvalidByKey("description")}
                                            />
                                             <Input
                                                value={config.meta}
                                                onChange={(e) => this.setState({ config: { ...config, meta: e.target.value } })}
                                                label="Meta"
                                                textArea                                               
                                            />
                                             <Input
                                                value={config.social}
                                                onChange={(e) => this.setState({ config: { ...config, social: e.target.value } })}
                                                label="Mạng xã hội"
                                                textArea                                               
                                            />
                                        </div>
                                        <div className="form-footer">
                                            <button type="submit" className="btn btn-primary">
                                                <span className="btn-text">Lưu</span>
                                                {saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse" /></span>}
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            />                           
                        </div>

                    </div>
                </div>
            </Layout>
        );
    }
    

}