import {api} from "./api";

export const discountCodeApi = {
    createDiscountCode: (item) => {
        return api.post("/api/discount-code", item)
    },
    updateDiscountCode: (id, item) => {
        return api.put(`/api/discount-code/${id}`, item)
    },
    removeDiscountCode: (id) => {
        return api.delete(`/api/discount-code/${id}`)
    },
    getDiscountCodes: () => {
        return api.get("/api/discount-code")
    },
    searchDiscountCode: (code) => {
        return api.get(`/api/search-discount-code/${code}`);
    },
    applyDiscountCode: (discountID, billID) => {
        return api.post(`/api/apply-discount-code/${discountID}/${billID}`)
    }
};
