import React from "react";
import {Modal} from "./modal";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import remove from "lodash/remove";
import {responsive} from "../../common/responsive/responsive";
/*global $ */

export class ModalsRegistry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalList: []
        };


        modals.openModal = (options) => {
            let modalOptions = {
                options,
                resolve: null
            };

            this.state.modalList.push(modalOptions);
            this.forceUpdate();
            let result = new Promise((resolve)=> {
                modalOptions.resolve = resolve;
            });
            return {
                dismiss: ()=> {
                    this.dismiss(modalOptions);
                },
                close: (result) => {
                    this.close(modalOptions, result);
                },
                refresh: () => {
                 
                },
                result: result
            };
        };
    }

    dismiss(modal) {
        modal.resolve();
        remove(this.state.modalList, m => m == modal);
        this.forceUpdate();
    }

    close(modal, result) {
        modal.resolve(result);
        remove(this.state.modalList, m => m == modal);
        this.forceUpdate();
    }
 

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.modalList.length > 0) {

            this.lastScrollTop = $(window).scrollTop();

            if (responsive.le("xs")) {
                $("html").css({"overflow": "hidden", "position" : "static"});
                $("body").css({"overflow": "hidden", "position" : "static", "height" : "0px", margin: "0"});
            } else {
                $("html").css({"overflow": "hidden"})
            }
        } else {
            if (responsive.le("xs")) {
                $("html").css({"overflow": "", "position" : "", "height" : "", margin: ""});
                $("body").css({"overflow": "", "position" : "", "height" : "", margin: ""});
                window.scroll(0, this.lastScrollTop);
            } else {
                $("html").css({"overflow": ""})
            }
        }
    }

    render() {
        const {modalList} = this.state;

        return (
            <TransitionGroup className="app-modal-list" >
                { modalList.map((modal, i)=> (
                    <CSSTransition
                        timeout={300}
                        classNames="app-modal-fade"
                        key={i}>
                        <Modal
                            cantKickOut={modal.options.cantKickOut}
                            isStack={modalList.length > 1}
                            className={modal.options.className}
                            content={modal.options.content}
                            onDismiss={() => this.dismiss(modal)}
                            
                        />
                    </CSSTransition>

                )) }
            </TransitionGroup>
        );
    }
}

export const modals = {};
