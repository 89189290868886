import React from "react";
import {Form} from "../../components/form/form";
import {InputNumber} from "../../components/input-number/input-number";
import {warehouseApi} from "../../api/warehouse-api";
import {getError, getExported, getUsed} from "./warehouse-columns";
import {CSVLink} from "react-csv";
import {subwWarehouseCSVData, warehouseCSVData} from "./warehouse-csv-data";
export class ExcelWarehouseModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: null
        };

        let query = props.selectedBase == "all" ? warehouseApi.exportExcelItemInBase : warehouseApi.exportExcelItemInWarehouse;

        let {keyword, filteredColors, filteredTypes, filterDateTye, from, to} = this.props;

        query({
            keyword,
            filteredColors,
            filteredTypes,
            from: filterDateTye == "Toàn bộ ngày" ? null : from,
            to: filterDateTye == "Toàn bộ ngày" ? null : to,
            premisesID: props.selectedBase
        }).then(({products, flowers, requests, bills}) => {
            this.setState({
                products: products.map(p => {
                    let flower = flowers.find(f => f._id == p.parentID);
                    return {
                        ...flower,
                        ...p,
                        used: getUsed(p, bills, this.props.selectedBase),
                        error: getError(p, "report-error", requests, this.props.selectedBase),
                        missing: getError(p, "report-missing", requests, this.props.selectedBase),
                        exported: getExported(p, requests)
                    }
                })
            })
        })
    }



    render() {

        let {products} = this.state;
        let {selectedBase, suppliers} = this.props;

        return (
            <div className="modal app-modal-box">

                <div className="modal-header">
                    <div className="modal-title">
                        Xuất Excel
                    </div>
                </div>

                <div className="modal-body">
                    {products ? (
                        <div className="filter-wrapper">
                            {selectedBase == "all" ? (
                                <CSVLink
                                    data={warehouseCSVData(products, suppliers)}
                                    filename={"tonkho.csv"}
                                    className="btn btn-primary btn-small">
                                    <span className="btn-text">Xuất Excel</span>
                                    <span className="loading-icon"><i className="fa fa-file-excel-o"/></span>
                                </CSVLink>
                            ) : (
                                <CSVLink
                                    data={subwWarehouseCSVData(products, suppliers)}
                                    filename={"tonkho.csv"}
                                    className="btn btn-primary btn-small">
                                    <span className="btn-text">Xuất Excel</span>
                                    <span className="loading-icon"><i className="fa fa-file-excel-o"/></span>
                                </CSVLink>
                            )}
                        </div>
                    ) : (
                        <div className="">
                            Đang lấy dữ liệu...
                        </div>
                    )}
                </div>


            </div>
        );
    }
}
