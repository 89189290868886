import React from "react";
import {Layout} from "../../components/layout/layout";
import {ColorSetting} from "./color-setting";
import {TypeSetting} from "./type-setting";
import {permissionInfo} from "../../security/premises-info";
import {userInfo} from "../../security/user-info";
import {securityFe} from "../../security/security-fe";
import {customerApi} from "../../api/customer-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {warehouseApi} from "../../api/warehouse-api";
import {WarningSetting} from "./warning-setting";
import {WYSIWYGEditor} from "../../components/wysiwyg-editor/wysiwyg-editor";
import {Input} from "../../components/input/input";
import {FooterContent} from "./footer-content";
import {GiftTypeSetting} from "./gift-type-setting";

export class SettingRoute extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            customers: null,
            doneIndex: 0,
            privacy : ""
        }
    };


    render() {

        let {customers, doneIndex ,privacy} = this.state;
        const user = userInfo.getUser();

        return (
            <Layout
                activeRoute="Cài Đặt Màu, Loại"
            >
                <div className="setting-route">

                    {securityFe.isHavePermission(["bill.editProductColor"]) && (
                        <ColorSetting />
                    )}

                    { securityFe.isHavePermission(["bill.editProductType"]) && (
                        <TypeSetting/>
                    )}

                    { securityFe.isHavePermission(["bill.editGiftType"]) && (
                        <GiftTypeSetting/>
                    )}
                </div>
            </Layout>
        );
    }
}
