import uuid from "uuid/v4";
import {userInfo} from "../security/user-info";
/*global firebase */
export const supportChatApi = {
    sendMessage: ({message, conversationID}) => {
        return new Promise((resolve, reject)=>{
            let currentTime = new Date();
            let messageID = uuid();
            let newMessagesRef = firebase.database().ref('messages/' + conversationID).push();
            newMessagesRef.set({
                message,
                messageID,
                isCustomer: false,
                supportName: userInfo.getUser().username,
                time: currentTime.getTime(),
            }).then(() => {
                let updates = {};
                updates[`conversations/${conversationID}/lastMessage`] = message;
                updates[`conversations/${conversationID}/lastUpdate`] = currentTime.getTime();
                /*
                //firebase
                firebase.database().ref().update(updates).then(() => {
                    resolve({
                        message,
                        messageID,
                        isCustomer: false,
                        supportName: userInfo.getUser().name,
                        time: currentTime.getTime()
                    });
                })
                */
                resolve({
                    message,
                    messageID,
                    isCustomer: false,
                    supportName: userInfo.getUser().name,
                    time: currentTime.getTime()
                });

            });
        })

    }
};
