const eWalletValue = [{
    cost: 10000000,
    earn: 11000000,
    expireMonths: 12,
    totalVouchers: 1
}, {
    cost: 20000000,
    earn: 22000000,
    expireMonths: 12,
    totalVouchers: 2
}, {
    cost: 50000000,
    earn: 57500000,
    expireMonths: 18,
    totalVouchers: 5
}, {
    cost: 100000000,
    earn: 120000000,
    expireMonths: 18,
    totalVouchers: 10
}]
exports.eWalletValue = eWalletValue;
