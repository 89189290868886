import React from "react";
import {Layout} from "../../components/layout/layout";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {formatNumber} from "../../common/common";
import {ButtonGroup} from "../../components/button-group/button-group";
import {SelectTagsColor} from "../../components/select-tags-color/select-tags-color";
import {SelectTags} from "../../components/select-tags/select-tags";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import {webManageProductApi} from "../../api/web-manage-product-api";
import {modals} from "../../components/modal/modals";
import {WebManageProductModal} from "./web-manage-product-modal";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {Checkbox} from "../../components/checkbox/checkbox";
import {webProductTypeApi} from "../../api/web-product-type-api";
import {collectionApi } from "../../api/collection-api";
import {LoadingInline} from "../../../app/components/loading-inline/loading-inline";
import { userInfo } from "../../security/user-info";

export class WebManageProduct extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            flowers: null,
            filteredColors: [],
            filteredTypes: [],
            filterHidden: false,
            filterBestSeller: false,
            filterPromotionSeller:false,
            filterNewSeller:false,
            filterOutStock: false,
            filterCollections:[],
            catalogs: null,
            catalogsBase : [],
            collections: [],
            items: null
        };

        webProductTypeApi.get().then(({catalogs, items}) => {
            this.setState({catalogs, items});
        });

        collectionApi.getCollections2().then(({collections}) => {
            this.setState({collections});
        });
        // productApi.getTypes().then((catalogs) => this.setState({catalogs: catalogs.map(c => c.name) , catalogsBase: catalogs }));
    }

    
    addProduct() {
        const modal = modals.openModal({
            cantKickOut: true,
            content: (
                <WebManageProductModal
                    product={{
                        size: "L",
                        price: 0,
                        images: [],
                        tags : [],
                        colors: [],
                        catalogs: [],
                        content: ''
                    }}
                    catalogs={this.state.catalogs}
                    items={this.state.items}
                    onDismiss={() => modal.close()}
                    onClose={() => {
                        modal.close();
                        this.table.refresh();
                    }}
                />
            )
        })

    }

    edit(row) {
        const modal = modals.openModal({
            cantKickOut: true,
            content: (
                <WebManageProductModal
                    product={row}
                    onDismiss={() => modal.close()}
                    catalogsBase={this.state.catalogsBase}
                    onClose={() => {
                        modal.close();
                        this.table.refresh();
                    }}
                    editType
                    catalogs={this.state.catalogs}
                    items={this.state.items}
                />
            )
        })
    }
    updateBoSuuTap(item, productID, modal) {
        var collections = this.state.collections
        if(item.productIDSObject.some(s => s.productID == productID)) {
          var products =   item.productIDSObject.filter( f=> f.productID != productID);
          item.productIDSObject = products.map((m,idx) => ({productID: m.productID, sortIndex: idx}));
         
        } else {
            item.productIDSObject.push({productID: productID, sortIndex: item.productIDSObject.length});
            item.productIDSObject =  item.productIDSObject.map((m,idx) => ({productID: m.productID, sortIndex: idx}));
              
        }
        collectionApi.updateCollection(item._id, item).then(f => {
            collections.forEach(f => {
                if(f._id == item._id) {
                    f = item;
                }
              });
    
            this.setState({collections});
        })
       
        modal.refresh();
        //modal.close();
    }
    openBoSutap(collections, r) {
        const modal = modals.openModal({
            cantKickOut: true,
            content: (
                <div className="manage-product-modal app-modal-box container full-width">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Quản lý bộ sưu tập - {r.name}</h5>
                            <button type="button" className="close" onClick={() => modal.close()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>
                                {collections.filter(f => !f.isHidden).map(m => <div key={m._id} style={{marginTop: "10px"}}>
                                   
                                <Checkbox
                                    value={m.productIDSObject.some(s => s.productID== r._id)}
                                    label={m.title}
                                    onChange={(t) => this.updateBoSuuTap(m,r._id, modal)}
                                />
                            </div>)}
                            </div>
                        </div>
                    </div>
                </div>

               
            )
        });
        modal.refresh = () => {
            modal.close();
            this.openBoSutap(collections, r);
        }

    }


    remove(row) {
        confirmModal.show({
            title: "Xóa sản phẩm này?",
            description: "Bạn có đồng ý muốn xóa sản phẩm này không?"
        }).then(() => {
            webManageProductApi.removeProduct(row._id).then((resp) => {
                if (resp && resp.error) {
                    confirmModal.alert("Không thể xóa sản phẩm vì sản phẩm đang được sử dụng ở nơi khác");
                } else {
                    this.table.refresh();
                }
            });
        })
    }

    handleUpdateStatus(row, key, value) {

        let {flowers} = this.state;
        this.setState({
            flowers: flowers.map(f => f._id == row._id ? {...row, [key]: value} : f)
        });
        webManageProductApi.updateProduct(row._id, {...row, [key]: value});
    }
    randomColor() {
        var arr = ["Black","Blue","BlueViolet","Brown","Chocolate","Crimson","DarkBlue","DarkGreen","DarkMagenta",
        "DarkOliveGreen","DarkSlateBlue","DarkSlateGray","Green","Indigo","Maroon","MediumBlue","Navy","MidnightBlue",
        "Purple","SaddleBrown","Teal"
    ]
    return arr[Math.round(Math.random()*arr.length)];
    }



    render() {
        let user = userInfo.getUser(); 
        let {flowers, total, 
            items, 
            collections,
            catalogs, 
            filteredColors, 
            filteredTypes, 
            filterHidden, 
            filterBestSeller,
            filterPromotionSeller, 
            filterNewSeller, 
            filterCollections
            } = this.state;

        if (!items) return (
            <Layout
                activeRoute="Sản Phẩm"
            >
                <div className="card">
                    <div className="card-title">
                        Đang tải... <LoadingInline/>
                    </div>
                </div>
            </Layout>
        );


        let columns = [{
            label: "Mã SP",
            width: "10%",
            display: (row) => (
                <div>{row.productID}
                    <div>
                        <ImgPreview
                            src={row.images[0]}
                            style={{
                                width: "100%",
                                marginTop: "10px"
                            }}
                        />
                    </div>
                </div>
            ),
            sortKey: "productID",
            minWidth: "150"
        }, {
            label: "Tên",
            width: "35%",
            display: (row) => (
                <div>
                    <div><b>{row.name}</b>  
               </div>
                    <div style={{fontWeight: "normal", fontSize: "13px", marginTop: "10px"}}>{row.description}</div>
                    <div style={{fontWeight: "normal", fontSize: "12px", marginTop: "10px"}}>
                        {row.isHidden && <b className="text-danger" style={{marginRight: "5px"}}>Ẩn trên web</b>}
                        {row.bestSeller && <b className="text-success" style={{marginRight: "5px"}}>Best Seller</b>}                       
                        {row.newSeller && <b className="text-success" style={{marginRight: "5px"}}>Mới nhất</b>}
                        {row.outStock && <b className="text-danger" style={{marginRight: "5px"}}>Hết hàng</b>}
                        {row.uuTien && <b className="text-success" style={{marginRight: "5px"}}>Ưu tiên</b>}
                    </div>
                    <div>
                        <label> 
                        <button type="button" className="btn btn-link" onClick={() => this.openBoSutap(collections,row)}>
                            <i className="fa fa-object-group nav-icon"></i>
                            Bộ sưu tập</button> 
                        </label>
                        <div style={{display:'flex', flexWrap:'wrap', gap:'2px'}}>
                        {collections.filter(f => !f.isHidden && f.productIDSObject.some(s => s.productID == row._id)).map(m => <div key={m._id}  style={{marginRight: "5px", color:this.randomColor()}}>{m.title}</div>)}
                        </div>
                        
                    </div>
                </div>
            ),
            sortKey: "name",
            minWidth: "250"
        }, {
            label: "Danh Mục",
            width: "15%",
            display: (product) => product.catalogs.map((row) => {
                let item = items.find(i => i._id == row);
                if (!item) return null;
                return item.name
            }).join(", "),
            sortKey: "catalog",
        }, {
            label: "Size",
            width: "15%",
            display: (row) => row.size,
            sortKey: "size",
        }, {
            label: "Màu",
            width: "15%",
            display: (row) => row.colors.map((color, index) => (
                <div key={index}
                     style={{
                         background: color,
                         height: "15px",
                         width: "25px",
                         display: "inline-block",
                         marginRight: "5px",
                         border: "1px solid #dedede"
                     }}
                />
            )),
            minWidth: "150"
        }, {
            label: "Giá",
            width: "10%",
            display: (row) => (
                <div>
                    <span style={{
                        textDecoration: `${row.discountedNumber > 0 ? "line-through" : ""}`,
                        fontSize: `${row.discountedNumber > 0 ? "12px" : ""}`,
                        color: `${row.discountedNumber > 0 ? "#fd397a" : ""}`
                    }}>{formatNumber(row.price)}</span>
                    <br/>
                    {row.discountedNumber > 0 && <span>{formatNumber(row.price - row.discountedNumber)}</span>}
                </div>
            ),
            sortKey: "price",
            minWidth: "100"
        }, {
            label: "",
            width: "5%",
            display: (row) => (
                [   "mkt",
                    "admin",
                    "bpmh",
                    "florist",
                    "khotong",
                    "ns",
                    "web"
                ].includes(user.role)? (
                <ButtonGroup
                    actions={[{
                        name: "Sửa",
                        icon: <i className="fa fa-pencil"/>,
                        click: () => this.edit(row)
                    }, {
                        name: `${row.isHidden ? "Hiện SP" : "Ẩn SP"}`,
                        icon: <i className={`fa fa-eye${row.isHidden ? "" : "-slash"}`}/>,
                        click: () => this.handleUpdateStatus(row, "isHidden", !row.isHidden)
                    }, {
                        name: `${row.bestSeller ? "Bỏ Best Seller" : "Best Seller"}`,
                        icon: <i className={`fa fa-angle-double${row.bestSeller ? "-down" : "-up"}`}/>,
                        click: () => this.handleUpdateStatus(row, "bestSeller", !row.bestSeller)
                    },
                   
                    {
                        name: `${row.newSeller ? "Bỏ Mới nhất" : "Mới nhất"}`,
                        icon: <i className={`fa fa-angle-double${row.newSeller ? "-down" : "-up"}`}/>,
                        click: () => this.handleUpdateStatus(row, "newSeller", !row.newSeller)
                    },
                    {
                        name: `${row.uuTien ? "Bỏ Ưu tiên" : "Ưu tiên"}`,
                        icon: <i className={`fa fa-angle-double${row.uuTien ? "-down" : "-up"}`}/>,
                        click: () => this.handleUpdateStatus(row, "uuTien", !row.uuTien)
                    },
                    {
                        name: `${row.outStock ? "Bỏ Hết hàng" : "Hết hàng"}`,
                        icon: <i className={`fa fa-angle-double${row.outStock ? "-down" : "-up"}`}/>,
                        click: () => this.handleUpdateStatus(row, "outStock", !row.outStock)
                    },
                    {
                        name: "Xóa",
                        icon: <i className="fa fa-trash text-danger"/>,
                        click: () => this.remove(row)
                    }]}
                />): (<div></div>)
            ),
            minWidth: "50"
        }];



        return (
            <Layout
                activeRoute="Sản Phẩm"
            >
                <div className="web-manage-catalog-route products-route">
                    <div className="card">
                        <div className="card-title">
                            Danh sách sản phẩm
                        </div>

                        <div className="card-body">
                            <button type="button" className="btn btn-primary" onClick={() => this.addProduct()}>Thêm Sản Phẩm</button>

                            <div className="filter-wrapper">
                                <div className="filter-col">
                                    <SelectTagsColor
                                        label="Lọc Theo Màu"
                                        tags={filteredColors}
                                        onChange={(filteredColors) => this.setState({filteredColors}, () => this.table.reset())}
                                    />
                                </div>

                                <div className="filter-col">
                                    <SelectTags
                                        label="Lọc Theo Danh Mục"
                                        tags={filteredTypes}
                                        onChange={(filteredTypes) => this.setState({filteredTypes}, () => this.table.reset())}
                                        list={items.map(i => i._id)}
                                        displayAs={(v) => {
                                            let item = items.find(i => i._id == v);
                                            if (!item) return null;
                                            let father = catalogs.find(c => c._id == item.fatherID);
                                            return `${father.name} - ${item.name}`
                                        }}
                                        placeholder="Chọn Loại"
                                    />
                                </div>
                            </div>
                            <div className="filter-wrapper">
                                <div className="filter-col">
                                <div style={{marginTop: "10px"}}>
                                <Checkbox
                                    value={filterHidden}
                                    label="Lọc sản phẩm đang ẩn"
                                    onChange={(filterHidden) => this.setState({filterHidden}, () => this.table.reset())}
                                />
                            </div>

                            <div style={{marginTop: "10px"}}>
                                <Checkbox
                                    value={filterBestSeller}
                                    label="Lọc sản phẩm best seller"
                                    onChange={(filterBestSeller) => this.setState({filterBestSeller}, () => this.table.reset())}
                                />
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <Checkbox
                                    value={filterPromotionSeller}
                                    label="Lọc sản phẩm Ưu đãi"
                                    onChange={(filterPromotionSeller) => this.setState({filterPromotionSeller}, () => this.table.reset())}
                                />
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <Checkbox
                                    value={filterNewSeller}
                                    label="Lọc sản phẩm Mới nhất"
                                    onChange={(filterNewSeller) => this.setState({filterNewSeller}, () => this.table.reset())}
                                />
                            </div>
                                </div>
                                <div className="filter-col">

                                </div>
                            </div>
                            
                        </div>

                        <PaginationDataTable
                            ref={elem => this.table = elem}
                            total={total}
                            columns={columns}
                            rows={flowers}
                            rowStyling={(row) => {
                                if (row.isHidden) return {background: "rgba(253,57,122, .1)"};
                                if (row.bestSeller) return {background: "rgb(29,201,183, .1)"}
                            }}
                            api={({keyword, page, sortKey, isDesc}) => {
                                return webManageProductApi.getProducts({
                                    keyword,
                                    skip: (page - 1) * 15,
                                    sortKey,
                                    isDesc,
                                    filteredColors,
                                    filteredTypes,
                                    filterBestSeller,
                                    filterPromotionSeller,
                                    filterNewSeller,
                                    filterHidden,
                                    filterCollections
                                }).then(({flowers, total}) => {
                                    this.setState({flowers, total});
                                    return Promise.resolve();
                                })
                            }}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}
