import React from "react";
import {Input} from "../../../components/input/input";
import {AutoComplete} from "../../../components/auto-complete/auto-complete";
import {DatePicker} from "../../../components/date-picker/date-picker";
import {customerApi} from "../../../api/customer-api";
import {formatNumber} from "../../../common/common";
import {Checkbox} from "../../../components/checkbox/checkbox";
import {modals} from "../../../components/modal/modals";
import {Select} from "../../../components/select/select";
import {BillDiscountModal} from "./bill-discount-modal";
import {cities, listAgesRange} from "../../../common/constance";
import moment from "moment";

export const vipTypes = [{
    name: "VIP Platinum",
    discountList: ["Giảm giá 10%", "Tăng 15% định lượng hoa", null],
    money: 100000000
}, {
    name: "VIP Gold",
    discountList: ["Giảm giá 5%", "Tăng 10% định lượng hoa", null],
    money: 15000000
}];



export class BillCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customerInfo: null,
            isNotVip: false
        }
    }

    componentDidMount() {
        let {bill} = this.props;
        if (bill.customerId) {
            this.getCustomerInfo(bill.customerId)
        }
    }

    setVipPay(value) {
        this.setState({vipPay: value})
    }

    getCustomerInfo(id) {

        let {onChangeBill, bill} = this.props;

        customerApi.getCustomer(id).then((customerInfo) => {

            if (bill.is_e_wallet) {
                onChangeBill({...bill, customerInfo: customerInfo, customer: customerInfo.customer})
            } else {
                let vipInfo = vipTypes.find(v => {
                    if (customerInfo.customer.customVip && customerInfo.customer.customVip != "Không") {
                        let customVip = customerInfo.customer.customVip;

                        if (customVip == "VIP Gold" && customerInfo.spend.totalSpend >= 100000000) {
                            customVip = "VIP Platinum"
                        }
                        return v.name == customVip
                    }
                    return customerInfo.spend.totalSpend > v.money;
                });

                onChangeBill({...bill, customerInfo: customerInfo, customer: customerInfo.customer, vipSaleType: bill.vipSaleType ? bill.vipSaleType : (vipInfo ? vipInfo.discountList[0] : null)});
            }


            this.phone.setValue(customerInfo.customer.customerPhone);
        })
    }


    addDiscount() {
        let {onChangeBill, bill, customer} = this.props;
        customer = customer || {};
        const modal = modals.openModal({
            content: (
                <BillDiscountModal
                    customerID={customer._id}
                    onClose={(discount) => {
                        modal.close();
                        onChangeBill({...bill, discountCode: {
                                discount_id: discount._id,
                                code: discount.code,
                                reason: discount.reason,
                                codeType: discount.codeType,
                                discountNumber: discount.discountNumber
                        }});
                    }}
                    onDismiss={() => modal.close()}
                />
            )
        })
    }


    render() {

        let {customer, onChange, editMode = false, bill, onChangeBill, infoComponent} = this.props;
        let {isNotVip, vipPay} = this.state;
        customer = customer || {};
        let vipInfo = bill.customerInfo ? vipTypes.find(v => {
            if (bill.customerInfo.customer.customVip && bill.customerInfo.customer.customVip != "Không") {
                let customVip = bill.customerInfo.customer.customVip;

                if (customVip == "VIP Gold" && bill.customerInfo.spend.totalSpend >= 100000000) {
                    customVip = "VIP Platinum"
                }
                return v.name == customVip
            }
            return bill.customerInfo.spend.totalSpend > v.money;
        }) : null;

        
        return (
            <div className="bill-customer card">

                <div className="card-title">
                    Thông tin khách hàng
                    { bill.customerInfo && (
                        <div className="text-primary text-small">
                            Khách hàng {customer.customerName} đã chi {formatNumber(bill.customerInfo.spend.totalSpend)} ({formatNumber(Math.round((bill.customerInfo.spend.totalSpend + bill.customerInfo.spend.totalOwe)/500000))} điểm)
                            <br/>
                            E-Wallet: {formatNumber(bill.customer.e_wallet)} - HSD: {moment(bill.customer.e_wallet_expire_date).format("DD/MM/YYYY HH:mm")} {new Date().getTime() > new Date(bill.customer.e_wallet_expire_date).getTime() && <span className="text-danger">(Hết hạn)</span>}
                            {bill.customerInfo.spend.totalOwe > 0 && (
                                <div>
                                    <span
                                            className="text-danger"> Đang nợ {formatNumber(bill.customerInfo.spend.totalOwe)} </span>
                                    <div style={{marginTop: "5px", marginBottom: "5px"}}>
                                        <Checkbox label="Thanh toán nợ" value={ bill.payOwe}
                                                  onChange={(value) => {
                                                      
                                                      onChangeBill({...bill, payOwe: value})}}/>
                                    </div>
                                </div>
                            )}


                            { vipInfo && (
                                <div className="text-danger">
                                    Khách {vipInfo.name}
                                </div>
                            )}
                        </div>
                    )}

                    {!editMode && !bill.is_e_wallet && (
                        <div>
                            { !bill.discountCode ? (
                                <span className="text-success text-action" style={{fontSize: "13px", paddingLeft: "0"}} onClick={() => {
                                    this.addDiscount()
                                }}>
                                Thêm mã giảm giá
                            </span>
                            ) : (
                                <span className="text-danger text-action" style={{fontSize: "13px", paddingLeft: "0"}} onClick={() => {
                                    onChangeBill({...bill, discountCode: null})
                                }}>
                                Xóa mã giảm giá
                            </span>
                            )}
                        </div>
                    )}
                </div>

                <div className="card-body">

                    { !bill.is_e_wallet && (
                        <div className="row">
                            <div className="liti-input">
                                <Checkbox label="Đơn nội bộ" value={bill.internal}
                                          onChange={(value) => onChangeBill({...bill, internal: value})}/>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <Input
                            className="col"
                            label={bill.is_e_wallet ? "Tên Khách Hàng*" : "Tên Người Đặt*"}
                            readOnly={vipPay}
                            value={customer.customerName}
                            onChange={(e) => {
                               
                                onChange({...customer, customerName: e.target.value})}}
                        />

                        <Input
                            className="col"
                            label="Ghi chú"
                            disabled={vipPay}
                            value={customer.customNote}
                            onChange={(e) => onChange({...customer, customNote: e.target.value})}
                        />
                    </div>
                    <div className="row">                        

                        <Input
                            label="Địa Chỉ"
                            disabled={vipPay}
                            value={customer.customerPlace}
                            onChange={(e) => onChange({...customer, customerPlace: e.target.value})}
                        />
                    </div>

                    <div className="row">

                        <AutoComplete
                            className="col"
                            label="Số Điện Thoại*"
                            readOnly={editMode}
                            ref={elem => this.phone = elem}
                            asyncGet={ (phone) => {
                                onChangeBill({
                                    ...bill, customerInfo: null, payOwe: false, customer: {
                                        ...customer,
                                        customerPhone: phone,
                                        customerPlace: "",
                                        email: "",
                                        customerName: "",
                                    },
                                    to: {
                                        ...bill.to,
                                        receiverPhone: "",
                                        receiverName: "",
                                        receiverPlace: "",
                                        paymentType: null
                                    },
                                    wallet_points_used: 0
                                });

                                if (phone.length > 3) {
                                    return customerApi.getCustomerByPhone(phone).then((resp) => {
                                        return resp.customers
                                    })
                                }
                                this.setState({isNotVip: false});
                                return Promise.resolve([])
                            }}
                            onSelect={(updatedCustomer) => {
                                if (updatedCustomer.isNew) {
                                    onChangeBill({
                                        ...bill, customerInfo: null, payOwe: false,  customer: {
                                            ...customer,
                                            customerPhone: updatedCustomer.customerPhone,
                                            customerPlace: "",
                                            email: "",
                                            customerName: "",
                                        },
                                        to: {
                                            ...bill.to,
                                            receiverPhone: "",
                                            receiverName: "",
                                            receiverPlace: "",
                                            paymentType: null
                                        },
                                        vipSaleType: null
                                    });
                                    this.setState({isNotVip: false});
                                } else {
                                    onChangeBill({...bill, customerInfo: null, payOwe: false, vipSaleType: null});
                                    this.setState({isNotVip: false});
                                    this.getCustomerInfo(updatedCustomer._id);
                                }
                            }}
                            onChange={(value) => {
                                onChangeBill({...bill, customer: {...customer, customerPhone: value}, vipSaleType: null});
                            }}
                            objectKey="customerPhone"
                            object={customer}
                            displayAs={(customer) => `${customer.customerPhone} - ${customer.customerName}`}
                            noPopup
                        />


                        <Input
                            className="col"
                            label="Email"
                            readOny={vipPay}
                            value={customer.email}
                            onChange={(e) => onChange({...customer, email: e.target.value})}
                        />
                    </div>

                    <div className="row">
                        <DatePicker
                            label="Ngày Sinh"
                            className="col"
                            value={customer.birthDate ? new Date(customer.birthDate) : new Date(1976, 0, 1)}
                            onChange={(value) => onChange({...customer, birthDate: value})}
                        />

                        <Select
                            className="col"
                            value={customer.gender}
                            onChange={(gender) => onChange({...customer, gender})}
                            list={["Nam", "Nữ"]}
                            label="Giới Tính*"
                        />
                    </div>

                    { !bill.is_e_wallet && (
                        <div className="row">
                            { vipInfo && (
                                <Select
                                    label="VIP sale"
                                    value={bill.vipSaleType}
                                    list={vipInfo.discountList}
                                    onChange={(vipSaleType) => onChangeBill({...bill, vipSaleType})}
                                    displayAs={(value) => {
                                        if (value == null) return "Không";
                                        return value;
                                    }}
                                />
                            )}
                        </div>
                    )}

                    <div className="row">
                        <Select
                            className="col"
                            value={customer.city}
                            onChange={(city) => onChange({...customer, city})}
                            list={cities}
                            label="Thành phố*"
                        />

                        <Select
                            className="col"
                            value={customer.ageRanges}
                            onChange={(ageRanges) => onChange({...customer, ageRanges})}
                            list={listAgesRange}
                            label="Độ tuổi*"
                        />
                    </div>

                    { infoComponent()}

                </div>

            </div>
        );
    }
}

