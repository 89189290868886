import {api} from "./api";

export const reviewOrderApi = {
    get: (data) => {
        return api.post(`/api/review-order-list`, data);
    },
    updateOrderDetail: (orderID, data) => {
        return api.put(`/api/review-order/update-status/${orderID}`, data)
    },
    setToken: (token) => {
        return api.post(`/api/notification/set-token`, {token})
    }
};
