import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {customerApi} from "../../api/customer-api";
import {Input} from "../../components/input/input";
import sum from "lodash/sum";
import sortBy from "lodash/sortBy";
import {filteredByKeys, formatNumber, getTotalBill} from "../../common/common";
import {modals} from "../../components/modal/modals";
import {CustomerBillModal} from "./customer-bill-modal";
import {permissionInfo, premisesInfo} from "../../security/premises-info";
import {Pagination} from "../../components/pagination/pagination";
import {LoadingOverlay} from "../../components/loading-overlay/loading-overlay";
import {userInfo} from "../../security/user-info";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import classnames from "classnames"
import {ColumnViewMore} from "../../components/column-view-more/column-view-more";
import {Select} from "../../components/select/select";
import {securityFe} from "../../security/security-fe";
import {cache} from "../../common/cache";
import moment from "moment";
import {ExtendWalletModal} from "./extend-wallet-modal";
import { APP_API } from "../../api/api";

export class Customers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customers: null,
            bills: null,
            total: 0,
        };

    }


    updateCustomer(customer) {
        const modal = modals.openModal({
            content: (
                <ExtendWalletModal
                    customer={customer}
                    onClose={async (customer) => {
                        await customerApi.updateCustomer(customer._id, customer);
                        this.table.refresh();
                        modal.close();
                    }}
                />
            )
        })
    }

    render() {
        let user = userInfo.getUser();    

        let {customers, bills, total} = this.state;

        let columns = [{
            label: "Khách Hàng",
            width: "15%",
            display: (row) => {
                const getTotalPay = (customerId) => {
                    const customerBills = bills.filter(b => b.customerId == customerId);
                    return sum(customerBills.map(b => getTotalBill(b)))
                };

                let totalPay = getTotalPay(row._id);

                let options = [];

                if (totalPay < 100000000) {
                    options.push("VIP Platinum")
                }

                if (totalPay < 15000000) {
                    options.push("VIP Gold")
                }

                options.push("Không");

                return (
                    <div className="customer-name">
                        {row.customerName}
                        <div style={{
                            marginTop: "15px"
                        }}>
                            {options.length > 1 && securityFe.isHavePermission(["customer.update-vip-customer"]) && (
                                <Select
                                    label="Nâng cấp VIP"
                                    list={options.reverse()}
                                    value={row.customVip}
                                    onChange={(customVip) => {
                                        customerApi.updateCustomer(row._id, {customVip});
                                        this.setState({
                                            customers: customers.map((c => c._id == row._id ? ({
                                                ...row,
                                                customVip
                                            }) : c))
                                        })
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )
            },
            sortKey: "customerName",
            minWidth: "200"
        }, {
            label: "Điện Thoại",
            width: "10%",
            display: (row) => row.customerPhone,
            sortKey: "customerPhone",
            minWidth: "150"
        }, {
            label: "Tổng Tiền",
            width: "25%",
            display: (row) => {
                const getTotalPay = (customerId, isOwe) => {
                    let _bills = [];
                    if (isOwe) _bills = bills.filter(b => b.isOwe);

                    const customerBills = _bills.filter(b => b.customerId == customerId);
                    return sum(customerBills.map(b => getTotalBill(b)))
                };


                const getPayOfPremises = (customerId, premises_id) => {
                    const customerBills = bills.filter(b => b.customerId == customerId && b.premises_id == premises_id);
                    return sum(customerBills.map(b => getTotalBill(b)))
                };

                let premises = premisesInfo.getPremises();

                return (
                    <ColumnViewMore
                        header={formatNumber(row.totalPay)}
                        renderViewMoreBody={() => premises.filter(p => getPayOfPremises(row._id, p._id) > 0).map((p, index) => (
                            <div className="info-item" key={index}>
                                {p.name}: <b>{formatNumber(getPayOfPremises(row._id, p._id))}</b>
                            </div>
                        ))}
                        viewMoreText="Chi Tiết"
                        subText={getTotalPay(row._id, true) > 0 &&
                        <div className="text-danger">Nợ: {formatNumber(getTotalPay(row._id, true))}</div>}
                        isShowViewMoreText={row.totalPay > 0}
                    />
                )
            },
            sortKey: "totalPay",
            minWidth: "200"
        }, {
            label: "Tổng Đơn",
            width: "15%",
            display: (row) => formatNumber(row.totalBill),
            sortKey: "totalBill",
            minWidth: "100"
        }, {
            label: <>E-Card <br/> HSD</>,
            width: "15%",
            display: (row) => (
                <>
                    {formatNumber(row.e_wallet || 0)}
                    <br/>
                    {row.e_wallet_expire_date && (
                        <>
                            <span style={{fontSize: 12}}
                                  className="text-primary text-small">{moment(row.e_wallet_expire_date).format("DD/MM/YYYY HH:mm")}</span>
                            <button className="btn btn-small btn-primary" style={{marginTop: 5}}
                                    onClick={() => this.updateCustomer(row)}>Gia Hạn
                            </button>
                        </>
                    )}
                </>
            ),
            sortKey: "e_wallet",
            minWidth: "150"
        }, {
            label: "Điểm",
            width: "15%",
            display: (row) => formatNumber(Math.round(row.totalPay/500000)),
            sortKey: "wallet_points",
            minWidth: "100"
        }, {
            label: "Miêu tả",
            width: "20%",
            display: (row) => {
                const customerBills = bills.filter(b => b.customerId == row._id);
                const mapBillDescription = () => {
                    let ret = [];
                    for (let bill of customerBills) {
                        for (let item of bill.items) {
                            let found = ret.find(r => r.name == item.name);
                            if (found) found.count += item.quantity;
                            else ret.push({name: item.name, count: 1})
                        }
                    }

                    return sortBy(ret, r => -r.count);
                };

                const descriptions = mapBillDescription();

                if (descriptions.length == 0) return null;

                return (
                    <ColumnViewMore
                        header={<Fragment><span
                            className="text-primary">{descriptions[0].count}</span> {descriptions[0].name}</Fragment>}
                        isShowViewMoreText={descriptions.length > 1}
                        renderViewMoreBody={() => descriptions.slice(1).map((item, index) => (
                            <div className="info-item" key={index}>
                                <span className="text-primary">{item.count}</span> {item.name}
                            </div>
                        ))}
                    />
                )
            },
            minWidth: "200"
        }, {
            label: "Màu",
            width: "5%",
            display: (row) => {
                const customerBills = bills.filter(b => b.customerId == row._id);
                const mapBillDescription = () => {
                    let ret = [];
                    for (let bill of customerBills) {
                        for (let item of bill.items) {
                            let colors = item.color ? item.color.split(", ") : [];
                            for (let color of colors) {
                                let found = ret.find(r => r.color == color);
                                if (found) found.count++;
                                else ret.push({color, count: 1})
                            }
                        }
                    }

                    return sortBy(ret, r => -r.count);
                };

                const descriptions = mapBillDescription();
                if (descriptions.length == 0) return null;

                return (
                    <ColumnViewMore
                        header={<Fragment><span className="text-primary">{descriptions[0].count}</span>
                            <div style={{
                                background: descriptions[0].color,
                                width: "15px",
                                height: "10px",
                                display: "inline-block"
                            }}/>
                        </Fragment>}
                        isShowViewMoreText={descriptions.length > 1}
                        renderViewMoreBody={() => descriptions.slice(1).map((item, index) => (
                            <div className="info-item" key={index}>
                                <span className="text-primary">{item.count}</span>
                                <div style={{
                                    background: item.color,
                                    width: "15px",
                                    height: "10px",
                                    display: "inline-block"
                                }}/>
                            </div>
                        ))}
                    />
                )
            },
            minWidth: "125"
        }, {
            label: "Size",
            width: "5%",
            display: (row) => {
                const customerBills = bills.filter(b => b.customerId == row._id);
                const mapBillDescription = () => {
                    let ret = [];
                    for (let bill of customerBills) {
                        for (let item of bill.items) {
                            if (item.size) {
                                let found = ret.find(r => r.size == item.size);
                                if (found) found.count++;
                                else ret.push({size: item.size, count: 1})
                            }
                        }
                    }

                    return sortBy(ret, r => -r.count);
                };

                const descriptions = mapBillDescription();

                if (descriptions.length == 0) return null;

                return (
                    <ColumnViewMore
                        header={<Fragment><span
                            className="text-primary">{descriptions[0].count}</span> {descriptions[0].size}</Fragment>}
                        isShowViewMoreText={descriptions.length > 1}
                        renderViewMoreBody={() => descriptions.slice(1).map((item, index) => (
                            <div className="info-item" key={index}>
                                <span className="text-primary">{item.count}</span> {item.size}
                            </div>
                        ))}
                    />
                )
            },
            minWidth: "125"
        }, {
            label: "Loại",
            width: "5%",
            display: (row) => {
                const customerBills = bills.filter(b => b.customerId == row._id);
                const mapBillDescription = () => {
                    let ret = [];
                    for (let bill of customerBills) {
                        for (let item of bill.items) {
                            if (item.flowerType) {
                                let found = ret.find(r => r.flowerType == item.flowerType);
                                if (found) found.count++;
                                else ret.push({flowerType: item.flowerType, count: 1})
                            }
                        }
                    }

                    return sortBy(ret, r => -r.count);
                };

                const descriptions = mapBillDescription();

                if (descriptions.length == 0) return null;

                return (
                    <ColumnViewMore
                        header={<Fragment><span
                            className="text-primary">{descriptions[0].count}</span> {descriptions[0].flowerType}
                        </Fragment>}
                        isShowViewMoreText={descriptions.length > 1}
                        renderViewMoreBody={() => descriptions.slice(1).map((item, index) => (
                            <div className="info-item" key={index}>
                                <span className="text-primary">{item.count}</span> {item.flowerType}
                            </div>
                        ))}
                    />
                )
            },
            minWidth: "125"
        }];


        return (
            <Layout
                activeRoute="Danh Sách Khách Hàng"
            >
                <div className="card customers-route">
                    <div className="card-title">
                        Danh Sách Khách Hàng
                    </div>

                    <div className="card-body">
                        {user.role === "admin" ? (<button className="btn btn-primary"
                                onClick={() => window.open(`${APP_API}/api/excel-customers/${cache.get("token")}`)}
                        >
                            Xuất Excel
                        </button>) : (<div></div>) }
                                        
                        
                    </div>

                    <PaginationDataTable
                        ref={elem => this.table = elem}
                        total={total}
                        columns={columns}
                        rows={customers}
                        api={({keyword, page, sortKey, isDesc}) => {
                            return customerApi.getCustomers({
                                keyword,
                                skip: (page - 1) * 15,
                                sortKey,
                                isDesc
                            }).then(({customers, bills, total}) => {
                                this.setState({customers, bills, total});
                                return Promise.resolve();
                            })
                        }}
                    />
                </div>
            </Layout>
        );
    }
}

