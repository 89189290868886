import {uploadApi} from "../../api/upload-api";

export class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
        .then(file => new Promise((resolve, reject) => {
            uploadApi.upload(file).then(resp => {
                resolve({
                    default: resp.file
                })
            })
        }));
    }
}

// ...

export function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader);
    };
}
