import React from "react";
import {uploadApi} from "../../api/upload-api";
//import {CKEditor} from "@ckeditor/ckeditor5-react";
import { CKEditor } from 'ckeditor4-react';

import classnames from "classnames";

export class WYSIWYGEditor4 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            imagePreview: ''
        }
    }

    render() {

        let {label, value, onChange, className, error} = this.props;
        let {focus, imagePreview} = this.state;
        return (
            <div className={classnames("wysiwyg-editor", className, error && "has-error", focus && "focus")}>

                <div
                    className="label"
                >
                    {label}

                 
                </div>

                <CKEditor
                  
                    config={{
                        height:"500px",
                        allowedContent: true,
                        extraAllowedContent: '*(*);*{*}',
                        filebrowserUploadUrl: uploadApi.url_upload(),
                        filebrowserUploadMethod:'form'
                        }}
                    onChange={(event) => onChange(event.editor.getData())}
                    onBlur={(event, editor) => this.setState({focus: false})}
                    onFocus={(event, editor) => this.setState({focus: true})}
                    onInstanceReady={(evt) => {
                        setTimeout(()=> {
                            let dt = evt.editor.getData();
                            if(this.props.value && !dt) {
                                evt.editor.setData(this.props.value);
                            }
                        },2000);
                        
                    }}
                    initData={this.props.value}
                    
                />
            </div>
        );
    }
}
