import React from "react";
import {DataTable} from "../../components/data-table/data-table";
import sortBy from "lodash/sortBy";
import {productApi} from "../../api/product-api";
import {ImgPreview} from "../../components/img-repview/img-preview";
import sumBy from "lodash/sumBy";
import {formatNumber} from "../../common/common";
/*global onDismiss */
export class BillFlowerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: []
        };

        productApi.getProductByIds(props.flowers.map(f => f.parentID)).then((products) => {
            this.setState({products})
        })
    }


    render() {

        let {products} = this.state;
        let {flowers, onClose} = this.props;

        let columns = [{
            label: "Tên",
            width: "50%",
            minWidth: "250",
            display: (row) => {
                let product = products.find(r => r._id == row.parentID);
                if (product) {
                    return (
                        <div className="info-item product-name">
                            <ImgPreview src={product.image}/>
                            {product.name}
                        </div>
                    )
                }
            }
        }, {
            label: "Ngày nhập kho",
            width: "20%",
            minWidth: "120",
            display: (row) => {
                return row.created || "Chưa cập nhật";
            }
        }, {
            label: "Số Lượng",
            width: "15%",
            minWidth: "100",
            display: (row) => row.quantity
        }, {
            label: "Giá",
            width: "15%",
            minWidth: "100",
            display: (row) => formatNumber(row.price)
        }];

        return (
            <div className="app-modal-box bill-catalog report-supplier">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Hoa đã làm</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <DataTable
                        columns={columns}
                        rows={flowers}
                        loading={products.length == 0}
                    />

                    <div className="modal-body">
                        <div className="text-right">
                            <b>Tổng tiền: {formatNumber(sumBy(flowers, f => f.price * f.quantity))}</b>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-link " data-dismiss="modal" onClick={() => onClose()}>Đóng</button>
                    </div>
                </div>
            </div>
        );
    }
}
