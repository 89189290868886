import React from "react";
import {Layout} from "../../components/layout/layout";
import {bannerApi} from "../../api/banner-dvh-api";
import {sortBy} from "lodash";
import {DataTable} from "../../components/data-table/data-table";
import {ButtonGroup} from "../../components/button-group/button-group";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {modals} from "../../components/modal/modals";
import {BannerManageModal} from "./banner-manage-modal";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {FooterBanner} from "./footer-banner";

export class BannerRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            banners: null,
            bannerSetting: null
        };

        bannerApi.getBanners().then((banners) => {
            this.setState({banners})
        })

    }

    handleUpdateStatus(row, key, value) {

        let {banners} = this.state;
        this.setState({
            banners: banners.map(f => f._id == row._id ? {...row, [key]: value} : f)
        });
        bannerApi.updateBanner(row._id, {...row, [key]: value});
    }


    add() {
        const modal = modals.openModal({
            content: (
                <BannerManageModal
                    banner={{
                        title: "",
                        imageSrc: null
                    }}
                    onDismiss={() => {
                        modal.close()
                    }}
                    onClose={() => {
                        bannerApi.getBanners().then((banners) => {
                            this.setState({banners});
                            modal.close();
                        })
                    }}
                />
            )
        })
    }

    edit(row) {
        console.log(row);
        const modal = modals.openModal({
            content: (
                <BannerManageModal
                    banner={row}
                    onDismiss={() => {
                        modal.close()
                    }}
                    onClose={() => {
                        bannerApi.getBanners().then((banners) => {
                            this.setState({banners});
                            modal.close();
                        })
                    }}
                />
            )
        })

    }

    remove(row) {
        confirmModal.show({
            title: "Xóa banner này?",
            description: "Bạn có đồng ý muốn xóa banner này không?"
        }).then(() => {
            let {banners} = this.state;
            this.setState({banners: banners.filter(c => c._id != row._id)});
            bannerApi.removeBanner(row._id);
        })
    }


    render() {

        let {banners, bannerSetting} = this.state;

        const columns = [{
            label: "STT",
            width: "5%",
            display: (row, index) => index + 1,
        }, {
            label: "Tên",
            width: "40%",
            display: (row) => row.title,
            minWidth: "150"
        }, {
            label: "Ảnh",
            width: "50%",
            display: (row) => (
                <ImgPreview
                    src={row.imageSrc}
                    style={{
                        height: "60px"
                    }}
                />
            ),
        }, {
            label: "",
            width: "5%",
            display: (row) => (
                <ButtonGroup
                    actions={[{
                        name: "Sửa",
                        icon: <i className="fa fa-pencil"/>,
                        click: () => this.edit(row)
                    }, {
                        name: `${row.isHidden ? "Hiện" : "Ẩn"}`,
                        icon: <i className={`fa fa-eye${row.isHidden ? "" : "-slash"}`}/>,
                        click: () => this.handleUpdateStatus(row, "isHidden", !row.isHidden)
                    }, {
                        name: "Xóa",
                        icon: <i className="fa fa-trash text-danger"/>,
                        click: () => this.remove(row)
                    }]}
                />
            ),
            minWidth: "50"
        }];

        let bannerSettingContents = [
            {
                label: "Tiêu đề Banner",
                key: 'title'
            }, {
                label: "Nội dung Banner",
                key: 'description'
            }, {
                label: "Banner Footer",
                key: 'footer'
            },
        ];

        return (
            <Layout
                activeRoute="Banner dịch vụ hoa"
            >
                <div className="banner-route web-manage-catalog-route">
                    <div className="card">
                        <div className="card-title">
                            Quản Lý Banner Dịch Vụ Hoa
                        </div>

                        <div className="card-body">
                            <button type="button" className="btn btn-primary" onClick={() => this.add()}>Thêm</button>
                        </div>

                        <DataTable
                            rows={sortBy(banners, c => c.sortIndex)}
                            columns={columns}
                            sortable
                            onChangeRows={(banners) => {
                                let updated = banners.map((c, index) => ({...c, sortIndex: index}));
                                this.setState({banners: updated});
                                bannerApi.updateBulkBanners(updated);
                            }}
                            rowStyling={(row) => {
                                if (row.isHidden) return {background: "rgba(253,57,122, .1)"};
                            }}
                        />
                    </div>




                </div>
            </Layout>
        );
    }
}
