import React from "react";
import classnames from "classnames";
import {CSSTransition, TransitionGroup} from "react-transition-group";

export class ComponentSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            direction: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedIndex !== this.props.selectedIndex) {

            if (nextProps.topBottom) {
                if (nextProps.alwayBottom) {
                    this.setState({direction: "top"})
                } else {
                    this.setState({direction: nextProps.selectedIndex > this.props.selectedIndex ? "top" : "bottom"});
                }

            } else {

                if (nextProps.alwayLeftToRight) {
                    this.setState({direction: "left"})
                } else {
                    this.setState({direction: nextProps.selectedIndex > this.props.selectedIndex ? "right" : "left"});
                }

            }
        }
    }

    render() {
        let {direction} = this.state;
        const {selectedIndex, getComponent, style, classNameItem = "", visible} = this.props;

        return (
            <div className={classnames("component-slider", visible && "visible")} style={style}>
                <TransitionGroup
                    className={classnames("panels-container", direction)}>
                    <CSSTransition
                        timeout={300}
                        classNames="slide"
                        key={selectedIndex}
                    >
                        <div className={classNameItem}>
                            {getComponent()}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}
