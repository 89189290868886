import React from "react";
import {ColumnViewMore} from "../../../components/column-view-more/column-view-more";
import sortBy from "lodash/sortBy";
import {formatNumber, getTotalBill} from "../../../common/common";
import sumBy from "lodash/sumBy";
import sum from "lodash/sum";
import {premisesInfo} from "../../../security/premises-info";
import {PaginationDataTableOffline} from "../../../components/data-table/pagination-data-table-offline";
import {CSVLink} from "react-csv";
import {Input} from "../../../components/input/input";
import {getCSVData} from "../../order/excel";
import XLSX from "xlsx";
import {ExportSheet} from "react-xlsx-sheet";

export class ReportCustomerSpend extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: ""
        }
    }


    columns = [{
        label: "Tên",
        display: (row) => row.customerName,
        width: "33.33%",
        minWidth: "150",
    }, {
        label: "Số Điện Thoại",
        display: (row) => row.customerPhone,
        width: "33.33%",
        minWidth: "100",
    }, {
        label: "Tổng Chi",
        display: (row) => {

            let totalPay = sumBy(this.props.bills.filter(b => b.customerId == row._id), b => getTotalBill(b));
            const getTotalPay = (customerId, isOwe) => {
                let _bills = [];
                if (isOwe) _bills = this.props.bills.filter(b => b.isOwe);

                const customerBills = _bills.filter(b => b.customerId == customerId);
                return sum(customerBills.map(b => getTotalBill(b)))
            };

            const getPayOfPremises = (customerId, premises_id) => {
                const customerBills = this.props.bills.filter(b => b.customerId == customerId && b.premises_id == premises_id);
                return sum(customerBills.map(b => getTotalBill(b)))
            };

            let premises = premisesInfo.getPremises();

            return (
                <ColumnViewMore
                    header={formatNumber(totalPay)}
                    renderViewMoreBody={() => premises.filter(p => getPayOfPremises(row._id, p._id) > 0).map((p, index) => (
                        <div className="info-item" key={index}>
                            {p.name}: <b>{formatNumber(getPayOfPremises(row._id, p._id))}</b>
                        </div>
                    ))}
                    viewMoreText="Chi Tiết"
                    subText={getTotalPay(row._id, true) > 0 && <div className="text-danger">Nợ: {formatNumber(getTotalPay(row._id, true))}</div>}
                    isShowViewMoreText={totalPay > 0}
                />
            )
        },
        width: "33.33%",
        minWidth: "150",
    }];

    render() {

        let {customers, bills, loading} = this.props;
        let {keyword} = this.state;
        let premises = premisesInfo.getPremises();

        const getPayOfPremises = (customerId, premises_id, bills) => {
            const customerBills = bills.filter(b => b.customerId == customerId && b.premises_id == premises_id);
            return sum(customerBills.map(b => getTotalBill(b)))
        };


        const getCSVData = () => {
            let header = [
                "Tên",
                "Số Điện Thoại",
                "Tổng Chi",
                ...premises.map(p => p.name)
            ];

            let csvData = [header];

            let _bills = [...bills];

            for (let i = 0; i < customers.length; i++) {
                let customer = customers[i];
                let customerBills = _bills.filter(b => b.customerId == customer._id);
                _bills = _bills.filter(b => b.customerId != customer._id);

                csvData.push([
                    customer.customerName,
                    customer.customerPhone,
                    sumBy(customerBills, b => getTotalBill(b)),
                    ...premises.map(p => getPayOfPremises(customer._id, p._id, customerBills))
                ])
            }


            return csvData;
        };

        console.log(getCSVData());



        return (
            <div className="customer-spend-report">

                <div className="card-body" style={{paddingTop: 0}}>
                    <Input
                        label="Tìm kiếm"
                        value={keyword}
                        onChange={(e) => this.setState({keyword: e.target.value})}
                    />
                </div>

                <div className="export-to-csv">
                    { !loading && (
                        <>
                            <ExportSheet
                                dataType="Array-of-Arrays"
                                fileName={`bao-cao`}
                                dataSource={getCSVData()}
                                xlsx={XLSX}
                            >
                                <button className="btn btn-primary btn-small">
                                    <span className="btn-text">Tải xuống</span>
                                    <span className="loading-icon"><i className="fa fa-file-excel-o"/></span>
                                </button>
                            </ExportSheet>
                        </>
                    )}

                </div>


                <PaginationDataTableOffline
                    loading={loading}
                    rows={sortBy(customers.filter(c => (c.customerName || "").toLowerCase().indexOf(keyword.toLowerCase()) > -1 || c.customerPhone.toLowerCase().indexOf(keyword.toLowerCase()) > -1), row => -sumBy(bills.filter(b => b.customerId == row._id), b => getTotalBill(b)))}
                    columns={this.columns}
                />
            </div>
        );
    }
}
