import React from "react";
import moment from "moment";
import {DataTable} from "../../components/data-table/data-table";
import sortBy from "lodash/sortBy";
import {premisesInfo} from "../../security/premises-info";
import {DatePicker} from "../../components/date-picker/date-picker";
import {warehouseApi} from "../../api/warehouse-api";
import {formatNumber} from "../../common/common";
import {getError, getReturned, getUsed} from "./warehouse-columns";
export class RequestHistoryModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            requests: null,
            bills: null
        }


        warehouseApi.viewHistory({baseID: props.baseID, importID: props.product.importID}).then(({requests, bills, totalQuantity, importedQuantity}) => {
            this.setState({bills, requests, totalQuantity, importedQuantity})
        })
    }

    render() {

        let {onClose, suppliers, product, baseID, selectedBase} = this.props;
        let {bills, requests, totalQuantity, importedQuantity} = this.state;
        const premises = [{_id: "all", name: "Kho Công Ty"}].concat(premisesInfo.getPremises());

        const requestTypesRender = {
            "request-from-supplier": (request) => <span><b className="text-primary">{suppliers.find(s => s._id == request.supplierID).name}</b> <i className="fa fa-arrow-right text-primary" aria-hidden="true"/> {premises.find(p => p._id == (request.toWarehouse ? request.toWarehouse : "all")).name}</span>,
            "return-to-supplier": () => <span><i className="fa fa-arrow-left text-danger" aria-hidden="true"/> Trả hàng </span>,
            "transfer-to-subwarehouse": (request) => <span>{premises.find(p => p._id == (request.fromWarehouse ? request.fromWarehouse : "all")).name} <i className="fa fa-arrow-right text-primary" aria-hidden="true"/> {premises.find(p => p._id == request.premisesID).name} </span>,
            "return-to-base": (request) => <span>Kho {premises.find(p => p._id == request.premisesID).name} <i className="fa fa-arrow-right text-danger" aria-hidden="true"/> Kho công ty </span>,
            "report-missing": () => <span><span className="text-danger">Hao Hụt</span></span>,
            "report-error": () => <span><span className="text-danger">Hủy Hỏng</span> </span>,
            "used_in_bill": (r) => <span className="text-primary">Florist {premises.find(p => p._id == r.premisesID).name} sử dụng</span>
        };


        const getQuantity = (request) => {

            if (request.requestType == "used_in_bill") return request.quantity;

            for (let item of request.items) {
                if (item.baseProductID == baseID || item.id == baseID || (request.requestType == "request-from-supplier" && item.importID == product.importID)) {
                    return item.quantity
                }
            }
        };


        const results = (requests || []).concat((bills || []).map(b => ({
                quantity: b.selectedFlower.find(b => (b.baseProductID == product._id || b.id == product._id || b.baseProductID == product.baseProductID))?.quantity || 0,
                requestType: "used_in_bill",
                created: b.deliverTime,
                premisesID: b.premises_id
            }
        )));


        let columns = [{
            label: "Thời Gian",
            display: (row) => moment(row.created).format("DD/MM/YYYY HH:mm"),
            width: "30%",
            minWidth: "150"
        }, {
            label: "Hình Thức",
            display: (request) => requestTypesRender[request.requestType](request),
            width: "50%",
            minWidth: "150"
        }, {
            label: "Số Lượng",
            display: (request) => getQuantity(request),
            width: "20%",
            minWidth: "150"
        }];


        return (
            <div className="request-history-modal app-modal-box">
                <div className="modal-header">
                    <div className="modal-title">
                        Lịch sử xuất nhập
                    </div>
                </div>


               <div className="modal-body">
                   {bills && (
                       <div className="total-static">
                           <div>Tổng nhập: <b className="text-primary">{formatNumber(importedQuantity)}</b></div>
                           <div>Tổng trả hàng: <b className="text-danger">{formatNumber(getReturned(product, requests))}</b></div>
                           <div>Sử dụng: <b className="text-danger">{formatNumber(getUsed(product, bills, selectedBase))}</b></div>
                           <div>Hao hụt: <b className="text-danger">{formatNumber(getError(product, "report-missing", requests, selectedBase))}</b></div>
                           <div>Hủy hỏng: <b className="text-danger">{formatNumber(getError(product, "report-error", requests, selectedBase))}</b></div>
                           <div>Tổng tồn hệ thống: <b className="text-primary">{formatNumber(totalQuantity)}</b></div>

                       </div>
                   )}
               </div>


                <DataTable
                    columns={columns}
                    rows={sortBy(results, l => new Date(l.created).getTime())}
                    loading={!bills}
                />

                <div className="modal-footer">
                    <button type="button" className="btn btn-link " data-dismiss="modal" onClick={() => onClose()}>Đóng</button>
                </div>
            </div>
        );
    }
}
