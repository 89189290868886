import React from "react";
import { webManageProductApi } from "../../api/web-manage-product-api";
import { minVal, required } from "../../components/form/validations";
import { Form } from "../../components/form/form";
import { Input } from "../../components/input/input";
import { Select } from "../../components/select/select";
import { InputNumber } from "../../components/input-number/input-number";
import { SelectTagsColor } from "../../components/select-tags-color/select-tags-color";
import { InputUploadMultiple } from "../../components/input-upload-multiple/input-upload-multiple";
import { WYSIWYGEditor } from "../../components/wysiwyg-editor/wysiwyg-editor";
import { collectionApi } from "../../api/collection-api";
import { SelectTags } from "../../components/select-tags/select-tags";
import { isEqual } from "lodash"
import { TagsForm } from "../../components/tags-form/tags-form";
import { confirmModal } from "../../components/confirm-modal/confirm-modal";
import {buildUrl} from '../../common/common';
export class WebManageProductModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: props.product,
            error: false,
            selectedDiscountType: props.product.discountedPercent ? "percent" : "number",
            collections: [],
            selectedCollection: [],
            oriCollection: []
        };

        collectionApi.getCollections().then(({ collections }) => {
            this.setState({ collections })
        });


        if (props.product._id) {
            collectionApi.getCollectionsViaProduct(props.product._id).then((collections) => {
                this.setState({
                    selectedCollection: collections.map(c => c._id),
                    oriCollection: collections.map(c => c._id)
                })
            })
        }


    }

    submit() {
        let { product } = this.props;
       
        if (!product._id) {
            this.setState({ saving: true });
          
            webManageProductApi.createProduct(this.state.product).then((resp) => {
                if (resp.error) {
                    confirmModal.alert(resp.error);
                    this.setState({ error: true, saving: false });
                } else {
                    collectionApi.upsertProductToCollection({
                        productID: resp._id,
                        collections: this.state.selectedCollection
                    }).then(() => {
                        this.props.onClose();
                    });

                }
            })
        } else {
            this.setState({ saving: true });
            webManageProductApi.updateProduct(product._id, this.state.product).then((resp) => {
                if (resp.error) {
                    confirmModal.alert(resp.error);
                    this.setState({ error: true, saving: false });
                } else {
                    if (!isEqual(this.state.oriCollection, this.state.selectedCollection)) {
                        collectionApi.upsertProductToCollection({
                            productID: resp._id,
                            collections: this.state.selectedCollection
                        }).then(() => {
                            this.props.onClose();
                        });
                    } else {
                        this.props.onClose();
                    }
                }
            })
        }
    }


    render() {

        let validations = [
            { "description": [required("Thông Tin Sản Phẩm")] },
            // {"code": [required("Mã sản phẩm")]},
            { "name": [required("Tên")] },
            {
                "images": [(val) => ({
                    text: "Ảnh sản phẩm k được để trống",
                    valid: val.length > 0
                })
                ]
            },
            { "colors": [required("Màu")] },
            { "catalogs": [required("Danh mục")] },
            { "price": [minVal("Giá", 0), required("Giá")] },
            { "width": [minVal("Chiều rộng", 0), required("Chiều rộng")] },
            { "height": [minVal("Chiều dài", 0), required("Chiều dài")] },
            { "mainFlowerQty": [minVal("Số lượng hoa chính", 0), required("Số lượng hoa chính")] },
            { "mainFlower": [required("Hoa chính")] },
            { "subFlower": [required("Hoa phụ")] },
            { "subFlowerQty": [required("Số lượng hoa phụ"), minVal("Số lượng hoa phụ", 0)] },
        ];

        let { product, saving, selectedDiscountType, collections, selectedCollection } = this.state;
        let { onDismiss, catalogs, catalogsBase, editType, items } = this.props;
        let getFullCatalog = (name) => catalogsBase.find(c => c.name === name);


        return (
            <div className="manage-product-modal app-modal-box container full-width">
                <Form
                    onSubmit={() => this.submit()}
                    formValue={product}
                    validations={validations}
                    render={(getInvalidByKey) => (
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{product._id ? "Sửa" : "Thêm"} sản phẩm</h5>
                                <button type="button" className="close" onClick={() => onDismiss()}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-6">
                                        {editType && (
                                            <Input
                                                label="Mã Sản Phẩm"
                                                value={product.code}
                                                readOnly
                                                error={getInvalidByKey("code")}
                                            />
                                        )}

                                        <Input
                                            label="Tên Sản Phẩm*"
                                            value={product.name}
                                            onChange={(e) => {
                                                let nameValue = e.target.value;
                                                this.setState({ product: { ...product,  name: nameValue  } }); 
                                            }}
                                            error={getInvalidByKey("name")}
                                        />
                                         

                                        <Input
                                            textArea
                                            label="Thông Tin Sản Phẩm*"
                                            value={product.description}
                                            onChange={(e) => this.setState({ product: { ...product, description: e.target.value } })}
                                            error={getInvalidByKey("description")}
                                        />

                                        <SelectTags
                                            label="Bộ sưu tập"
                                            tags={selectedCollection}
                                            onChange={(collections) => this.setState({ selectedCollection: collections })}
                                            list={collections.map(c => c._id)}
                                            displayAs={(v) => collections.find(c => c._id == v)?.title}
                                        />

                                        <SelectTags
                                            label="Danh mục*"
                                            tags={product.catalogs}
                                            onChange={(catalogs) => this.setState({ product: { ...product, catalogs: catalogs } })}
                                            list={items.map(c => c._id)}
                                            displayAs={(v) => {
                                                let item = items.find(i => i._id == v);
                                                if (!item) return null;
                                                let father = catalogs.find(c => c._id == item.fatherID);
                                                return `${father.name} - ${item.name}`
                                            }}
                                            error={getInvalidByKey("catalogs")}
                                        />

                                        <Select
                                            label="Size*"
                                            value={product.size}
                                            onChange={(size) => this.setState({ product: { ...product, size } })}
                                            list={["XS", "S", "M", "L", "XL", "XXL"]}
                                        />

                                        <InputUploadMultiple
                                            label="Ảnh Sản Phẩm*"
                                            images={product.images}
                                            onChange={(images) => this.setState({ product: { ...product, images } })}
                                            error={getInvalidByKey("images")}
                                        />

                                        <SelectTagsColor
                                            label="Màu*"
                                            tags={product.colors || []}
                                            onChange={(colors) => this.setState({ product: { ...product, colors } })}
                                            error={getInvalidByKey("colors")}
                                        />

                                        <InputNumber
                                            label="Giá*"
                                            value={product.price}
                                            onChange={(price) => this.setState({ product: { ...product, price } })}
                                            error={getInvalidByKey("price")}
                                        />



                                    </div>
                                    <div className="col-md-6">


                                        <InputNumber
                                            label="Chiều dài*"
                                            value={product.height}
                                            onChange={(height) => this.setState({ product: { ...product, height } })}
                                            error={getInvalidByKey("height")}
                                        />

                                        <InputNumber
                                            label="Chiều rộng*"
                                            value={product.width}
                                            onChange={(width) => this.setState({ product: { ...product, width } })}
                                            error={getInvalidByKey("width")}
                                        />
                                        <Input
                                            label="Hoa chính*"
                                            value={product.mainFlower}
                                            onChange={(e) => this.setState({ product: { ...product, mainFlower: e.target.value } })}
                                            error={getInvalidByKey("mainFlower")}
                                        />

                                        <InputNumber
                                            label="Số lượng hoa chính*"
                                            value={product.mainFlowerQty}
                                            onChange={(mainFlowerQty) => this.setState({ product: { ...product, mainFlowerQty } })}
                                            error={getInvalidByKey("mainFlowerQty")}
                                        />

                                        <Input
                                            label="Hoa phụ*"
                                            value={product.subFlower}
                                            onChange={(e) => this.setState({ product: { ...product, subFlower: e.target.value } })}
                                            error={getInvalidByKey("subFlower")}
                                        />

                                        <InputNumber
                                            label="Số lượng hoa phụ*"
                                            value={product.subFlowerQty}
                                            onChange={(subFlowerQty) => this.setState({ product: { ...product, subFlowerQty } })}
                                            error={getInvalidByKey("subFlowerQty")}
                                        />




                                        <Select
                                            list={["number"]}
                                            value={selectedDiscountType}
                                            onChange={(type) => this.setState({
                                                selectedDiscountType: type,
                                                product: {
                                                    ...product,
                                                    discountedNumber: type == "number" ? product.discountedNumber : 0,
                                                    discountPercent: type == "percent" ? product.discountPercent : 0,
                                                }
                                            })}
                                            displayAs={(value) => value == "percent" ? "%" : "Tiền mặt"}
                                            label="Loại giảm giá"
                                        />

                                        <InputNumber
                                            label="Giảm giá"
                                            value={selectedDiscountType == "percent" ? product.discountPercent : product.discountedNumber}
                                            onChange={(value) => this.setState({
                                                product: {
                                                    ...product,
                                                    discountedNumber: selectedDiscountType == "number" ? value : 0,
                                                    discountPercent: selectedDiscountType == "percent" ? value : 0,
                                                }
                                            })}
                                        />

                                        <Input
                                            label="Note"
                                            value={product.note}
                                            onChange={(e) => this.setState({ product: { ...product, note: e.target.value } })}
                                            textArea
                                        />

                                        <TagsForm
                                            tags={product.tags}
                                            onEnter={(value) => this.setState({ product: { ...product, tags: [...product.tags, value] } })}
                                            onRemove={(value) => this.setState({ product: { ...product, tags: product.tags.filter(t => t !== value) } })}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <WYSIWYGEditor
                                            label="Nội Dung"
                                            value={product.content}
                                            onChange={(content) => this.setState({ product: { ...product, content: content } })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                                <button type="submit"
                                    className="btn btn-primary">
                                    <span className="btn-text">Lưu</span>
                                    {saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse" /></span>}
                                </button>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    }
}
