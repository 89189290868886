import React from "react";
import {Layout} from "../../components/layout/layout";
import {DataTable} from "../../components/data-table/data-table";
import {modals} from "../../components/modal/modals";
import {ManageSettingModal} from "./manage-setting-modal";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {ManageFatherModal} from "./manage-father-modal";
import {webProductTypeApi} from "../../api/web-product-type-api";
const filterTypeID = "5ec63e9bf4b3797e96e246d1";

export class WebManageProductTypeRoute extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            productTypes: null
        };

        webProductTypeApi.get().then(({catalogs, items}) => {
            this.setState({catalogs, items});
        })
    };


    addProductType(fatherID) {
        const modal = modals.openModal({
            content: (
                <ManageSettingModal
                    productType={{
                        name: "",
                        fatherID
                    }}
                    onClose={(productType) => {
                        webProductTypeApi.create(productType).then((resp) => {
                            if (resp.error) {
                                confirmModal.alert(resp.error);
                            } else {
                                this.setState({
                                    items: this.state.items.concat(resp)
                                });
                            }

                            modal.close();

                        });
                    }}
                    onDismiss={() => {
                        modal.close();
                    }}
                />
            )
        })
    }

    add() {
        const modal = modals.openModal({
            content: (
                <ManageFatherModal
                    catalog={{
                        name: "",
                    }}
                    onClose={(catalog) => {
                        webProductTypeApi.createCatalogFather(catalog).then((resp) => {
                            if (resp.error) {
                                confirmModal.alert(resp.error);
                            } else {
                                this.setState({
                                    catalogs: this.state.catalogs.concat(resp)
                                });
                            }
                            modal.close();
                        });

                    }}
                    onDismiss={() => {
                        modal.close();
                    }}
                />
            )
        })
    }

    remove(item) {
        confirmModal.show({
            title: `Xoá danh mục?`,
            description: "Bạn có đồng ý xoá danh mục này không?"
        }).then(() => {
            webProductTypeApi.delete(item._id).then((resp) => {
                if (resp.error) {
                    confirmModal.alert("Danh mục đang được sử dụng ở sản phẩm khác nên không thể xóa.")
                } else {
                    this.setState({
                        items: this.state.items.filter(p => p._id != item._id)
                    });
                }
            });

        })
    }


    edit(row) {
        const modal = modals.openModal({
            content: (
                <ManageSettingModal
                    productType={row}
                    onClose={(item) => {
                        webProductTypeApi.update(item._id, item).then((resp) => {
                            if (resp.error) {
                                confirmModal.alert(resp.error);
                            } else {
                                this.setState({
                                    items: this.state.items.map((p => p._id == item._id ? item : p))
                                });
                            }

                            modal.close();
                        });

                    }}
                    onDismiss={() => {
                        modal.close();
                    }}
                />
            )
        })
    }

    removeFather(item) {
        confirmModal.show({
            title: `Xoá danh mục?`,
            description: "Bạn có đồng ý xoá danh mục này không?. Mọi danh mục con cũng sẽ biến mất."
        }).then(() => {
            webProductTypeApi.removeCatalogFather(item._id).then((resp) => {
                if (resp.error) {
                    confirmModal.alert("Danh mục đang được sử dụng ở sản phẩm khác nên không thể xóa.")
                } else {
                    this.setState({
                        catalogs: this.state.catalogs.filter(p => p._id != item._id)
                    });
                }
            });

        })
    }


    editFather(row) {
        const modal = modals.openModal({
            content: (
                <ManageFatherModal
                    catalog={row}
                    onClose={(item) => {
                        webProductTypeApi.updateCatalogFather(item._id, item).then((resp) => {
                            if (resp.error) {
                                confirmModal.alert(resp.error);
                            } else {
                                this.setState({
                                    catalogs: this.state.catalogs.map((p => p._id == item._id ? item : p))
                                });
                            }
                            modal.close();
                        });

                    }}
                    onDismiss={() => {
                        modal.close();
                    }}
                />
            )
        })

    }

    render() {

        let {catalogs, items} = this.state;


        let columns = [{
            label: "Danh mục cha",
            width: "30%",
            display: (row) => row.name,
            sortBy: (row) => row.name,
            minWidth: "150"
        }, {
            label: "Danh mục con",
            width: "60%",
            display: (row) => (
                <div className="catalogs-item">
                    {items.filter(item => item.fatherID == row._id).map((childItem, index) => (
                        <div className="catalog-item" key={index}>
                            <span className="catalog-text">
                                {childItem.name}
                                <i className="fa fa-edit add-icon" onClick={() => this.edit(childItem)}/>
                                <i className="fa fa-trash remove-icon" onClick={() => this.remove(childItem)}/>
                            </span>
                        </div>
                    ))}

                    <button className="btn btn-small btn-primary add-button" onClick={() => this.addProductType(row._id)}>
                        Thêm danh mục con
                    </button>
                </div>
            ),
            minWidth: "300"
        }, {
            label: "",
            width: "10%",
            display: (row) => (
                <div className="catalog-item">
                    <i className="fa fa-edit add-icon" onClick={() => this.editFather(row)}/>
                    {row._id != filterTypeID && <i className="fa fa-trash remove-icon" onClick={() => this.removeFather(row)}/>}
                </div>
            ),
            className: "text-right",
            minWidth: "60"
        }];


        return (
            <Layout
                activeRoute="Danh Mục Sản Phẩm Web"
            >
                <div className="web-manage-product-type">
                    <div className="card">
                        <div className="card-title">
                            Danh Mục Sản Phẩm Web
                        </div>

                        <div className="card-body">
                            <button type="button" className="btn btn-primary btn-medium" onClick={() => this.add()}>Thêm danh mục cha</button>
                        </div>

                        <DataTable
                            rows={catalogs}
                            columns={columns}
                        />
                    </div>

                </div>
            </Layout>
        );
    }
}
