import React from "react";
import {InputNumber} from "../input-number/input-number";

export class InputQuantity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value
        }
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.value != this.state.inputValue) {
            this.setState({inputValue: props.value})
        }
    }


    render() {

        let {value, onChange, maxVal = 99999999} = this.props;
        let {inputValue} = this.state;

        return (
            <div className="input-quantity">
                <button className="btn btn-danger btn-small"
                    onClick={() => {
                        onChange(value - 1);
                        this.setState({inputValue: value - 1});
                    }}
                >
                    <i className="fa fa-minus" aria-hidden="true"/>
                </button>

                <InputNumber
                    maxVal={maxVal}
                    value={inputValue}
                    onChange={(value) => {
                        this.setState({inputValue: value});
                        if (value > 0) {
                            onChange(value)
                        }
                    }}
                    onBlur={() => {
                        if (inputValue != value) {
                            this.setState({inputValue: value})
                        }
                    }}
                />

                <button className="btn btn-primary btn-small"
                    onClick={() => {
                        onChange(Math.min(value + 1, maxVal));
                        this.setState({inputValue: Math.min(value + 1, maxVal)});
                    }}
                >
                    <i className="fa fa-plus" aria-hidden="true"/>
                </button>
            </div>
        );
    }
}
