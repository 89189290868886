import React from "react";
import {flowersApi} from "../../../api/flowers-api";
import {modals} from "../../../components/modal/modals";
import {ComboViewModal} from "./combo-view-modal";
export class FloristCombo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            combos: []
        }

        flowersApi.getCombos().then(({combos}) => {
            this.setState({combos})
        })
    }

    viewCombo(combo) {
        let {suppliers, onSelect} = this.props;

        const modal = modals.openModal({
            content: (
                <ComboViewModal
                    combo={combo}
                    onClose={(products) => {
                        onSelect(products);
                        modal.close();
                    }}
                    onDismiss={() => modal.close()}
                    suppliers={suppliers}
                />
            )
        })
    }

    render() {
        let {combos} = this.state;

        if (combos.length == 0) return null;

        return (
            <div className="card">
                <div className="card-title">
                    Combo
                </div>

                <div className="card-body">
                    <div className="combo-wrapper">
                        {combos.map((combo, index) => (
                            <div className="card-combo" key={index}
                                onClick={() => this.viewCombo(combo)}
                            >
                                {combo.name}
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        );
    }
}
