import React from "react";
import {dichvuhoaApi} from "../../api/dich-vu-hoa-api";
import {Layout} from "../../components/layout/layout";
import {Input} from "../../components/input/input";
import {PictureUpload} from "../../components/picture-upload/picture-upload";
import {WYSIWYGEditor} from "../../components/wysiwyg-editor/wysiwyg-editor";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {LoadingOverlay} from "../../components/loading-overlay/loading-overlay";
import uniq from "lodash/uniq";
import {AutoCompleteNormal} from "../../components/auto-complete/auto-complete-normal";
import {TagsForm} from "../../components/tags-form/tags-form";
import { buildUrl } from "../../common/common";
import { WYSIWYGEditor4 } from "../../components/wysiwyg-editor/wysiwyg-editor4";
export class DichVuHoaRoute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tid: "dich-vu-hoa",
            title: "",
            cateIds: "",
            description: "",
            content: "",
            imagePreview: null,
            schemaText: '',
            banner: null
           
            
        };

        dichvuhoaApi.get("dich-vu-hoa").then((ct) => {
            this.setState({...ct});
        });


       
    }

    submit() {

        dichvuhoaApi.update(this.state.tid, this.state).then((resp) => {
            if (resp.error) {
                confirmModal.alert(resp.error);
            } else {
                confirmModal.alert("Cập nhật thành công");
            }
        })


    }

    render() {

        let {title, description, schemaText, content,cateIds, imagePreview} = this.state;
        let {match} = this.props;
        
        return (
            <Layout
                activeRoute="Dịch vụ điện hoa"
            >
                <div className="blog-route">

                    <LoadingOverlay
                        show={match.params.id && !this.state._id}
                    >
                        <div className="card">
                            <div className="card-title">
                               Dịch vụ hoa
                            </div>

                            <div className="card-body">
                            <Input
                                    textArea
                                    label="cate ids"
                                    value={cateIds}
                                    onChange={(e) => this.setState({cateIds: e.target.value})}
                                />
                                <Input
                                    label="Tên Bài"
                                    value={title}
                                    onChange={(e) =>{
                                        let valueName = e.target.value; 
                                        this.setState({title: valueName});
                                        if(!match.params.id) {
                                            this.setState({slug: buildUrl(valueName, Date.now().toString(32))});
                                        }
                                    } }
                                />
                                
                               <Input
                                    textArea
                                    label="Mô Tả Ngắn"
                                    value={description}
                                    onChange={(e) => this.setState({description: e.target.value})}
                                />

                                <Input
                                    textArea
                                    label="Schema"
                                    value={schemaText}
                                    onChange={(e) => this.setState({schemaText: e.target.value})}
                                />  

                                <PictureUpload
                                    noResize
                                    label="Ảnh Đại Diện"
                                    value={imagePreview}
                                    onChange={(imagePreview) => this.setState({imagePreview})}
                                />
                              
                                
                                <WYSIWYGEditor4
                                    label="Nội Dung"
                                    value={content}
                                    onChange={(content) => this.setState({content})}
                                />

                                
                                 

                            </div>

                            <div className="card-body">
                                <div className="text-right">
                                    <button className="btn btn-primary"
                                            disabled={title.length == 0 || description.length == 0 || !imagePreview }
                                            onClick={() => this.submit()}
                                    >
                                        Cập nhật
                                    </button>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </Layout>
        );
    }
}
