import React from "react";
import {flowersApi} from "../../api/flowers-api";
import {AutoComplete} from "../../components/auto-complete/auto-complete";
import {DataTable} from "../../components/data-table/data-table";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {uniq} from "lodash";
import {Input} from "../../components/input/input";
export class ManageComboModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productID: "",
            combo: props.combo
        }
    }

    render() {
        let {onDismiss, onClose} = this.props;
        let {combo, saving} = this.state;

        const columns = [{
            label: "Mã",
            display: (row) => row.productID,
            width: "20%"
        }, {
            label: "Thông tin",
            display: (row) => (
                <div className="product-name" >
                    <ImgPreview src={row.image}/> {row.name}
                </div>
            ),
            width: "30%"
        }, {
            label: "Loại",
            display: (row) => row.catalog,
            width: "40%"
        }, {
            label: "",
            width: "10%",
            display: (row) => (
                <button
                    onClick={() => this.setState({combo: {...combo, flowerIDs: combo.flowerIDs.filter(p => p.productID != row.productID)}})}
                    className="btn btn-danger btn-small"><i className="fa fa-trash "/></button>
            ),
            minWidth: "50",
        }]



        return (
            <div className="app-modal-box warehouse-route products-route">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{combo._id ? "Thêm combo" : "Sửa combo"}</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Input
                            label="Tên"
                            value={combo.name}
                            onChange={(e) => this.setState({combo: {...combo, name: e.target.value}})}
                        />

                        <AutoComplete
                            asyncGet={async (name) => {
                                if (name.length > 0) {
                                    const flowers = await flowersApi.getFlowers({keyword: name, skip: 0}).then((resp) => resp.flowers);
                                    return flowers.filter(p => combo.flowerIDs.map(p => p.productID).indexOf(p.productID) == -1)
                                }
                                return Promise.resolve([])
                            }}
                            onSelect={(product) => {
                                this.setState({combo: {...combo, flowerIDs: combo.flowerIDs.concat(product)}, productID: ""})
                            }}
                            objectKey="productID"
                            object={this.state}
                            onChange={(value) => this.setState({productID: value})}
                            displayAs={(product) => <span>{product.productID} - <b>{product.name}</b> - {product.catalog}</span>}
                            noPopup
                            label="Tên/Mã Sản Phẩm"
                        />
                    </div>

                    <DataTable
                        columns={columns}
                        rows={combo.flowerIDs}
                    />

                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                        <button type="submit"
                                disabled={combo.name.length == 0 || combo.flowerIDs.length == 0 || saving }
                                onClick={() => {
                                    this.setState({saving: true});
                                    onClose({...combo, flowerIDs: combo.flowerIDs.map(f => f._id)});
                                }}
                                className="btn btn-primary">
                            <span className="btn-inner--text">Lưu</span>
                            { saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
