import React from 'react';
import {Input} from "../../components/input/input";
import {productApi} from "../../api/product-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import { WYSIWYGEditor } from '../../components/wysiwyg-editor/wysiwyg-editor';


export class AboutSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value : '',
            loading :true
        };

        productApi.getAboutText().then(({text}) =>{
            this.setState({ value : text ,loading : false })
        })
    }

    saveWarningText(){
        let {value } =this.state ;
        productApi.createAboutText({text : value}).then(()=>{
            confirmModal.alert("Lưu thành công !");
        })
    }

    render() {
        let {value , loading} = this.state ;
        return(
            <div className="card">
                <div className="card-title">
                    Về chúng tôi
                </div>
                {!loading && (
                    <div className="card-body set-warning-text">
                        <WYSIWYGEditor
                                 
                                    label={"Về chúng tôi"}
                                    value={value}
                                    onChange={(value) => this.setState({value: value})}
                                />
                       
                        <button className="btn btn-primary"
                                onClick={() => this.saveWarningText()}>
                        <span className="btn-text">
                            Lưu
                        </span>
                        </button>
                    </div>
                )}
            </div>
        )
    }
}