import React from "react";
import {blogApi} from "../../api/blog-api";
import {Layout} from "../../components/layout/layout";
import {Input} from "../../components/input/input";
import {PictureUpload} from "../../components/picture-upload/picture-upload";
import {WYSIWYGEditor} from "../../components/wysiwyg-editor/wysiwyg-editor";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {LoadingOverlay} from "../../components/loading-overlay/loading-overlay";
import uniq from "lodash/uniq";
import {AutoCompleteNormal} from "../../components/auto-complete/auto-complete-normal";
import {TagsForm} from "../../components/tags-form/tags-form";
import { buildUrl } from "../../common/common";

export class BlogManageRoute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            slug: '',
            description: "",
            content: "",
            imagePreview: null,
            banner: null,
            catalog: "",
            seoTitle: "", 
            seoDes: "",
            tags :[],
            catalogs: ["Khách hàng của Liti Florist", "Hoa dành cho các dịp lễ", "Hoa và cuộc sống", "DIY", "Từ điển các loài hoa", "Sự kiện", "Báo chí"]
        };

        blogApi.getBlogCatalogs().then((catalogs) => {
            this.setState({catalogs: uniq(this.state.catalogs.concat(catalogs))});
        });


        if (props.match.params.id) {
            blogApi.getBlog(props.match.params.id).then((blog) => {
                this.setState(blog);
            })
        }
    }

    submit() {

        let {match} = this.props;

        if (match.params.id) {
            blogApi.updateBlog(this.state._id, this.state).then((resp) => {
                if (resp.error) {
                    confirmModal.alert(resp.error);
                } else {
                    confirmModal.alert("Cập nhật thành công");
                }
            })

        } else {
            blogApi.createBlog(this.state).then((resp) => {
                if (resp.error) {
                    confirmModal.alert(resp.error);
                } else {
                    confirmModal.alert("Bài viết của bạn đã được tạo thành công.");
                    this.setState({
                        title: "",
                        description: "",
                        content: "",
                        imagePreview: null,
                        catalog: "",
                        tags :[]
                    })
                }


            })
        }


    }

    render() {

        let {title, description, content, imagePreview,banner, catalog, catalogs , tags, slug, seoTitle, seoDes} = this.state;
        let {match} = this.props;

        return (
            <Layout
                activeRoute="Blog"
            >
                <div className="blog-route">

                    <LoadingOverlay
                        show={match.params.id && !this.state._id}
                    >
                        <div className="card">
                            <div className="card-title">
                                Viết Bài
                            </div>

                            <div className="card-body">
                                <Input
                                    label="Tên Bài"
                                    value={title}
                                    onChange={(e) =>{
                                        let valueName = e.target.value; 
                                        this.setState({title: valueName});
                                        if(!match.params.id) {
                                            this.setState({slug: buildUrl(valueName, Date.now().toString(32))});
                                        }
                                    } }
                                />
                               <Input
                                    textArea
                                    label="Mô Tả Ngắn"
                                    value={description}
                                    onChange={(e) => this.setState({description: e.target.value})}
                                />
                                                                  

                                <AutoCompleteNormal
                                    label="Danh mục"
                                    className="col"
                                    value={catalog}
                                    onSelect={(catalog) => this.setState({catalog})}
                                    onChange={(catalog) => this.setState({catalog})}
                                    displayAs={(item) => item}
                                    defaultList={catalogs}
                                />

                               
                                <PictureUpload
                                    noResize
                                    label="Ảnh Banner"
                                    value={banner}
                                    onChange={(banner) => this.setState({banner})}
                                />

                                <PictureUpload
                                    noResize
                                    label="Ảnh Đại Diện"
                                    value={imagePreview}
                                    onChange={(imagePreview) => this.setState({imagePreview})}
                                />

                                <WYSIWYGEditor
                                    label="Nội Dung"
                                    value={content}
                                    onChange={(content) => this.setState({content})}
                                />

                                <TagsForm
                                    tags={tags}
                                    onEnter={(value) =>  this.setState({ tags : [...tags, value]})}
                                    onRemove={(value) => this.setState({tags : tags.filter(t => t!== value )})}
                                />
                                 <Input                                    
                                    label="Title SEO"
                                    value={seoTitle}
                                    onChange={(e) => this.setState({seoTitle: e.target.value})}
                                />
                                <Input
                                    
                                    label="url"
                                    value={slug}
                                    onChange={(e) => this.setState({slug: e.target.value})}
                                />
                                 <Input
                                    textArea
                                    label="Description SEO"
                                    value={seoDes}
                                    onChange={(e) => this.setState({seoDes: e.target.value})}
                                />

                            </div>

                            <div className="card-body">
                                <div className="text-right">
                                    <button className="btn btn-primary"
                                            disabled={title.length == 0 || description.length == 0 || !imagePreview || catalog.length == 0}
                                            onClick={() => this.submit()}
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </Layout>
        );
    }
}
