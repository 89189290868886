import {cache} from "../../common/cache";
import {permissionInfo, premisesInfo} from "../../security/premises-info";
import {securityFe} from "../../security/security-fe";
import {modals} from "../modal/modals";
import {ChangePasswordModal} from "./change-password-modal";
import React from "react";
import {confirmModal} from "../confirm-modal/confirm-modal";
import {ConfigModal} from "./config-modal";

export const navItems = (user) => {

    const permission = permissionInfo.getPermission();

    return [{
        label: "Hoá Đơn",
        to: "/",
        hide: () => !securityFe.isHavePermission(["bill.edit", "bill.create"]),
        icon: <i className="fa fa-file-text-o nav-icon"/>
    }, {
        label: "Đơn Hàng",
        icon: <i className="fa fa-list nav-icon"/>,
        child: [{
            label: "Đơn Chính",
            to: "/orders",
            hide: () => !securityFe.isHavePermission(["bill.view"])
        }, {
            label: "Đơn Sẵn",
            to: "/draft",
            hide: () => !securityFe.isHavePermission(["bill.view"])
        }, {
            label: "Đơn Chờ Làm",
            to: "/florist",
            hide: () => user.role != "florist"
        }, {
            label: "Đơn Chờ Ship",
            to: "/ship",
            hide: () => user.role != "ship"
        }],
        hide: () => user.role != "ship" && user.role != "florist" && !permission[user.role].find(r => r.indexOf("bill") > -1)
    }, {
        label: "Doanh Thu Của Tôi",
        hide: () => ["florist", "ship", "sale"].indexOf(user.role) == -1,
        icon: <i className="fa fa-money nav-icon"/>,
        to: "/salary"
    }, {
        label: "Báo Cáo",
        icon: <i className="fa fa-bar-chart nav-icon"/>,
        child: [{
            label: "Doanh Thu",
            to: "/report-revenue",
            hide: () => permission[user.role].indexOf("report.report-revenue") == -1
        }, {
            label: "Đơn Hàng",
            to: "/report-bill",
            hide: () => permission[user.role].indexOf("report.report-bill") == -1
        }, {
            label: "Khách Hàng",
            to: "/report-customer",
            hide: () => permission[user.role].indexOf("report.report-customer") == -1
        }, {
            label: "Khuyến Mại",
            to: "/report-discount",
            hide: () => permission[user.role].indexOf("report.report-promotion") == -1
        }, {
            label: "Nhà Cung Cấp",
            to: "/report-supplier",
            hide: () => !securityFe.isHavePermission(["report.report-supplier"])
        }, {
            label: "Đơn Florist Làm",
            to: "/report-florist-working",
            hide: () => !securityFe.isHavePermission(["report.report-florist-working"])
        }],
        hide: () => !permission[user.role].find(r => r.indexOf("report") > -1)
    }, {
        label: "Khách Hàng",
        icon: <i className="fa fa-users nav-icon"/>,
        child: [{
            label: "Danh Sách Khách Hàng",
            to: "/customers",
            hide: () => permission[user.role].indexOf("customer.list") == -1
        }, {
            label: "Mã Giảm Giá",
            to: "/discount-codes",
            hide: () => permission[user.role].indexOf("customer.discount-codes") == -1
        }, {
            label: "Đơn hàng gần nhất",
            to: "/customer-last-bill",
            hide: () => permission[user.role].indexOf("customer.last-bill") == -1
        }, {
            label: "Đổi số điện thoại",
            to: "/customer-change-phone",
            hide: () => permission[user.role].indexOf("customer.change-phone") == -1
        }, {
            label: "Khách hàng chưa quay lại",
            to: "/customer-not-buy",
            hide: () => permission[user.role].indexOf("customer.customer-not-buy") == -1
        }],
        hide: () => !permission[user.role].find(r => r.indexOf("customer") > -1)
    }, {
        label: "Chiến Dịch Khuyến Mại",
        to: "/promotion",
        icon: <i className="fa fa-gift nav-icon"/>,
        hide: () => !permission[user.role].find(r => r.indexOf("promotion") == 0)
    },
    {
        label: "Mã khuyến mại",
        to: "/promotion-code",
        icon: <i className="fa fa-gift nav-icon"/>,
        hide: () => !permission[user.role].find(r => r.indexOf("promotion") == 0)
    },
    
    {
        label: "Ngày Này Năm Xưa",
        to: "/memories",
        icon: <i className="fa fa-calendar nav-icon"/>,
        hide: () => !securityFe.isHavePermission(["memories"])
    },  {
        label: "Kho Ảnh",
        to: "/gallery",
        icon: <i className="fa fa-picture-o nav-icon"/>,
        hide: () => false
    }, {
        label: "Kho",
        icon: <i className="fa fa-truck nav-icon"/>,
        child: [{
            to: "/products",
            label: "Danh Sách Sản Phẩm",
            hide: () => !permission[user.role].find(r => r.indexOf("warehouse.product") > -1)
        }, {
            to: "/request-warehouse",
            label: "Phiếu Xuất Nhập Kho",
            hide: () => !permission[user.role].find(r => r.indexOf("warehouse.request") > -1)
        }, {
            to: "/warehouse",
            label: "Tồn Kho",
            hide: () => false
        }, {
            to: "/manage-combo",
            label: "Cài đặt combo",
            hide: () => !permission[user.role].find(r => r.indexOf("warehouse.update-combo") > -1)
        },
        {
            label: "Quản Lý Nhà Cung Cấp",
            to: "/manage-supplier",
            hide: () => !securityFe.isHavePermission(["warehouse.manage-supplier"])
        }],
        hide: () => !permission[user.role].find(r => r.indexOf("warehouse") > -1)
    }, {
        label: "Quản trị WEB",
        icon: <i className="fa fa-globe nav-icon"/>,
        child: [{
            label: "Danh Mục Sản Phẩm Web",
            to: "/web-product-type",
            hide: () => !securityFe.isHavePermission(["web-manager.web-product-type"])
        }, {
            label: "Sản Phẩm",
            to: "/web-manage-product",
            hide: () => !securityFe.isHavePermission(["web-manager.product"])
        }, {
            label: "Bộ Sưu Tập",
            to: "/web-manage-collection",
            hide: () => !securityFe.isHavePermission(["web-manager.collection"])
        }, {
            label: "Blog",
            to: "/web-manage-blog",
            hide: () => !securityFe.isHavePermission(["web-manager.blog"])
        }, 
        {
            label: "Dịch vụ điện hoa",
            to: "/web-dich-vu-hoa",
            hide: () => !securityFe.isHavePermission(["web-manager.blog"])
        }, 
        
        {
            label: "Banner dịch vụ hoa",
            to: "/web-manage-banner-dich-vu-hoa",
            hide: () => !securityFe.isHavePermission(["web-manager.banner"])
        },
        {
            label: "Banner",
            to: "/web-manage-banner",
            hide: () => !securityFe.isHavePermission(["web-manager.banner"])
        },{
            label: "Duyệt Đơn Hàng",
            to: "/order-review",
            hide: () => !securityFe.isHavePermission(["web-manager.order-review"])
        },{
            label : "Tab tùy biến",
            to : "/tab-config",
            hide: () => !securityFe.isHavePermission(["web-manager.tab-config"])
        },{
            label : "Thiết lập Footer",
            to : "/footer-setting",
            hide: () => !securityFe.isHavePermission(["web-manager.footer-setting"])
        },{
            label : "Đổi quà",
            to : "/gifts-exchange",
            hide: () => !securityFe.isHavePermission(["web-manager.gifts-exchange"])
        },
        { 
            label: "Cấu hình",
            to: "/web-config",
            hide: () => user.role != "admin"
        },
        //     {
        //     label: "Tin Nhắn",
        //     to: "/message",
        //     hide: () => !security.isHavePermission(["web-manager.message"])
        // }
        ],
        hide: () => !permission[user.role].find(r => r.indexOf("web-manager") > -1)
    }, {
        label: "Quản Lý Hệ Thống",
        icon: <i className="fa fa-cog nav-icon"/>,
        hide: () => user.role != "admin",
        child: [{
            label: "Quản Lý Cơ Sở",
            to: "/manage-premises",
            hide: () => user.role != "admin"
        }, {
            label: "Quản Lý Tài Khoản",
            to: "/manage-user",
            hide: () => user.role != "admin"
        }, {
            label: "Quản Lý Nhà Cung Cấp",
            to: "/manage-supplier",
            hide: () => !securityFe.isHavePermission(["warehouse.manage-supplier"])
        }, {
            to: "/manage-role",
            label: "Phân Quyền",
            hide: () => user.role != "admin"
        }, {
            to: "/setting",
            label: "Cài Đặt Màu, Loại",
            hide: () => !securityFe.isHavePermission(["bill.editProductColor", "bill.editProductType"])
        }, {
            to: "/geo-dia-chi",
            label: "Định vị Phường Xã",
            hide: () =>  user.role != "admin"
        }
    
    ],
    }]
};
