import React from 'react';
import {WarningSetting} from "../setting/warning-setting";
import {FooterContent} from "../setting/footer-content";
import {Layout} from "../../components/layout/layout";
import {Input} from "../../components/input/input";
import {WYSIWYGEditor} from "../../components/wysiwyg-editor/wysiwyg-editor";
import {footerContentApi} from "../../api/footer-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";

export class BannerSettingRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            content: "",
            loading: true
        };

        footerContentApi.getBannerSetting().then(({title, description , footer }) => {
            this.setState({title, description, footer, loading: false})
        })
    }

    save(key) {
        footerContentApi.editBannerSetting({key, value: this.state[key]}).then(() => {
            confirmModal.alert("Lưu thành công!");
        })
    }

    render() {
        const { loading } = this.state;

        let bannerSetting =[
            {
                label: "Tiêu đề Banner",
                key: 'title'
            }, {
                label: "Nội dung Banner",
                key: 'description'
            }, {
                label: "Banner Footer",
                key: 'footer'
            },
        ];
        return (
            <Layout
                activeRoute="Banner footer, header"
            >

                {!loading &&
                    (
                        <div className="banner-setting-route web-manage-catalog-route">
                            {bannerSetting.map((b,i) =>(
                                <div key={i} className="card">
                                    <div className="card-title">
                                        {b.label}
                                    </div>

                                    <div className="card-body set-warning-text">
                                        <Input
                                            label={b.label}
                                            value={this.state[b.key]}
                                            onChange={(e) => this.setState({[b.key]: e.target.value})}
                                            textArea
                                        />
                                        <button className="btn btn-primary"
                                                onClick={() => this.save(b.key )}>
                                <span className="btn-text">
                                    Lưu
                                </span>
                                        </button>
                                    </div>

                                </div>
                            ))}
                        </div>
                    )
                }
            </Layout>
        )
    }
}