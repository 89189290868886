export const paymentTypes = ["Tất cả", "Ship", "Shop", "Thẻ", "Web", "Chuyển Khoản", "Paypal", "Nợ", "Free MKT", "Free BGĐ", "Free DVKH", "Free Wedding", "Free Company", "Free Mẫu"];
export const viaTypes = ["Đến Shop", "","Facebook / Instargram", "Facebook", "Instargram", "Website", "Voucher", "Khách hàng Doanh nghiệp", "Điện thoại (viber, zalo, imess, cố định)", "CC&C- BIDV", "Đối tác ( VINID, Now...)", "VNAMall"];

export const cities = 
[
    "Hà Nội", "Thành phố Hồ Chí Minh",
    "Tỉnh Hà Giang",
    "Tỉnh Cao Bằng",
    "Tỉnh Bắc Kạn",
    "Tỉnh Tuyên Quang",
    "Tỉnh Lào Cai",
    "Tỉnh Điện Biên",
    "Tỉnh Lai Châu",
    "Tỉnh Sơn La",
    "Tỉnh Yên Bái",
    "Tỉnh Hoà Bình",
    "Tỉnh Thái Nguyên",
    "Tỉnh Lạng Sơn",
    "Tỉnh Quảng Ninh",
    "Tỉnh Bắc Giang",
    "Tỉnh Phú Thọ",
    "Tỉnh Vĩnh Phúc",
    "Tỉnh Bắc Ninh",
    "Tỉnh Hải Dương",
    "Thành phố Hải Phòng",
    "Tỉnh Hưng Yên",
    "Tỉnh Thái Bình",
    "Tỉnh Hà Nam",
    "Tỉnh Nam Định",
    "Tỉnh Ninh Bình",
    "Tỉnh Thanh Hóa",
    "Tỉnh Nghệ An",
    "Tỉnh Hà Tĩnh",
    "Tỉnh Quảng Bình",
    "Tỉnh Quảng Trị",
    "Tỉnh Thừa Thiên Huế",
    "Thành phố Đà Nẵng",
    "Tỉnh Quảng Nam",
    "Tỉnh Quảng Ngãi",
    "Tỉnh Bình Định",
    "Tỉnh Phú Yên",
    "Tỉnh Khánh Hòa",
    "Tỉnh Ninh Thuận",
    "Tỉnh Bình Thuận",
    "Tỉnh Kon Tum",
    "Tỉnh Gia Lai",
    "Tỉnh Đắk Lắk",
    "Tỉnh Đắk Nông",
    "Tỉnh Lâm Đồng",
    "Tỉnh Bình Phước",
    "Tỉnh Tây Ninh",
    "Tỉnh Bình Dương",
    "Tỉnh Đồng Nai",
    "Tỉnh Bà Rịa - Vũng Tàu",
    "Tỉnh Long An",
    "Tỉnh Tiền Giang",
    "Tỉnh Bến Tre",
    "Tỉnh Trà Vinh",
    "Tỉnh Vĩnh Long",
    "Tỉnh Đồng Tháp",
    "Tỉnh An Giang",
    "Tỉnh Kiên Giang",
    "Thành phố Cần Thơ",
    "Tỉnh Hậu Giang",
    "Tỉnh Sóc Trăng",
    "Tỉnh Bạc Liêu",
    "Tỉnh Cà Mau"
];

export const listAgesRange = ["Nhóm 1: 18-24 tuổi", "Nhóm 2: 25-34 tuổi", "Nhóm 3: 35-44 tuổi", "Nhóm 4: 45-55 tuổi"];


export const roles = [{
    value: "admin",
    label: "Ban Giám Đốc"
}, {
    value: "mkt",
    label: "Marketing"
}, {
    value: "tch",
    label: "Trưởng Cửa Hàng"
}, {
    value: "dvkh",
    label: "Dịch Vụ Khách Hàng"
}, {
    value: "bpmh",
    label: "BP Mua Hàng"
}, {
    value: "sale",
    label: "Sale"
}, {
    value: "salemanager",
    label: "Sale Manager"
}, {
    value: "florist",
    label: "Florist"
}, {
    value: "ship",
    label: "Ship"
}, {
    value: "ns",
    label: "Nhân Sự"
}, {
    value: "ktt",
    label: "Kế Toán Trưởng"
}, {
    value: "kt",
    label: "Kế Toán"
}, {
    value: "nl",
    label: "Nhập Liệu"
}, {
    value: "khotong",
    label: "Kho Tổng"
}, {
    value: "web",
    label: "Quản lý Website"
}, {
    value: "guest",
    label: "Khách"
}];

export const permissions = [{
    value: "bill.create",
    father: "Hoá Đơn",
    label: "Tạo Đơn"
}, {
    value: "bill.create-e-wallet-bill",
    father: "Hoá Đơn",
    label: "Tạo Đơn E Wallet"
}, {
    value: "bill.edit",
    father: "Hoá Đơn",
    label: "Sửa Đơn"
}, {
    value: "bill.delete",
    label: "Xoá Đơn",
    father: "Hoá Đơn",
}, {
    value: "bill.remove-ship",
    father: "Hoá Đơn",
    label: "Xóa Ship"
}, {
    value: "bill.editProductType",
    father: "Hoá Đơn",
    label: "Chỉnh sửa loại sản phẩm"
}, {
    value: "bill.editProductColor",
    father: "Hoá Đơn",
    label: "Chỉnh sửa màu sản phẩm"
}, {
    value: "bill.editGiftType",
    father: "Hoá Đơn",
    label: "Chỉnh sửa dịp tặng hoa"
}
// ,{
//     value : "bill.editProductWaring",
//     father: "Hoá Đơn",
//     label: "Chỉnh sửa câu cảnh báo cho sản phẩm"
// }
    , {
        value: "bill.editDoneBill",
        father: "Hoá Đơn",
        label: "Chỉnh sửa hoá đơn Done"
    }, {
        value: "bill.editBillItem",
        father: "Hoá Đơn",
        label: "Chỉnh sửa giá đơn hàng"
    }, {
        value: "bill.rejectBill",
        father: "Hoá Đơn",
        label: "Hủy Đơn"
    }, {
        value: "bill.view",
        label: "Xem Đơn Hàng",
        father: "Hoá Đơn"
    }, {
        value: "bill.excel",
        label: "Xuất Excel",
        father: "Hoá Đơn"
    }, {
        value: "bill.edit-status",
        label: "Chỉnh sửa trạng thái đơn",
        father: "Hoá Đơn",
    }, {
        value: "report.report-revenue",
        label: "Báo cáo doanh thu",
        father: "Báo Cáo"
    }, {
        value: "report.report-bill",
        label: "Báo cáo đơn hàng",
        father: "Báo Cáo"
    }, {
        value: "report.report-customer",
        label: "Báo cáo khách hàng",
        father: "Báo Cáo"
    }, {
        value: "report.report-promotion",
        label: "Báo cáo chiến dịch khuyến mại",
        father: "Báo Cáo"
    }, {
        value: "report.report-supplier",
        label: "Báo cáo nhà cung cấp",
        father: "Báo Cáo"
    }, {
        value: "report.report-florist-working",
        label: "Báo cáo đơn Florist làm",
        father: "Báo Cáo"
    }, {
        value: "gallery",
        label: "Tải ảnh vào kho ảnh",
        father: "Kho Ảnh"
    }, {
        value: "customer.list",
        label: "Danh Sách Khách Hàng",
        father: "Khách Hàng"
    }, {
        value: "customer.update-vip-customer",
        label: "Cập Nhật Khách Hàng",
        father: "Khách Hàng"
    }, {
        value: "customer.change-phone",
        label: "Đổi số điện thoại",
        father: "Khách Hàng"
    }, {
        value: "customer.discount-codes",
        label: "Mã Giảm Giá",
        father: "Khách Hàng"
    }, {
        value: "customer.last-bill",
        label: "Xem đơn hàng gần nhất của khách",
        father: "Khách Hàng"
    }, {
        value: "customer.customer-not-buy",
        label: "Khách hàng chưa quay lại",
        father: "Khách Hàng"
    }, {
        value: "promotion.view",
        label: "Xem Chiến Dịch",
        father: "Khuyến Mại"
    }, {
        value: "promotion.create",
        label: "Thêm Chiến Dịch",
        father: "Khuyến Mại"
    }, {
        value: "promotion.edit",
        label: "Sửa Chiến Dịch",
        father: "Khuyến Mại"
    }, {
        value: "promotion.remove",
        label: "Xoá Chiến Dịch",
        father: "Khuyến Mại"
    }, {
        value: "warehouse.manage-supplier",
        label: "Quản Lý Nhà Cung Cấp",
        father: "Kho"
    }, {
        value: "warehouse.products.view",
        label: "Xem Danh Sách Sản Phẩm",
        father: "Kho"
    }, {
        value: "warehouse.products.update",
        label: "Chỉnh Sửa Danh Sách Sản Phẩm",
        father: "Kho"
    }, {
        value: "warehouse.request.update-request",
        label: "Sửa phiếu khi xác nhận",
        father: "Kho"
    }, {
        value: "warehouse.request.view-request-from-supplier",
        label: "Xem Phiếu Nhập Hàng",
        father: "Kho"
    }, {
        value: "warehouse.request.create-request-from-supplier",
        label: "Tạo Phiếu Nhập Hàng",
        father: "Kho"
    }, {
        value: "warehouse.request.update-request-from-supplier",
        label: "Duyệt Phiếu Nhập Hàng",
        father: "Kho"
    }, {
        value: "warehouse.request.view-return-to-supplier",
        label: "Xem Phiếu Trả Hàng",
        father: "Kho"
    }, {
        value: "warehouse.request.create-return-to-supplier",
        label: "Tạo Phiếu Trả Hàng",
        father: "Kho"
    }, {
        value: "warehouse.request.update-return-to-supplier",
        label: "Duyệt Phiếu Trả Hàng",
        father: "Kho"
    }, {
        value: "warehouse.request.view-transfer-to-subwarehouse",
        label: "Xem Phiếu Xuất Kho",
        father: "Kho"
    }, {
        value: "warehouse.request.create-transfer-to-subwarehouse",
        label: "Tạo Phiếu Xuất Kho",
        father: "Kho"
    }, {
        value: "warehouse.request.update-transfer-to-subwarehouse",
        label: "Duyệt Phiếu Xuất Kho",
        father: "Kho"
    }, {
        value: "warehouse.request.view-return-to-base",
        label: "Xem Phiếu Trả Kho",
        father: "Kho"
    }, {
        value: "warehouse.request.create-return-to-base",
        label: "Tạo Phiếu Trả Kho",
        father: "Kho"
    }, {
        value: "warehouse.request.update-return-to-base",
        label: "Duyệt Phiếu Trả Kho",
        father: "Kho"
    }, {
        value: "warehouse.request.view-report-flower",
        label: "Xem Phiếu Hao Hụt / Hủy Hỏng",
        father: "Kho"
    }, {
        value: "warehouse.request.create-report-flower",
        label: "Tạo Phiếu Hao Hụt / Hủy Hỏng",
        father: "Kho"
    }, {
        value: "warehouse.request.update-report-flower",
        label: "Duyệt Phiếu Hao Hụt / Hủy Hỏng",
        father: "Kho"
    }, {
        value: "warehouse.request.view-order-flower",
        label: "Xem Phiếu Order Hoa",
        father: "Kho"
    }, {
        value: "warehouse.request.create-order-flower",
        label: "Tạo Phiếu Order Hoa",
        father: "Kho"
    }, {
        value: "warehouse.request.update-order-flower",
        label: "Duyệt Phiếu Order Hoa",
        father: "Kho"
    }, {
        value: "warehouse.view-ori-price",
        label: "Xem giá gốc",
        father: "Kho"
    }, {
        value: "warehouse.update-price",
        label: "Cập nhật giá tồn kho",
        father: "Kho"
    }, {
        value: "warehouse.update-combo",
        label: "Chỉnh sửa combo",
        father: "Kho"
    }, {
        value: "web-manager.product",
        label: "Quản lý sản phẩm",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.collection",
        label: "Quản lý bộ sưu tập",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.blog",
        label: "Quản lý Blog",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.banner",
        label: "Quản lý Banner",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.order-review",
        label: "Xác nhận đơn hàng",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.tab-config",
        label: "Tab tùy biến",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.footer-setting",
        label: "Thiết lập Footer",
        father: "Quản trị WEB"
    }, {
        value: "web-manager.gifts-exchange",
        label: "Đổi quà",
        father: "Quản trị WEB"
    },
    {
        value: "web-manager.web-product-type",
        label: "Danh mục sản phẩm",
        father: "Quản trị WEB"
    },
//     {
//     value: "web-manager.message",
//     label: "Quản lý tin nhắn",
//     father: "Quản trị WEB"
// },
    {
        value: "memories",
        label: "Xem Đơn",
        father: "Ngày Này Năm Xưa"
    }];

export const catalogs = ["Hoa chính","Hoa lụa", "Hoa lá phụ/Lá", "Phụ kiện", "Cost", "Quà tặng", "Quả", "Chùm", "Hộp quả"];
