import {api} from "./api";

export const blogApi = {
    getBlogList: (data) => {
        return api.post("/api/blog-list", data);
    },
    getBlog: (id) => {
        return api.get(`/api/blog/${id}`);
    },
    updateBlog: (id, data) => {
        return api.put(`/api/blog/${id}`, data)
    },
    removeBlog: (id) => {
        return api.delete(`/api/blog/${id}`)
    },
    createBlog: (data) => {
        return api.post(`/api/blog`, data)
    },
    getBlogCatalogs: () => {
        return api.get("/api/blog-catalog")
    },
    getBlogByKeyword: (keyword) => {
        return api.post("/api/find-blog-by-keyword", {keyword})
    }

};
