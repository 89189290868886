import React, {Fragment} from "react";
import concat from "lodash/concat";
import sortBy from "lodash/sortBy";
import {formatNumber, getRevenueEmployee, getSalary, getTotalBill} from "../../../common/common";
import sumBy from "lodash/sumBy";
import moment from "moment";
import {premisesInfo} from "../../../security/premises-info";
import {modals} from "../../../components/modal/modals";
import {CSVLink} from "react-csv";
import {securityApi} from "../../../api/security-api";
import {DataTable} from "../../../components/data-table/data-table";
import {roles} from "../../../common/constance";
export class ReportEmployee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sales: [],
            florists: [],
            ships: []
        };

        securityApi.getSalesAndFlorist().then((users) => {
            const mapItem = (u) => ({
                name: u.name,
                username: u.username,
                role: u.role,
                _id: u._id,
                salaryFormula: u.salaryFormula
            });

            this.setState({
                sales: users.filter(u => u.role == "sale").map(mapItem),
                florists: users.filter(u => u.role == "florist").map(mapItem),
                ships: users.filter(u => u.role == "ship").map(mapItem)
            })
        })
    }

    render() {

        let {bills, loading, lastInitBills, filterType} = this.props;
        let {sales, florists, ships} = this.state;

        let employees = concat(sales, florists, ships);

        employees = employees.map((e) => {

            let getTotalBills = (bills) => bills.filter(b => {
                if (b.sales.find(u => u.username == e.username)) return true;
                if (b.florists.find(u => u.username == e.username)) return true;
                return !!b.ships.find(u => u.username == e.username);
            });

            let getTotalGet = (bills) => sumBy(bills, b => {
                if (b.sales.find(u => u.username == e.username)) {
                    return getSalary(e, b).money
                }


                if (b.florists.find(u => u.username == e.username)) {
                    return getSalary(e, b).money
                }

                if (b.ships.find(u => u.username == e.username)) {
                    return getSalary(e, b).money
                }

                return 0;
            });

            let totalBills = getTotalBills(bills);

            return {
                ...e,
                totalBills: totalBills.length,
                totalGet: getTotalGet(bills),
                totalRevenue: sumBy(bills, b => getRevenueEmployee(b, e)),
            }
        });

        let columns = [{
            label: "Tên",
            display: (employee) => employee.username,
            width: "20%",
            minWidth: "150",
            sortBy: (employee) => employee.username
        }, {
            label: "Chức Vụ",
            display: (row) => roles.find(r => r.value == row.role).label,
            sortBy: (row) => roles.find(r => r.value == row.role).label,
            width: "20%",
            minWidth: "150",
        }, {
            label: "Lương Doanh Thu",
            display: (employee) => formatNumber(employee.totalGet),
            width: "20%",
            minWidth: "150",
            sortBy: (employee) => employee.totalGet
        }, {
            label: "Tổng Đơn",
            display: (employee) => employee.totalBills,
            width: "20%",
            minWidth: "150",
            sortBy: (employee) => employee.totalBills
        }, {
            label: "Doanh Thu",
            display: (employee) => formatNumber(employee.totalRevenue),
            width: "20%",
            minWidth: "150",
            sortBy: (employee) => employee.totalRevenue
        }];

        let CSVdata = [[
            "Tên",
            "Chức Vụ",
            "Lương Doanh Thu",
            "Tổng Đơn",
            "Doanh Thu"
        ]];

        for (let employee of employees) {
            CSVdata.push([
                employee.username,
                roles.find(r => r.value == employee.role).label,
                employee.totalGet,
                employee.totalBills,
                employee.totalRevenue,
            ])
        }


        return (
            <div>

                <div style={{
                    marginLeft: "10px",
                    marginBottom: "10px"
                }}>
                    <CSVLink
                        data={CSVdata}
                        filename={"bao-cao-luong.csv"}
                        className="btn btn-primary btn-small">
                        <span className="btn-text">Xuất Excel</span>
                        <span className="loading-icon"><i className="fa fa-file-excel-o"/></span>
                    </CSVLink>
                </div>

                <DataTable
                    loading={loading}
                    columns={columns}
                    rows={employees}
                />
            </div>
        );
    }
}


