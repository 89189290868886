import React, {Fragment} from "react";
import {DatePicker} from "../../../components/date-picker/date-picker";
import {defaultReportDayService} from "../../app-router";
import {Select} from "../../../components/select/select";
import {Layout} from "../../../components/layout/layout";
import {billApi} from "../../../api/bill-api";
import sortBy from "lodash/sortBy";
import {DataTable} from "../../../components/data-table/data-table";
import {ColumnViewMore} from "../../../components/column-view-more/column-view-more";
import moment from "moment";
import {formatNumber, getSubTotalBill, getTotalBill} from "../../../common/common";
import {premisesAllInfo} from "../../../security/premises-info";
import {productApi} from "../../../api/product-api";

export class ReportFloristWorkingRoute extends React.Component {

    constructor(props) {
        super(props);
        let {from, to} = defaultReportDayService.get();

        this.state = {
            from,
            to,
            loading: true,
            bills: [],
            premisesID: "all"
        };
    }

    componentDidMount() {
        this.getReport();
    }

    getReport() {
        this.setState({loading: true});
        let {from, to} = this.state;
        billApi.getReportAllFlowers({from, to}).then(({bills, products}) => {
            this.setState({bills: bills.filter(b => b.selectedFlower.length > 0, products), loading: false, products})
        })
    }

    render() {

        let {loading, from, to, bills, viewType, products} = this.state;

        let columns = [{
            label: "Thời Gian",
            display: (bill) => (
                <div>
                    {moment(bill.deliverTime).format("DD/MM/YYYY HH:mm")}
                    <br/>
                    {bill.bill_number}
                </div>
            ),
            width: "25%",
            minWidth: "150",
            sortBy: (row) => row.name
        }, {
            label: "Cơ Sở",
            display: (row) => premisesAllInfo.getPremises().find(p => p._id == row.premises_id).name,
            width: "25%",
            minWidth: "50",
            sortBy: (row) => row.total
        }, {
            label: "Thông Tin Đơn",
            display: (bill) => (
                <div>
                    {bill.items.map((item, index) => (
                        <div key={index}>
                            <b>{item.quantity}</b> {item.flowerType} {item.name} {item.sale &&
                        <span className="text-primary">({item.sale}%)</span>} {item.vat ?
                            <span className="text-primary"> - {item.vat}% VAT</span> : ""}
                            {item.color && (
                                <div className="text-small">Màu: {item.color.split(", ").map((c, i) => (
                                    <div key={i}
                                         style={{
                                             background: c,
                                             display: "inline-block",
                                             marginRight: "5px",
                                             width: "15px",
                                             height: "10px"
                                         }}
                                    />
                                ))}</div>)}
                            {item.size && (<div className="text-small">Size: <b>{item.size}</b></div>)}
                        </div>
                    ))}

                    <div>Florist: <b>{bill.florists.map(f => f.username).join(", ")}</b></div>
                    <div>Tổng tiền: <b>{getSubTotalBill(bill)}</b></div>
                </div>
            ),
            width: "25%",
            minWidth: "300"
        }, {
            label: "Nguyên Liệu",
            display: (bill) => {

                let getProduct = (id) => products.find(p => p._id == id);

                return bill.selectedFlower.map((item, index) => (
                    <div key={index}>
                        <b className="text-primary">{item.quantity}</b> - <span>{getProduct(item.parentID).name}</span> - {formatNumber(item.price)}
                    </div>
                ))
            },
            width: "25%",
            minWidth: "300"
        }];


        return (
            <Layout activeRoute="Đơn Florist Làm">

                <div className="card bill-report-route">
                    <div className="card-title">
                        Báo cáo đơn Florist làm
                        <span className="text-small text-primary">{bills ? bills.length : 0} Đơn</span>

                    </div>

                    <div className="card-body">
                        <div className="row first-margin">
                            <DatePicker
                                className="col"
                                label="Từ Ngày"
                                value={from}
                                onChange={(from) => {
                                    this.setState({from});
                                    defaultReportDayService.set({...defaultReportDayService.get(), from});
                                }}
                            />

                            <DatePicker
                                className="col"
                                label="Tới Ngày"
                                value={to}
                                onChange={(to) => {
                                    this.setState({to});
                                    defaultReportDayService.set({...defaultReportDayService.get(), to});
                                }}
                            />

                            <button className="btn btn-primary"
                                    onClick={() => this.getReport()}
                                    disabled={loading}
                            >
                                <span className="btn-text">Xem</span>
                                {loading &&
                                <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                            </button>
                        </div>
                    </div>

                    <DataTable
                        loading={loading}
                        columns={columns}
                        rows={bills}
                    />

                </div>
            </Layout>
        );
    }
}
