import React from "react";
export class InputUploadItem extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let {value, onRemove, onMoveLeft, onMoveRight} = this.props;

        return (
            <div className="input-upload-item picture-upload"
            >

                <input className="input-upload"
                       ref={elem => this.inputUpload = elem}
                       type="file"
                       accept="image/png, image/jpeg"
                       onChange={(e) => this.handleChange(e)}
                />



                <div className="image-wrapper"
                     style={{
                         backgroundImage: value ? `url(${value})`: null,
                         backgroundPosition: "center",
                         backgroundRepeat: "no-repeat",
                         backgroundSize: "cover"
                     }}
                >
                    <button className="btn btn-small btn-danger" type="button"
                            onClick={() => onRemove()}
                    >
                        <i className="fa fa-trash" aria-hidden="true"/>
                    </button>
                    <span onClick={ () => onMoveLeft && onMoveLeft()} className="arrow __left">  <i className="fa fa-arrow-left" aria-hidden="true"/> </span>
                    <span onClick={ () => onMoveRight && onMoveRight()} className="arrow __right">  <i className="fa fa-arrow-right" aria-hidden="true"/> </span>
                </div>

            </div>
        );
    }
}
