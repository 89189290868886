import React from "react";
import {Select} from "../../components/select/select";
import {premisesInfo} from "../../security/premises-info";
import {TimePicker} from "../../components/time-picker/time-picker";

export class OrderReviewAcceptedModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            premisesID:  (props.bill.premises_id || premisesInfo.getActivePremise()._id),
            deliverTime: new Date(props.bill.deliverTime)
        }
    }

    render() {

        let {onDismiss, onClose, bill} = this.props;
        let {premisesID, saving, deliverTime} = this.state;
        const premises = premisesInfo.getPremises();
       
        return (
            <div className="app-modal-box order-review-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Lựa chọn cơ sở</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Select
                            value={premisesID}
                            onChange={(premisesID) => this.setState({premisesID: premisesID})}
                            list={premises.filter(p => p.isActive).map(r => r._id)}
                            displayAs={(role) => premises.find(r => r._id == role)?.name}
                            label="Cơ sở"
                        />

                        <div className="control-label">Khoảng giờ khách nhận: <b>{bill.deliverTimeText}</b></div>

                        <div className="control-label">
                            Giờ nhận hàng
                        </div>

                        <TimePicker
                            value={deliverTime}
                            onChange={(deliverTime) => this.setState({deliverTime})}
                        />


                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                        <button type="submit" className="btn btn-primary"
                            onClick={() => onClose(premisesID, deliverTime)}
                        >
                            <span className="btn-text">Xac nhận</span>
                            { saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                        </button>
                    </div>


                </div>
            </div>
        );
    }
}
