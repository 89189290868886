import {api} from "./api";

export const SetupHomeApi = {
    getSetupHome: () => {
        return api.post(`/api/setup-home`)
    },
   
    updateSetupHome: (id, data) => {
        return api.put(`/api/setup-home/${id}`, data)
    }
};