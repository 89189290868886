import {api} from "./api";

export const bannerApi = {
    getBanners: () => {
        return api.get("/api/banners");
    },
    createBanner: (data) => {
        return api.post("/api/banner", data)
    },
    updateBulkBanners: (data) => {
        return api.put("/api/banner-update-bulk", data)
    },
    updateBanner: (id, data) => {
        return api.put(`/api/banner/${id}`, data)
    },
    removeBanner: (id) => {
        return api.delete(`/api/banner/${id}`)
    }
};
