import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {MessageList} from "./message-list";
import {MessageDetail} from "./message-detail";
import {responsive} from "../../common/responsive/responsive";
import {ComponentSlider} from "../../components/component-slider/component-slider";
export class MessageRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            conversations: null
        };

        //firebase

        // let messagesRef = firebase.database().ref(`conversations`);
        // messagesRef.on('value', (snapshot) => {
        //     let conversations = [];
        //     for (let key in snapshot.val()) {
        //         conversations.push({
        //             conversationID: key,
        //             ...snapshot.val()[key]
        //         })
        //     }

        //     const isMobile = responsive.le("xs");
        //     if (isMobile) {
        //         this.setState({conversations})
        //     } else {
        //         this.setState({conversations, selectedItem: conversations[0]})
        //     }
        // })
    }

    componentWillUnmount() {
        //firebase
       // firebase.database().ref(`conversations`).off('value');
    }

    render() {

        const isMobile = responsive.le("xs");
        let {selectedItem, conversations} = this.state;

        if (conversations && conversations.length == 0) {
            return (
                <Layout
                    activeRoute="Tin Nhắn"
                >
                    <div className="card">
                        <div className="card-title">
                            Không có tin nhắn nào cả.
                        </div>
                    </div>
                </Layout>
            )
        }

        return (
            <Layout
                activeRoute="Tin Nhắn"
            >
                <div className="message-route card">

                    {isMobile ? (
                        <ComponentSlider
                            selectedIndex={selectedItem ? 1 : 0}
                            getComponent={() => {
                                if (selectedItem) {
                                    return (
                                        <MessageDetail
                                            onGoBack={() => this.setState({selectedItem: null})}
                                            selectedConversation={selectedItem}
                                        />
                                    )
                                }

                                return <MessageList
                                    conversations={conversations}
                                    onSelect={(selectedItem) => this.setState({selectedItem})}
                                    selectedConversation={selectedItem}
                                />
                            }}
                        />
                    ) : (
                        <Fragment>
                            <MessageList
                                conversations={conversations}
                                onSelect={(selectedItem) => this.setState({selectedItem})}
                                selectedConversation={selectedItem}
                            />
                            <MessageDetail
                                onGoBack={() => this.setState({selectedItem: null})}
                                selectedConversation={selectedItem}
                            />
                        </Fragment>
                    ) }
                </div>
            </Layout>
        );
    }
}
