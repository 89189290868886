import React from "react";
import {formatNumber, formatValue, getTotalBill} from "../../../common/common";
import {eWalletValue} from "../../../../constance/constance"
import {Select} from "../../../components/select/select";
import {BillInfo} from "../bill-info/bill-info";
import {BillCustomer} from "../customer/bill-customer";
import {paymentTypes, viaTypes} from "../../../common/constance";
import {Input} from "../../../components/input/input";
import {premisesInfo} from "../../../security/premises-info";
import {cache} from "../../../common/cache";
import {customerApi} from "../../../api/customer-api";
import omit from "lodash/omit";
import {billApi} from "../../../api/bill-api";
import {userInfo} from "../../../security/user-info";
import {confirmModal} from "../../../components/confirm-modal/confirm-modal";
import {sumBy} from "lodash";
import {discountCodeApi} from "../../../api/discount-code-api";
import {PrintService} from "../../../common/print-service/print-service";
import {BillPrint} from "../print/bill-print";
import {BillEWalletPrint} from "../print/bill-e-wallet-print";
import {SpecialDays} from "./special-days";

const initBill = {
    items: [{
        name: `Thẻ ${formatNumber(eWalletValue[0].cost)} được ${formatNumber(eWalletValue[0].earn)}`,
        price: eWalletValue[0].cost,
        quantity: 1
    }],
    customer: {
        customerName: "",
        customerPhone: "",
        customerPlace: ""
    },
    customerInfo: null,
    is_e_wallet: true,
    to: {}
};

export class EWalletBill extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bill: initBill,
            saving: false
        }
    }


    submitBill = async () => {
        this.setState({saving: true});
        let premises = premisesInfo.getPremises();

        const getCurrentPremise = () => {
            const activeID = cache.get("active-premises");
            if (!activeID) {
                cache.set(premises[0]._id, "active-premises");
                return premises[0]._id;
            }
            const found = premises.find(p => p._id == activeID);
            if (found) return found._id;
            else {
                cache.set(premises[0]._id, "active-premises");
                return premises[0]._id
            }
        };

        let {bill} = this.state;

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const endDay = new Date();
        endDay.setHours(23, 59, 59, 99);

        const getCustomerID = async () => {
            if (!bill.customer._id) {
                const customer = await customerApi.createCustomer(bill.customer);
                return customer._id
            }
            await customerApi.updateCustomer(bill.customer._id, omit(bill.customer, "_id"));
            return bill.customer._id
        };



        const customerID = await getCustomerID();
        await this.specialDaysRef.submit(customerID);

        const bills = await billApi.getBillNumbers({from: today, to: endDay});
        const _b = await billApi.createBill({
            ...omit(bill, "customerInfo"),
            bill_number: `${formatValue(today.getDate())}${formatValue(today.getMonth() + 1)}${today.getFullYear().toString().substring(2)}${formatValue((bills.length || 0) + 1)}`,
            customerId: customerID,
            premises_id: getCurrentPremise(),
            created_by: userInfo.getUser().username,
            created: new Date(),
            isNewCustomer: !bill.customer._id,
            deliverTime: new Date().getTime(),
            status: "Done"
        })


        if (_b.error) {
            confirmModal.alert(_b.error);
            this.setState({
                bill: initBill
            })
        } else {

            this.setState({
                bill: initBill,
                saving: false
            });

            PrintService.printBill({
                body: (
                    <BillEWalletPrint
                        bill={{
                            ...bill,
                            bill_number: `${formatValue(today.getDate())}${formatValue(today.getMonth() + 1)}${today.getFullYear().toString().substring(2)}${formatValue((bills.length || 0) + 1)}`,
                            isOwe: bill.to.paymentType == "Nợ",
                            created_by: userInfo.getUser().username,
                            codes: _b.codes
                        }}
                    />
                )
            });
        }


    }

    render() {

        let {bill, saving} = this.state;
        return (
            <>
                <div className="card">
                    <div className="card-title">
                        Thông tin hóa đơn
                    </div>

                    <div className="card-body">
                        <Select
                            label="Loại thẻ"
                            value={bill.items[0]}
                            list={eWalletValue.map((v) => ({
                                name: `Thẻ ${formatNumber(v.cost)} được ${formatNumber(v.earn)}`,
                                price: v.cost,
                                quantity: 1
                            }))}
                            onChange={(v) => this.setState({bill: {...bill, items: [v]}})}
                            displayAs={(list) => list.name}
                        />

                        <div className="sub-total"
                             style={{
                                 textAlign: "right"
                             }}
                        >
                            Thành tiền: <b>{formatNumber(getTotalBill(bill))}</b>
                        </div>
                    </div>
                </div>

                <BillCustomer
                    ref={elem => this.billCustomer = elem}
                    bill={bill}
                    onChangeBill={(bill) => this.setState({bill})}
                    onChangeLocations={(locations) => this.setState({locations})}
                    customer={bill.customer}
                    onChange={(customer) => {
                        this.setState({bill: {...bill, customer}})
                    }}
                    infoComponent={() => (
                        <div className="row">

                            <Select
                                label="Kênh Mua Hàng*"
                                value={bill.to.buyerFrom}
                                onChange={(buyerFrom) => this.setState({bill: {...bill, to: {...bill.to, buyerFrom}}})}
                                list={viaTypes}
                                className="col"
                            />

                            <div className="col">
                                <Select
                                    label="Hình Thức Thanh Toán*"
                                    onChange={(paymentType) => this.setState({bill: {...bill, to: {...bill.to, paymentType}}})}
                                    list={paymentTypes.slice(1, 6)}
                                    value={bill.to.paymentType}
                                />

                                { bill.to.paymentType == "Thẻ" && (
                                    <Input
                                        label="MCC"
                                        value={bill.to.mcc}
                                        onChange={(e) => this.setState({bill: {...bill, to: {...bill.to, mcc: e.target.value}}})}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                />

                <SpecialDays
                    bill={bill}
                    onChange={(bill) => this.setState({bill})}
                    ref={elem => this.specialDaysRef = elem}
                />

                <div className="text-right btn-action">
                    <button type="button"
                            disabled={
                                 bill.items.length == 0 ||
                                 saving ||
                                 bill.customer.customerName.length == 0 ||
                                !bill.customer.gender||
                                !bill.customer.city ||
                                !bill.customer.ageRanges ||
                                 bill.customer.customerPhone.length == 0 ||
                                !bill.to.buyerFrom ||
                                 !bill.to.paymentType
                            }
                            className="btn btn-primary "
                            onClick={() => this.submitBill(bill)}>
                        <span className="btn-text">Bán Hàng</span>
                        {saving && <span className="loading-icon"><i
                            className="fa fa-spinner fa-pulse"/></span>}
                    </button>
                </div>
            </>
        );
    }
}
