import React, {Fragment} from "react";
import {Form} from "../../../components/form/form";
import {InputGroup} from "../../../components/input/input-group";
import {Input} from "../../../components/input/input";
import {discountCodeApi} from "../../../api/discount-code-api";
export class BillDiscountModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            code: ""
        }
    }

    handleFind() {

        let {customerID, onClose} = this.props;
        let {code} = this.state;

        discountCodeApi.searchDiscountCode(code).then((discount) => {
            if (discount) {
                if (discount.error) {
                    this.setState({error: "Code đã sử dụng", saving: false})
                } else {
                    onClose(discount);
                }
            } else this.setState({error: "Không tìm thấy code", saving: false});
        })
    }

    render() {

        let {onDismiss} = this.props;
        let {code, error, saving} = this.state;

        return (
            <div className="app-modal-box">
                <div className="modal-header">
                    <h5 className="modal-title">Nhập mã giảm giá</h5>
                    <button type="button" className="close" onClick={() => onDismiss()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>


                <Form
                    onSubmit={() => {
                        this.setState({saving: true});
                        this.handleFind()
                    }}
                    formValue={this.state}
                    validations={[]}
                    render={() => (
                        <Fragment>
                            <div className="modal-body">
                                <Input
                                    label="Mã giảm giá"
                                    ref={elem => this.input = elem}
                                    value={code}
                                    onChange={(e) => this.setState({code: e.target.value.toUpperCase().replace(/[^A-Za-z0-9]/g, ""), error: false})}
                                    error={error ? error : ""}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                        className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                                <button type="submit" className="btn btn-primary">
                                    <span className="btn-text">Tìm</span>
                                    { saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                                </button>
                            </div>
                        </Fragment>
                    )}
                />


            </div>
        );
    }
}
