import {api} from "./api";
import {Customers} from "../router/customers/customers";

export const customerApi = {
    getCustomers: (data) => {
        return api.post("/api/customers", data);
    },
    getCustomer: (id) => {
        return api.get(`/api/customer/${id}`)
    },
    getCustomerByPhone: (phone) => {
        return api.get(`/api/find-customers-by-phone/${phone}`)
    },
    createCustomer: (customer) => {
        return api.post(`/api/customer`, customer)
    },
    updateCustomer: (id, customer) => {
        return api.put(`/api/customer/${id}`, customer)
    },
    getCustomerBirthDate: () => {
        return api.get(`/api/customers-birthday`);
    },
    getCustomersAll: () => {
        return api.get("/api/get-customers")
    },
    updateCustomerPay: (customerID) => {
        return api.put(`/api/update-customer-pay/${customerID}`)
    },
    getLastBillCustomer: (body) => {
        return api.post(`/api/get-last-bill-customer`, body)
    },
    updateCustomerPhone: (resp) => {
        return api.post("/api/change-customer-phone", resp)
    },
    excelCustomers: () => {
        return api.get("/api/excel-customers");
    },
    getCustomerNotBuy: (data) => {
        return api.post(`/api/last-customer-not-buy`, data)
    },
    getCustomerSpecialDays: (customerID) => {
        return api.get(`/api/customer-special-days/${customerID}`)
    },
    modifyCustomerSpecialDays: (customerID, data) => {
        return api.post(`/api/customer-special-days/${customerID}`, data)
    },
    getRemindCustomerSpecialDay: (data) => {
        return api.post(`/api/remind-customer-special-day`, data)
    }
};
