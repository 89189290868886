import {api} from "./api";

export const webManageProductApi = {
    getProducts: (data) => {
        return api.post("/api/web-products", data)
    },
    updateProduct: (id, data) => {
        return api.put(`/api/web-product/${id}`, data)
    },
    createProduct: (data) => {
        return api.post(`/api/web-product`, data);
    },
    removeProduct: (id) => {
        return api.delete(`/api/web-product/${id}`)
    },
    initUniqueCode :(acronym) =>{
        return api.get(`/api/web-product/init-unique-code/${acronym}`)
    },
    getProductByKeyword: (keyword) => {
        return api.post("/api/find-web-product-by-keyword", {keyword})
    }

};
