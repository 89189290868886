import React from "react";
import {Layout} from "../../components/layout/layout";
import {securityFe} from "../../security/security-fe";
import {DataTable} from "../../components/data-table/data-table";
import {modals} from "../../components/modal/modals";
import {ManagePromotionModal} from "../promotion/manage-promotion-modal";
import {promotionApi} from "../../api/promotion-api";
import {DiscountCodesModal} from "./discount-codes-modal";
import {discountCodeApi} from "../../api/discount-code-api";
import moment from "../promotion/promotion-route";
import {ButtonGroup} from "../../components/button-group/button-group";
import {formatNumber} from "../../common/common";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
export class DiscountCodesRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            discounts: null
        };

        discountCodeApi.getDiscountCodes().then(({discounts, customers}) => {
            this.setState({discounts: discounts.map((d => ({
                    ...d,
                    customer: customers.find(c => c._id == d.customerID)
            })))})
        });

    }

    addItem() {
        const modal = modals.openModal({
            content: (
                <DiscountCodesModal
                    discount={{
                        codeType: "number",
                        discountNumber: 0
                    }}
                    customer={{}}
                    onClose={(discount, customer) => {
                        return discountCodeApi.createDiscountCode(discount).then((resp) => {
                            if (resp.error) {
                                return Promise.resolve();
                            } else {
                                this.setState({discounts: this.state.discounts.concat({...resp, customer})});
                                modal.close();
                            }
                        })

                    }}
                    onDismiss={() => modal.close()}
                />
            )
        })
    }

    edit(discount) {
        const modal = modals.openModal({
            content: (
                <DiscountCodesModal
                    discount={discount}
                    customer={discount.customer}
                    onClose={(discount) => {
                        return discountCodeApi.updateDiscountCode(discount._id, discount).then((resp) => {
                            if (resp.error) {
                                return Promise.resolve();
                            } else {
                                this.setState({discounts: this.state.discounts.map((d => d._id == discount._id ? discount : d))});
                                modal.close();
                            }
                        })

                    }}
                    onDismiss={() => modal.close()}
                />
            )
        })
    }

    remove(item) {
        let {discounts} = this.state;

        confirmModal.show({
            title: `Xoá mã ${item.code}?`,
            description: "Bạn có đồng ý xoá mã này không?"
        }).then(() => {
            this.setState({
                discounts: discounts.filter(p => p._id != item._id)
            });
            discountCodeApi.removeDiscountCode(item._id);
        })
    }



    render() {

        let {discounts} = this.state;


        let columns = [{
            label: "Mã",
            width: "20%",
            display: (item) => (
                <div>
                    <div>{item.code}</div>
                    { item.billID && <div className="text-danger">Đã sử dụng</div>}
                </div>
            ),
            sortKey: "code",
            minWidth: "100"
        }, {
            label: "Khách Hàng",
            width: "40%",
            display: (row) => (
                <div className="item">
                    <div>{row.customer.customerName}</div>
                    <div>{row.customer.customerPhone}</div>
                </div>
            ),
            minWidth: "150"
        }, {
            label: "Giảm Giá",
            width: "15%",
            display: (row) => `${formatNumber(row.discountNumber)}${row.codeType == "percent" ? "%" : ""}`,
            minWidth: "100"
        }, {
            label: "Lý Do",
            width: "20%",
            display: (row) => row.reason,
            minWidth: "100"
        }, {
            label: "",
            width: "5%",
            display: (row) => !row.billID && (
                <ButtonGroup
                    actions={[{
                        name: "Sửa",
                        icon: <i className="fa fa-pencil"/>,
                        click: () => this.edit(row),
                    }, {
                        name: "Xóa",
                        icon: <i className="fa fa-trash text-danger"/>,
                        click: () => this.remove(row),
                    }]}
                />
            ),
            minWidth: "50"
        }];


        return (
            <Layout
                activeRoute="Mã Giảm Giá"
            >
                <div className="card discount-code-route">
                    <div className="card-title">Mã Giảm Giá</div>

                    <div className="card-body">
                        <button type="button" className="btn btn-primary" onClick={() => this.addItem()}>
                            Tạo Mã
                        </button>
                    </div>

                    <DataTable
                        rows={discounts}
                        columns={columns}
                    />
                </div>
            </Layout>

        );
    }
}
