import React from "react";
import {customerApi} from "../../../api/customer-api";
import {DatePicker} from "../../../components/date-picker/date-picker";
import {Input} from "../../../components/input/input";
import {DataTable} from "../../../components/data-table/data-table";

export class SpecialDays extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            special_days: [],
            originData: []
        }
    }

    initCustomerSpecial = async (bill) => {
        if (!bill.customer._id) {
            this.setState({special_days: [], originData: []})
        } else {
            const days = await customerApi.getCustomerSpecialDays(bill.customer._id);
            this.setState({special_days: days, originData: days})
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.bill.customer?._id != this.props.bill.customer?._id) {
            this.initCustomerSpecial(props.bill)
        }
    }

    submit = async (customerID) => {
        let added = [];
        let updated = [];
        let removed = [];
        let {special_days, originData} = this.state;

        for (let data of special_days) {
            if (!data._id) added.push(data);
            else updated.push(data);
        }

        for (let item of originData) {
            let found = special_days.find(s => s._id == item._id);
            if (!found) removed.push(item);
        }

        await customerApi.modifyCustomerSpecialDays(customerID, {added, updated, removed})

    }

    render() {
        let {special_days} = this.state;

        const columns = [{
            label: "Ngày",
            display: (row, index) => (
                <DatePicker
                    className="col"
                    label="Ngày đặc biệt"
                    value={new Date(new Date().getFullYear(), row.month - 1, row.day)}
                    onChange={(date) => {
                        this.setState({special_days: special_days.map((_, i) => i == index ? ({
                                ..._,
                                month: date.getMonth() + 1,
                                day: date.getDate()
                        }) : _)})
                    }}
                    noYearDisplay
                />
            ),
            width: "40%"
        }, {
            label: "Note",
            display: (row, index) => (
                <Input
                    value={row.notes}
                    onChange={(e) => {
                        this.setState({special_days: special_days.map((_, i) => i == index ? ({
                            ..._,
                            notes: e.target.value
                        }) : _)})
                    }}
                />
            ),
            width: "50%"
        }, {
            label: "",
            display: (row, index) => (
                <button
                    className="btn btn-danger btn-small" onClick={() => {
                        this.setState({special_days: special_days.filter((_, i) => index != i)})
                }}>
                    <i className="fa fa-trash "/>
                </button>
            ),
            width: "10%"
        }]

        return (
            <div className="card">
                <div className="card-title">
                    Ngày đặc biệt
                </div>

                <div className="card-body">
                    <button className="btn btn-primary" onClick={() => this.setState({
                        special_days: this.state.special_days.concat({day: new Date().getDay(), month: new Date().getMonth() + 1, notes: ""})
                    })}>
                        Thêm ngày
                    </button>
                </div>

                <DataTable
                    columns={columns}
                    rows={special_days}
                />
            </div>
        );
    }
}
