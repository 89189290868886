import React, {Fragment} from 'react';
import {Layout} from "../../components/layout/layout";
import {Input} from "../../components/input/input";
import {tabConfigApi} from "../../api/tab-config-api";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {Form} from "../../components/form/form";
import {required} from "../../components/form/validations";
import {Checkbox} from "../../components/checkbox/checkbox";
import {collectionApi} from "../../api/collection-api";
import {blogApi} from "../../api/blog-api";
import {Select} from "../../components/select/select";
import {AutoComplete} from "../../components/auto-complete/auto-complete";

export class TabConfigRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            hyper_link: '',
            loading: true,
            linkType: "",
            linkKeyword: "",
            linkID: null
        };

        tabConfigApi.getTabConfig().then(({name, hyper_link, linkID, linkType, disabled}) => {
            if (linkType && linkType !== "Hyper link") {
                let detailApi = this.options.find(o => o.value == linkType).detailApi;
                detailApi(linkID).then((resp) => {

                    if (resp) {
                        if (resp.collection) {
                            this.setState({
                                linkKeyword: resp.collection.title,
                                loading: false,
                                selectedLink: resp.collection,
                                name, hyper_link, linkType, disabled
                            })
                        } else {
                            this.setState({
                                linkKeyword: resp.title,
                                loading: false,
                                selectedLink: resp,
                                name, hyper_link, linkType, disabled
                            })
                        }
                    } else {
                        this.setState({name, hyper_link, linkType, disabled, loading: false})
                    }
                })
            } else {
                this.setState({name, hyper_link, linkType, disabled, loading: false})
            }
        })

    }

    submit() {
        tabConfigApi.createTabConfig(this.state).then(() => {
            confirmModal.alert("Sửa tab tùy biến thành công !");
        })
    }

    options = [{
        value: null
    }, {
        value: "Bộ sưu tập",
        api: collectionApi.getCollectionByKeyword,
        detailApi: collectionApi.getCollection
    }, {
        value: "Blog",
        api: blogApi.getBlogByKeyword,
        detailApi: blogApi.getBlog
    }, {
        value: "Hyper link",
        text: true,
    }];

    render() {
        const {name, hyper_link, disabled, loading, linkType} = this.state;

        return (
            <Layout
                activeRoute="Tab tùy biến"
            >
                <div className="tab-config-route web-manage-catalog-route">
                    <div className="card">
                        <div className="card-title">
                            Thiết lập tab tùy biến
                        </div>

                        {
                            loading ? <div>Đang tải...</div> : (
                                <Fragment>
                                    <Checkbox
                                        value={!disabled}
                                        onChange={(value) => {
                                            this.setState({disabled: !value})
                                        }}
                                        label="Hiện tab tùy chỉnh"
                                        style={{margin: "15px"}}
                                    />
                                    <div className="card-body">

                                        <Input
                                            label="Tên Tab*"
                                            value={name || ''}
                                            onChange={(e) => this.setState({name: e.target.value})}
                                            text
                                        />

                                        <Select
                                            label="Loại Link"
                                            value={linkType || null}
                                            list={this.options.map(o => o.value)}
                                            onChange={(linkType) => this.setState({
                                                linkType,
                                                linkID: null,
                                                linkKeyword: "",
                                                hyper_link: ""
                                            })}
                                            displayAs={(linkType) => {
                                                if (!linkType) return "Không";
                                                return linkType;
                                            }}
                                        />

                                        {linkType === "Hyper link" ?
                                            <Input
                                                label="Hyper Link*"
                                                value={hyper_link || ''}
                                                onChange={(e) => this.setState({hyper_link: e.target.value})}
                                                text
                                            /> : linkType && (
                                            <AutoComplete
                                                label={`Chọn ${linkType.toLowerCase()}`}
                                                asyncGet={(keyword) => {
                                                    this.setState({linkID: null});
                                                    if (keyword.length > 0) {
                                                        return this.options.find(o => linkType == o.value).api(keyword).then((resp) => resp)
                                                    }
                                                    return Promise.resolve([])
                                                }}
                                                ref={input => this.input = input}
                                                onSelect={(selectedLink) => {
                                                    this.setState({
                                                        selectedLink,
                                                        linkID: selectedLink._id
                                                    });
                                                    this.input.setValue(selectedLink.title)
                                                }}
                                                onChange={(value) => {
                                                    this.setState({selectedLink: {}, linkKeyword: value})
                                                }}
                                                objectKey="linkKeyword"
                                                object={this.state}
                                                displayAs={(selectedLink) => selectedLink.title}
                                                noPopup
                                            />)
                                        }

                                        <button className="btn btn-primary" onClick={() => this.submit()}>Lưu</button>
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}
