import React from "react";
import {footerContentApi} from "../../api/footer-api";
import {DataTable} from "../../components/data-table/data-table";
import {sortBy} from "lodash";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {ButtonGroup} from "../../components/button-group/button-group";
import {modals} from "../../components/modal/modals";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {FooterBannerModal} from "./footer-banner-modal";

export class FooterBanner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bannerSetting: null
        };

        footerContentApi.getBannerSetting().then((bannerSetting) => {
            this.setState({bannerSetting})
        })

    }

    handleUpdateStatus(row, key, value) {

        let {bannerSetting} = this.state;
        this.setState({
            bannerSetting: bannerSetting.map(f => f._id == row._id ? {...row, [key]: value} : f)
        });
        bannerSetting.editBannerSetting(row._id, {...row, [key]: value});
    }


    add() {
        const modal = modals.openModal({
            content: (
                <FooterBannerModal
                    banner={{
                        title: "",
                        image: null,
                        description: ""
                    }}
                    onDismiss={() => {
                        modal.close()
                    }}
                    onClose={() => {
                        footerContentApi.getBannerSetting().then((bannerSetting) => {
                            this.setState({bannerSetting});
                            modal.close();
                        })
                    }}
                />
            )
        })
    }

    edit(row) {
        console.log(row);
        const modal = modals.openModal({
            content: (
                <FooterBannerModal
                    banner={row}
                    onDismiss={() => {
                        modal.close()
                    }}
                    onClose={() => {
                        footerContentApi.getBannerSetting().then((bannerSetting) => {
                            this.setState({bannerSetting});
                            modal.close();
                        })
                    }}
                />
            )
        })

    }

    remove(row) {
        confirmModal.show({
            title: "Xóa lời nhận xét này?",
            description: "Bạn có đồng ý muốn xóa nhận xét này không?"
        }).then(() => {
            let {bannerSetting} = this.state;
            this.setState({bannerSetting: bannerSetting.filter(c => c._id != row._id)});
            footerContentApi.removeBannerSetting(row._id);
        })
    }

    render() {

        let {bannerSetting} = this.state;

        const columns = [{
            label: "STT",
            width: "5%",
            display: (row, index) => index + 1,
        }, {
            label: "Tên",
            width: "40%",
            display: (row) => row.title,
            minWidth: "150"
        }, {
            label: "Nhận xét",
            width: "25%",
            display: (row) => row.description,
        }, {
            label: "Ảnh",
            width: "25%",
            display: (row) => (
                <ImgPreview
                    src={row.image}
                    style={{
                        height: "60px"
                    }}
                />
            ),
        }, {
            label: "",
            width: "5%",
            display: (row) => (
                <ButtonGroup
                    actions={[{
                        name: "Sửa",
                        icon: <i className="fa fa-pencil"/>,
                        click: () => this.edit(row)
                    }, {
                        name: `${row.isHidden ? "Hiện" : "Ẩn"}`,
                        icon: <i className={`fa fa-eye${row.isHidden ? "" : "-slash"}`}/>,
                        click: () => this.handleUpdateStatus(row, "isHidden", !row.isHidden)
                    }, {
                        name: "Xóa",
                        icon: <i className="fa fa-trash text-danger"/>,
                        click: () => this.remove(row)
                    }]}
                />
            ),
            minWidth: "50"
        }];

        return (
            <div className="card">
                <div className="card-title">
                    Thiết lập Banner Footer
                </div>

                <div className="card-body">
                    <button type="button" className="btn btn-primary" onClick={() => this.add()}>Thêm</button>
                </div>

                <DataTable
                    rows={sortBy(bannerSetting, c => c.sortIndex)}
                    columns={columns}
                    sortable
                    onChangeRows={(bannerSetting) => {
                        let updated = bannerSetting.map((c, index) => ({...c, sortIndex: index}));
                        this.setState({bannerSetting: updated});
                        footerContentApi.updateBulkBanners(updated);
                    }}
                    rowStyling={(row) => {
                        if (row.isHidden) return {background: "rgba(253,57,122, .1)"};
                    }}
                />
            </div>
        );
    }
}
