import React from "react";
import {PictureUpload} from "../picture-upload/picture-upload";
import {InputUploadItem} from "./input-upload-item";
import {resizeImage} from "../../common/common";
import {uploadApi} from "../../api/upload-api";
export class InputUploadMultiple extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploading: false
        }
    }

    handleChange(e) {
        if (e.target.files[0]) {
            resizeImage(e.target.files[0]).then((file) => {
                this.setState({uploading: true});
                uploadApi.upload(file).then(resp => {
                    this.setState({uploading: false});
                    let {images = []} = this.props;
                    this.props.onChange(images.concat(resp.file));
                })
            })
        }
    }
    moveLeft (value) {
        const result = [...this.props.images];
        const imagesLength = result.length;
        let startIndex = value;
        let endIndex = startIndex -1;
       
        if(endIndex >= imagesLength) endIndex= 0;
        if(endIndex < 0) endIndex= imagesLength -1;       
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);    
        this.props.onChange(result);
    };
    moveRight (value) {
        const result = [...this.props.images];
        const imagesLength = result.length;
        let startIndex = value;
        let endIndex = startIndex +1;       
        if(endIndex >= imagesLength) endIndex= 0;
        if(endIndex < 0) endIndex= imagesLength -1;       
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);    
        this.props.onChange(result);
    };
    render() {

        let {images = [], label, onChange, error} = this.props;
        let {uploading} = this.state;

        return (
            <div className="input-upload-multiple">
                <div className="label">
                    {label}
                </div>

                <div className="images">
                    { images.map((image, index) => (
                        <InputUploadItem
                            key={index}
                            value={image}
                            onMoveLeft={() => {this.moveLeft(index)}}
                            onMoveRight={() => {this.moveRight(index)}}
                            onRemove={() => onChange(images.filter((item, i) => i != index))}
                        />
                    ))}

                    <div className="input-upload-placeholder"
                        onClick={() => {
                            this.inputUpload.click();
                        }}
                    >
                        { uploading ? <i className="fa fa-spinner fa-pulse" aria-hidden="true"/> : <i className="fa fa-plus"/>}
                    </div>
                </div>

                <input className="input-upload"
                       ref={elem => this.inputUpload = elem}
                       type="file"
                       accept="image/png, image/jpeg"
                       onChange={(e) => this.handleChange(e)}
                />

                <div className="error">
                    {error}
                </div>
            </div>
        );
    }
}
