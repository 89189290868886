import {api} from "./api";


export const footerContentApi = {
    getFooters: () => {
        return api.get("/api/footer-content")
    },
    editFooter: (data) => {
        return api.post("/api/footer-content", data)
    },
    getBannerSetting: () => {
        return api.get("/api/banner-setting")
    },
    editBannerSetting: (id, data) => {
        return api.put(`/api/banner-setting/${id}`, data)
    },
    createBannerSetting: (data) => {
        return api.post(`/api/banner-setting`, data)
    },
    removeBannerSetting: (id) => {
        return api.delete(`/api/banner-setting/${id}`);
    },
    updateBulkBanners: (data) => {
        return api.put("/api/banner-setting-update-bulk", data)
    }

}
