import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import moment from "moment";
import {formatNumber, getTotalBill} from "../../common/common";
import {ButtonGroup} from "../../components/button-group/button-group";
import {reviewOrderApi} from "../../api/review-order-api";
import {modals} from "../../components/modal/modals";
import {OrderReviewAcceptedModal} from "./order-review-accepted-modal";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {ImgPreview} from "../../components/img-repview/img-preview";
/*global firebase */
export class OrderReviewRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: null,
            total: 0,
        };

        this.ordersRef = firebase.database().ref(`order`);
        this.ordersRef.on('value', () => {
            this.table.reset();
        });

        const sendTokenToServer = (token) => {
            reviewOrderApi.setToken(token)
        };


        try {
            //firebase
           // const messaging = firebase.messaging();
            // Notification.requestPermission().then((permission) => {
            //     if (permission === 'granted') {
            //         messaging.getToken().then((currentToken) => {
            //             if (currentToken) {
            //                 sendTokenToServer(currentToken);
            //             } else {
            //                 console.log('No Instance ID token available. Request permission to generate one.');
            //             }
            //         });
            //     } else {
            //         console.log('Unable to get permission to notify.');
            //     }
            // });


            // messaging.onTokenRefresh(() => {
            //     messaging.getToken().then((refreshedToken) => {
            //         sendTokenToServer(refreshedToken);
            //     });
            // });

        } catch (e) {
            console.log(e);
        }

    }

    componentWillUnmount() {
        this.ordersRef.off("value");
    }

    acceptBill(bill) {
        const modal = modals.openModal({
            content: (
                <OrderReviewAcceptedModal
                    bill={bill}
                  
                    onClose={(premisesID, deliverTime) => {
                        reviewOrderApi.updateOrderDetail(bill._id, {
                            premises_id: premisesID,
                            status: "accepted",
                            deliverTime
                        }).then(() => {
                            modal.close();
                            this.table.reset();
                        })
                    }}
                    onDismiss={() => modal.close()}
                />
            )
        })
    }

    rejectBill(bill) {
        confirmModal.show({title: "Từ chối đơn hàng", description: "Bạn có đồng ý muốn từ chối đơn hàng này không?"}).then(() => {
            reviewOrderApi.updateOrderDetail(bill._id, {
                status: "rejected"
            }).then(() => {
                this.table.reset();
            })
        })
    }

    render() {

        let {orders, total, customers} = this.state;

        let columns = [{
            label: "Thời gian",
            display: (bill) => (
                <Fragment>
                    <div><b>{bill.bill_number}</b></div>
                    {moment(bill.deliverTime).format("DD/MM/YYYY")} {bill.deliverTimeText}
                    <div>Giờ tạo đơn: {moment(bill.created).format("DD/MM/YYYY HH:mm")}</div>
                    <br/>

                    { bill.items.map((item, index) => (
                        <div key={index}>
                            <ImgPreview
                                src={item.images[0]}
                                key={index}
                            />
                        </div>
                    ))}
                </Fragment>
            ),
            width: "20%",
            minWidth: "150",
            sortBy: (bill) => bill.deliverTime
        }, {
            label: "Thông tin đơn",
            display: (bill) => (
                <div>
                    {bill.items.map((item, index) => (
                        <div key={index}>
                            <b>{item.quantity}</b> {item.flowerType} {item.name}
                            <div className="text-small">Màu: {item.color.split(", ").map((c, i) => (
                                <div key={i}
                                     style={{
                                         background: c,
                                         display: "inline-block",
                                         marginRight: "5px",
                                         width: "15px",
                                         height: "10px"
                                     }}
                                />
                            ))}</div>

                            {item.size && (<div className="text-small">Size: <b>{item.size}</b></div>)}
                        </div>
                    ))}


                    {bill.vipSaleType && (
                        <div>VIP: <b>{bill.vipSaleType}</b></div>
                    )}

                    {bill.discountCode && (
                        <div>
                            Mã Giảm giá: <b className="text-primary">{bill.discountCode.code} ({formatNumber(bill.discountCode.discountNumber)}{bill.discountCode.codeType == "percent" ? "%" : ""})</b>
                        </div>
                    )}

                    <div style={{
                        marginTop: "10px"
                    }}>
                        <span>Tổng tiền: <b>{formatNumber(getTotalBill(bill))}</b></span>
                    </div>

                    <br/>

                    <div>Hình thức thanh toán: {bill.to.paymentType} {(bill.to.paymentType == "Web" || bill.to.paymentType == "VNPay") && (<span className="text-primary">({bill.paymentStatus == 1 ? "Thành công" : bill.paymentStatus == 0 ? "Đang chờ xác nhận" : "Lỗi"})</span>)}

                    </div>
                    { bill.to && bill.to.paymentType == 'paypal' &&  (<div>
                        <p>Order ID (paypal):{bill.logs?.tongHop?.orderID ?? ''}</p> 
                        <p>Tổng tiền: {bill.vnp_total}$</p>
                         </div>)}

                    <div>
                        Ghi chú: {bill.to.notes}
                    </div>

                    <div>
                        Nội dung thiệp: {bill.to.cardContent}
                    </div>
                </div>
            ),
            width: "30%",
            minWidth: "250"
        }, {
            label: "Thông tin khách",
            display: (bill) => {
                let customer = customers.find(c => c._id == bill.customerId);

                return (
                    <Fragment>
                        <div>
                            Bên mua:
                            <b>
                                <div>
                                    {customer.customerName}
                                </div>
                                <div>
                                    {customer.customerPhone}
                                </div>
                                <div>
                                    {customer.customerPlace}
                                </div>
                            </b>
                        </div>

                        <div style={{marginTop: "10px"}}>
                            Bên nhận:
                            <b>
                                <div>
                                    {bill.to.receiverName}
                                </div>
                                <div>
                                    {bill.to.receiverPhone}
                                </div>
                                <div>
                                    {bill.to.receiverPlace}
                                </div>
                            </b>
                        </div>
                    </Fragment>
                )
            },
            width: "30%",
            minWidth: "250"
        }, {
            label: "Trạng Thái",
            width: "15%",
            minWidth: "100",
            display: (bill) => (
                <div>
                    {!bill.status ? <span>Chờ xác nhận</span> : <span>Từ chối</span>}
                    { bill.accepted_by && (
                        <div>
                           Bởi: {bill.accepted_by}
                        </div>
                    )}
                </div>
            )
        }, {
            label: "",
            width: "5%",
            minWidth: "100",
            display: (bill) => !bill.status && (
                <ButtonGroup
                    actions={[{
                        name: `Xác nhận`,
                        icon: <i className="fa fa-check text-success"/>,
                        click: () => this.acceptBill(bill),
                    }, {
                        name: "Từ chối",
                        icon: <i className="fa fa-close text-danger"/>,
                        click: () => this.rejectBill(bill),
                    }]}
                />
            )
        }];


        return (
            <Layout activeRoute="Duyệt Đơn Hàng">
                <div className="card customers-route order-review-route bill-report-route bill-report-route-2">
                    <div className="card-title">
                        Đơn Hàng
                    </div>

                    <PaginationDataTable
                        ref={table => this.table = table}
                        placeholderSearch="Tìm kiếm mã đơn"
                        total={total}
                        columns={columns}
                        rows={orders}
                        api={({keyword, page, sortKey, isDesc}) => {
                            return reviewOrderApi.get({
                                keyword,
                                skip: (page - 1) * 15,
                                sortKey: !sortKey ? "deliverTime" : sortKey,
                                isDesc: isDesc === undefined ? 1 : isDesc
                            }).then(({customers, orders, total}) => {
                                this.setState({customers, orders, total});
                                return Promise.resolve();
                            })
                        }}
                        rowStyling={(bill) => {
                            if (bill.status == "rejected") return {background: "rgba(253,57,122, .1)"};
                        }}
                    />
                </div>
            </Layout>
        );
    }
}
