import React, {Fragment} from "react";
import {warehouseApi} from "../../../api/warehouse-api";
import {premisesInfo} from "../../../security/premises-info";
import classnames from "classnames";
import {ColumnViewMore} from "../../../components/column-view-more/column-view-more";
import {ImgPreview} from "../../../components/img-repview/img-preview";
import {InputNumber} from "../../../components/input-number/input-number";
import {Input} from "../../../components/input/input";
import {DataTable} from "../../../components/data-table/data-table";
import moment from "moment";
import uuid from "uuid/v4";
import {sumBy} from "lodash";
import {formatNumber} from "../../../common/common";
export class RequestOrderFlowerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flowersInWarehouse: null,
            request: props.request,
            products: []
        };

        warehouseApi.getRequestByID(props.request._id).then(({flowersInWarehouse, products}) => {
            this.setState({flowersInWarehouse, products})
        })
    }

    submit() {
        this.setState({saving: true, error: false});
        let {request, flowersInWarehouse} = this.state;

        const getQuantity = (row) => {
            let flowers = flowersInWarehouse.filter(f => f.parentID == row.parentID);
            return sumBy(flowers, "submitQuantity");
        };

        warehouseApi.updateRequest(request._id, {
            ...request,
            items: request.items.map(item => ({
                ...item,
                quantity: getQuantity(item),
                importedQuantity: getQuantity(item)
            }))
        }).then(() => {
            warehouseApi.acceptRequest(this.props.request._id, {requestItems: flowersInWarehouse.filter(f => f.submitQuantity)}).then((resp) => {
                if (resp && resp.error) {
                    warehouseApi.getRequestByID(this.props.request._id).then(({flowersInWarehouse, products}) => {
                        this.setState({flowersInWarehouse, products, error: resp.error, saving: false});
                    });
                } else this.props.onClose();
            })
        });

    }

    handleUpdateQuantity(flower, quantity) {
        let {flowersInWarehouse} = this.state;
        this.setState({
            flowersInWarehouse: flowersInWarehouse.map(item => {
                if (item._id == flower._id) return ({...item, submitQuantity: Math.min(item.quantity, quantity)});
                return item;
            })
        })
    }

    render() {

        let {onDismiss, onClose, suppliers} = this.props;
        let {flowersInWarehouse, saving, error, request, products, submitItems} = this.state;
        const premises = [{_id: "all", name: "Kho Công Ty"}, {_id: "company", name: "Kho Công Ty 2"}].concat(premisesInfo.getPremises());



        const columns = [{
            label: "Sản Phẩm",
            width: "30%",
            display: (item) => {
                let product = products.find(f => f._id == item.parentID);

                if (!product) return null;

                return (
                    <ColumnViewMore
                        header={
                            <div className="product-name">
                                <ImgPreview src={product.image}/> {product.name}

                                <div>
                                    Yêu cầu: <b>{item.quantity}</b>
                                </div>
                            </div>
                        }
                        renderViewMoreBody={() => (
                            <Fragment>
                                { request.requestType != "request-from-supplier" && (
                                    <div className="info-item">
                                        Nhà cung cấp: {suppliers.find(s => s._id == item.supplierID) && suppliers.find(s => s._id == item.supplierID).name}
                                    </div>
                                )}

                                <div className="info-item">
                                    {product.productID} - {product.catalog}
                                </div>

                                <div className="info-item">
                                    Màu:
                                    {product.colors.map((color, index) => (
                                        <div key={index}
                                             style={{
                                                 background: color,
                                                 height: "15px",
                                                 width: "25px",
                                                 display: "inline-block",
                                                 marginLeft: "5px"
                                             }}
                                        />
                                    ))}
                                </div>

                                <div className="info-item">
                                    Đơn Vị Tính: {product.unit}
                                </div>

                                { product.lengthiness && (
                                    <div className="info-item">
                                        Chiều Dài Cành Hoa: {product.lengthiness}
                                    </div>
                                )}
                            </Fragment>
                        )}
                        viewMoreText="Chi Tiết"
                        isShowViewMoreText
                    />
                )
            },
            minWidth: "300"
        }, {
            label: "Tồn Kho",
            width: "70%",
            minWidth: "100",
            display: (row) => {

                if (!flowersInWarehouse) return null;

                let flowers = flowersInWarehouse.filter(f => f.parentID == row.parentID);


                return <div className="flower-item">
                    {flowers.map((flower, index) => {
                        return (
                            <div className="flower-item-list" key={index}>
                                <InputNumber
                                    value={flower.submitQuantity || 0}
                                    onChange={(value) => {
                                        this.handleUpdateQuantity(flower, value);
                                    }}
                                /> / <b>{flower.quantity}</b>

                                <span className="info-display text-primary">{suppliers.find(s => s._id == flower.supplierID).name} - {moment(flower.created).format("DD/MM/YYYY")}</span>
                            </div>
                        )
                    })}

                    <div className="total">
                        Tổng xuất: <b>{formatNumber(sumBy(flowers, "submitQuantity"))}</b>
                    </div>
                </div>;
            }
        }];

        return (
            <div className="app-modal-box request-from-supplier request-preview-modal">
                <div className="modal-header">
                    <h6 className="modal-title">Chi tiết đơn</h6>
                    <button type="button" className="close" onClick={() => onDismiss()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="text-item">
                        <span><span><span className="text-primary">Order Hoa từ {request.fromWarehouse == "company" ? "Kho Công Ty 2" : "Kho Công Ty"}</span> - <b>{premises.find(p => p._id == (request.toWarehouse ? request.toWarehouse : "all")).name}</b></span></span>
                    </div>

                    <div className="row">
                        <Input
                            className="col"
                            label="Người Gửi"
                            value={request.requestName}
                            readOnly
                        />

                        <Input
                            className="col"
                            label="Người Nhận"
                            value={request.receivedName}
                            readOnly
                        />
                    </div>

                    { request.notes && (
                        <div className="info-item">
                            Ghi chú: {request.notes}
                        </div>
                    )}

                </div>

                <DataTable
                    columns={columns}
                    rows={request.items}
                />

                { error && (
                    <div className="error text-danger" style={{
                        padding: "10px 15px",
                        fontSize: "13px"
                    }}>
                        {error}
                    </div>
                )}

                <div className="modal-footer">
                    <button type="button" className="btn btn-link  ml-auto" data-dismiss="modal" onClick={() => onDismiss()}>Thôi</button>
                    <button type="button" className="btn btn-primary"
                            disabled={saving}
                            onClick={() => this.submit()}>
                        <span className="btn-text">
                            Xác Nhận
                        </span>

                        { saving && (<span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>)}

                    </button>
                </div>
            </div>
        );
    }
}
