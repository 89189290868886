import {api} from "./api";

export const promotionCodeApi = {
    get: () => {
        return api.get(`/api/promotion-code`);
    },
    update: (id, data) => {
        return api.put(`/api/promotion-code/${id}`, data);
    },
    create: (data) => {
        console.log(data);
        return api.post("/api/promotion-code", data);
    },
    delete: (id) => {
        return api.delete(`/api/promotion-code/${id}`);
    }
};