import React from "react";
import { formatNumber, getTotalBill } from "../../../common/common";
import { InputNumber } from "../../../components/input-number/input-number";
import { InputQuantity } from "../../../components/input-quantity/input-quantity";
import { DataTable } from "../../../components/data-table/data-table";
import { Select } from "../../../components/select/select";
import sumBy from "lodash/sumBy";
import { securityFe } from "../../../security/security-fe";

export class BillView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let { bill, onChangeItems, editMode, activePromotions = [], onChangeBill, paperBagQty } = this.props;
        const items = bill.items;

        const isEditable = !editMode ? true : securityFe.isHavePermission(["bill.editBillItem"]);



        let columns = [{
            label: "Tên",
            width: "30%",
            display: (item) => <div className="dt-col">{item.flowerType} {item.name}</div>,
            minWidth: "150"
        }, {
            label: "SL",
            width: "30%",
            display: (item) => (
                (isEditable) ? <InputQuantity
                    key={item._id}
                    value={item.quantity}
                    onChange={(value) => {
                        if (value <= 0) {
                            onChangeItems(items.filter(i => (i.id &&  i.id != item.id) || (i._id &&  i._id != item._id)));
                        } else {
                            var itemsNew = items.map(i => {
                                if ( (i._id && i._id == item._id) || (i.id && i.id == item.id)) return { ...i, quantity: value };
                                return i;
                            });
                          
                            onChangeItems(itemsNew)
                        }
                    }}
                    maxVal={item.isPaperBag ? paperBagQty : 99999999}
                /> : item.quantity
            ),
            minWidth: "150"
        }, {
            label: "Đơn Giá",
            width: "15%",
            display: (item) => <div className="dt-col">{formatNumber(item.price)}</div>,
            minWidth: "100"
        }, {
            label: "KM",
            width: "15%",
            display: (item) => (isEditable && !item.isPaperBag) ? <InputNumber
                maxVal={100}
                autoSelect
                value={item.sale || ""}
                onChange={(sale) => {
                    if (sale <= 100) {
                        onChangeItems(items.map(i => {
                            if (i.name == item.name) return { ...i, sale };
                            return i;
                        }))
                    }
                }}
            /> : (item.sale || 0),
            minWidth: "75"
        }, {
            label: "VAT",
            width: "15%",
            display: (item) => !item.isPaperBag ? <InputNumber
                maxVal={100}
                autoSelect
                value={item.vat || ""}
                onChange={(vat) => {
                    if (vat <= 100) {
                        onChangeItems(items.map(i => {
                            if (i.name == item.name) return { ...i, vat };
                            return i;
                        }))
                    }
                }}
            /> : 0,
            minWidth: "75"
        }, {
            label: "",
            width: "5%",
            display: (item) => (
                (isEditable) ? (
                    <button className="btn btn-small btn-danger remove-btn"
                        onClick={() => { onChangeItems(items.filter(i => i.name != item.name)) }}>
                        <i className="fa fa-trash" />
                    </button>
                ) : ""
            ),
            minWidth: "50"
        }];



        const getPriceBill = (bill) => sumBy(bill.items, item => item.price * item.quantity);



        return (
            <div className="card bill-view">
                <div className="card-title">
                    Thông Tin Đơn
                </div>

                <DataTable
                    rows={items}
                    columns={columns}
                />


                {items.length > 0 && (
                    <div className="card-body bill-view-bottom">

                        <div className="text-right">
                            Thành Tiền: <b>{formatNumber(getPriceBill(bill))}</b>
                        </div>

                        {activePromotions.length > 0 && (
                            <Select
                                label="Khuyến Mại"
                                value={bill.promotion ? bill.promotion.promotion_id : null}
                                onChange={(value) => {
                                    if (value == null) onChangeBill({ ...bill, promotion: null });
                                    else {
                                        const found = activePromotions.find(p => p._id == value);
                                        onChangeBill({
                                            ...bill, promotion: {
                                                promotion_id: found._id,
                                                name: found.name,
                                                discount: found.discount,
                                            }
                                        })
                                    }

                                }}
                                list={[null].concat(activePromotions.map(a => a._id))}
                                displayAs={(value) => {
                                    if (value == null) return "Không";
                                    const promotion = activePromotions.find(p => p._id == value);
                                    if (promotion) return <span>{promotion.name} - {promotion.discount}%</span>
                                }}
                            />
                        )}

                        {bill.vipSaleType && (
                            <div className="text-right">
                                VIP: <b>{bill.vipSaleType}</b>
                            </div>
                        )}

                        {editMode && bill.promotion && (
                            <div className="text-right">
                                {bill.promotion.name}: {bill.promotion.discount}% <i className="fa fa-trash text-danger" style={{ cursor: "pointer" }} onClick={() => onChangeBill({ ...bill, promotion: null })} />
                            </div>
                        )}

                        {bill.payOwe && bill.customerInfo && (
                            <div className="text-right">
                                Thanh toán nợ: <b>{formatNumber(bill.customerInfo.spend.totalOwe)}</b>
                            </div>
                        )}

                        {bill.discountCode && (
                            <div className="text-right">
                                Mã Giảm giá: <b className="text-primary">{bill.discountCode.code} ({formatNumber(bill.discountCode.discountNumber)}{bill.discountCode.codeType == "percent" ? "%" : ""})</b>
                            </div>
                        )}

                        <Select
                            className="select-upgrade"
                            label="Tăng định lượng hoa"
                            value={bill.upgradeFlower}
                            onChange={(value) => onChangeBill({
                                ...bill,
                                upgradeFlower: value
                            })}
                            list={[null, "10% định lượng", "15% định lượng", "20% định lượng", "30% định lượng"]}
                            displayAs={(value) => {
                                if (value === null) return "Không";
                                if (value == "true") return "10% định lượng";
                                return value
                            }}
                        />

                        {!editMode && bill.customer._id && (
                            <InputNumber
                                className="select-upgrade"
                                label={`Sử dụng điểm (${bill.customer.wallet_points})`}
                                maxVal={bill.customer.wallet_points || 0}
                                value={bill.wallet_points_used}
                                onChange={(val) => onChangeBill({
                                    ...bill,
                                    wallet_points_used: val
                                })}
                            />
                        )}

                        {bill.to.paymentType == "E-Card" && (
                            <div className="text-right">
                                E-Card: <b>-{formatNumber(getTotalBill({ ...bill, e_wallet_used: false }))}</b>
                            </div>
                        )}

                        <div className="text-right">
                            Tổng Tiền: <b>{bill.to.paymentType == "E-Card" ? 0 : formatNumber(getTotalBill(bill))}</b>
                        </div>
                    </div>
                )}


            </div>
        );
    }
}
