import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {DatePicker} from "../../components/date-picker/date-picker";
import {defaultReportDayService} from "../app-router";
import {Select} from "../../components/select/select";
import sum from "lodash/sum";
import {formatNumber, getTotalBill} from "../../common/common";
import {securityFe} from "../../security/security-fe";
import {customerApi} from "../../api/customer-api";
import {premisesInfo} from "../../security/premises-info";
import {ColumnViewMore} from "../../components/column-view-more/column-view-more";
import sortBy from "lodash/sortBy";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import moment from "moment";

export class CustomerNotBuy extends React.Component {

    constructor(props) {
        super(props);
        let to = new Date();
        to.setHours(23,59, 59, 99);

        let from = new Date();
        from.setMonth(to.getMonth() - 3);
        from.setHours(0, 0 ,0 ,0);

        this.state = {
            from,
            to,
            loading: true
        };
    }


    render() {

        let {customers, from, to, loading, total, bills} = this.state;

        let columns = [{
            label: "Khách Hàng",
            width: "25%",
            display: (row) => {
                const getTotalPay = (customerId) => {
                    const customerBills = bills.filter(b => b.customerId == customerId);
                    return sum(customerBills.map(b => getTotalBill(b)))
                };

                let totalPay = getTotalPay(row._id);

                let options = [];

                if (totalPay < 100000000) {
                    options.push("VIP Platinum")
                }

                if (totalPay < 15000000) {
                    options.push("VIP Gold")
                }

                options.push("Không");

                return (
                    <div className="customer-name">
                        {row.customerName}

                        <div style={{
                            marginTop: "15px"
                        }}>
                            { options.length > 1 && securityFe.isHavePermission(["customer.update-vip-customer"]) && (
                                <Select
                                    label="Nâng cấp VIP"
                                    list={options.reverse()}
                                    value={row.customVip}
                                    onChange={(customVip) => {
                                        customerApi.updateCustomer(row._id, {customVip});
                                        this.setState({
                                            customers: customers.map((c => c._id == row._id ? ({
                                                ...row,
                                                customVip
                                            }) : c))
                                        })
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )
            },
            sortKey: "customerName",
            minWidth: "200"
        }, {
            label: "Điện Thoại",
            width: "20%",
            display: (row) => row.customerPhone,
            sortKey: "customerPhone",
            minWidth: "150"
        }, {
            label: "Tổng Tiền",
            width: "20%",
            display: (row) => {
                const getTotalPay = (customerId, isOwe) => {
                    let _bills = [];
                    if (isOwe) _bills = bills.filter(b => b.isOwe);

                    const customerBills = _bills.filter(b => b.customerId == customerId);
                    return sum(customerBills.map(b => getTotalBill(b)))
                };


                const getPayOfPremises = (customerId, premises_id) => {
                    const customerBills = bills.filter(b => b.customerId == customerId && b.premises_id == premises_id);
                    return sum(customerBills.map(b => getTotalBill(b)))
                };

                let premises = premisesInfo.getPremises();

                return (
                    <ColumnViewMore
                        header={formatNumber(row.totalPay)}
                        renderViewMoreBody={() => premises.filter(p => getPayOfPremises(row._id, p._id) > 0).map((p, index) => (
                            <div className="info-item" key={index}>
                                {p.name}: <b>{formatNumber(getPayOfPremises(row._id, p._id))}</b>
                            </div>
                        ))}
                        viewMoreText="Chi Tiết"
                        subText={getTotalPay(row._id, true) > 0 && <div className="text-danger">Nợ: {formatNumber(getTotalPay(row._id, true))}</div>}
                        isShowViewMoreText={row.totalPay > 0}
                    />
                )
            },
            sortKey: "totalPay",
            minWidth: "200"
        }, {
            label: "Tổng Đơn",
            width: "15%",
            display: (row) => formatNumber(row.totalBill),
            sortKey: "totalBill",
            minWidth: "100"
        }, {
            label: "Đơn gần nhất",
            width: "20%",
            display: (row) => row.lastBillDate && <span>{moment(row.lastBillDate).format("DD/MM/YYYY")}</span>,
            sortKey: "lastBillDate",
            minWidth: "100"
        }];


        return (
            <Layout
                activeRoute="Khách hàng chưa quay lại"
            >
                <div className="card customers-route bill-report-route">
                    <div className="card-title">
                        Khách hàng chưa quay lại gần đây

                        { total && <span className="text-small text-primary">{total} Khách</span>}
                    </div>

                    <div className="card-body">
                        <div className="row first-margin"
                        >
                            <DatePicker
                                className="col"
                                label="Từ Ngày"
                                value={from}
                                onChange={(from) => {
                                    this.setState({from});
                                    defaultReportDayService.set({...defaultReportDayService.get(), from});
                                }}
                            />

                            <DatePicker
                                className="col"
                                label="Tới Ngày"
                                value={to}
                                onChange={(to) => {
                                    this.setState({to});
                                    defaultReportDayService.set({...defaultReportDayService.get(), to});
                                }}
                            />

                            <button className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({loading: true});
                                        this.table.submitChange();

                                    }}
                                    disabled={loading}
                            >
                                <span className="btn-text">Xem</span>
                                {loading &&
                                <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                            </button>
                        </div>
                    </div>

                    <PaginationDataTable
                        ref={(elem) => this.table = elem}
                        total={total}
                        columns={columns}
                        rows={customers}
                        api={({keyword, page, sortKey, isDesc}) => {
                            return customerApi.getCustomerNotBuy({
                                keyword,
                                skip: (page - 1) * 15,
                                sortKey,
                                isDesc,
                                from,
                                to
                            }).then(({customers, bills, total}) => {
                                this.setState({customers, bills, total, loading: false});
                                return Promise.resolve();
                            })
                        }}
                    />
                </div>
            </Layout>
        );
    }
}