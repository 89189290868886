import React from "react";

export const LoginIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 19">
        <g fill="none" fillRule="evenodd" stroke="#000" transform="translate(1 1)">
            <path d="M16 17v-2a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v2h16z"/>
            <circle cx="8" cy="4" r="4"/>
        </g>
    </svg>
);

export const BasketIcon = ({fill = "none"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" fillRule="evenodd" stroke="#000"
         strokeLinejoin="round">
        <path d="M2 4.318v9.625c0 .76.597 1.375 1.333 1.375h9.333c.736 0 1.333-.616 1.333-1.375V4.318H2z"
              fill={fill}/>
        <path
            d="M10.5 5.273V3.045c0-.17-.016-.334-.047-.494C10.227 1.382 9.215.5 8 .5c-.824 0-1.555.406-2 1.032-.162.223-.3.475-.374.746-.075.242-.116.5-.116.768v2.227"
            strokeLinecap="round"/>
    </svg>
);

export const PhoneIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
        <path fill="none" fillRule="evenodd" stroke="#000"
              d="M21 15.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 3.11 1h3a2 2 0 0 1 2 1.72c.127.96.361 1.903.7 2.81a2 2 0 0 1-.45 2.11L7.09 8.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 1.85.573 2.81.7A2 2 0 0 1 21 15.92z"/>
    </svg>
);

export const BurgerIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
        <g fill="none" fillRule="evenodd" stroke="#000" strokeLinecap="round">
            <path d="M1 7h18M1 1h18M1 13h18"/>
        </g>
    </svg>
);

export const NavIcon = () => (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="16" height="16"/>
                <path
                    d="M15,10.1366782 C15,10.9316625 14.3071014,11.55 13.4523677,11.55 L6.64278573,11.55 L3.85704764,14.5 L3.85704764,11.55 C3.41525851,11.55 3.00255657,11.55 2.61894183,11.55 C1.8025658,11.55 0.981446894,11.0933469 1.00031922,10.1366782 C1.04764611,7.73760092 1.07130955,5.33852364 1.07130955,2.93944637 C1.07130955,2.14446209 1.76420812,1.5 2.61894183,1.5 L13.4523677,1.5 C14.3071014,1.5 15,2.14446209 15,2.93944637 L15,10.1366782 Z"
                    id="Path" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"
                    transform="translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) "/>
            </g>
        </g>
    </svg>
);

export const ArrowDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="2 5 8 11 14 5"/>
    </svg>
);

export const CloseXIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
);

export const MinusIcon = () => (
    <svg width="11" height="11" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 42 42" xmlSpace="preserve">
        <rect y="19" width="42" height="4"/>
    </svg>
);

export const PlusIcon = () => (
    <svg version="1.1" width="11" height="11" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 42 42" xmlSpace="preserve">
        <polygon points="42,19 23,19 23,0 19,0 19,19 0,19 0,23 19,23 19,42 23,42 23,23 42,23 "/>
    </svg>
);

export const ChatIcon = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px" width="25px" height="25px" viewBox="0 0 510 510" xmlSpace="preserve">
        <path
            d="M459,0H51C22.95,0,0,22.95,0,51v459l102-102h357c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z"/>
    </svg>

);

export const SendIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
)

