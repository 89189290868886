import React from "react";
import { Layout } from "../../components/layout/layout";

import { modals } from "../../components/modal/modals";
import { ManageGeoModal } from "./manage-geo-modal";
import { confirmModal } from "../../components/confirm-modal/confirm-modal";
import { DataTable } from "../../components/data-table/data-table";
import { ButtonGroup } from "../../components/button-group/button-group";
import { GeoApi } from "../../api/geo-api";
import { Select } from "../../components/select/select";
import { Input } from "../../components/input/input";
import { googleMapsApi } from "../../api/google-maps-api";

export class ManageGeoRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tinhThanh: [],
            quanHuyen: [],
            phuongXa: [],
            tinhThanhValue: null,
            phuongXaValue: null,
            keyword: ''
        };
    }

    componentDidMount() {
        this.getAllTinhThanh();
    }

    editGeo(content) {
        var {phuongXa} = this.state;
        var address = `Nhà văn hóa ${content.ten}, ${content.ten}, ${content.thuoc_ten}, ${content.thuoc_ten2}, Việt nam`;
        googleMapsApi.getPlaceGeo(address).then(value => {
            GeoApi.geoUpdate({ma: content.ma, point: value}).then(({error, data}) => {
                if(!error) {
                    phuongXa.find(f => f.ma == content.ma).point = value;
                    this.forceUpdate();
                }
               
            })
        })
    }



    handleUpdateGeo() {

    }

    getAllTinhThanh() {
        GeoApi.geoTinhThanhList().then(({ error, data }) => {
            if (!error) {
                this.setState({ tinhThanh: data, quanHuyen: [], phuongXa: [], phuongXaValue: null, keyword: '' });
            }
        });
    }

    handleSelectTinhThanh(item) {
        GeoApi.geoQuanHuyenList({ thuoc_ma: item.ma }).then(({ error, data }) => {
            if (!error) {
                this.setState({ quanHuyen: data, phuongXa: [], keyword: '', tinhThanhValue: item });
            }
        });
    }
    handleSelectQuanHuyen(item) {
        GeoApi.geoPhuongXaList({ thuoc_ma: item.ma, thuoc_ma2: item.thuoc_ma }).then(({ error, data }) => {

            if (!error) {                
                this.setState({ phuongXa: data, quanHuyenValue: item }, () => {
                    (data || []).filter(f => f.point == null).forEach(f => {
                        this.editGeo(f);
                    })
                });

            }
        });
    }
    render() {

        let { tinhThanh, quanHuyen, phuongXa, tinhThanhValue, quanHuyenValue, keyword } = this.state;
        let phuongXaLs = phuongXa.filter(f => new RegExp(".*" + keyword + ".*", "i").test(f.ten));
        let columns = [{
            label: "Mã",
            width: "45%",
            display: (row) => row.ma,
            sortBy: (row) => row.ma,
            minWidth: "150"
        }, {
            label: "Tên",
            width: "45%",
            display: (row) => row.ten,
            sortBy: (row) => row.ten,
            minWidth: "300"
        }, {
            label: "Vị trí",
            width: "45%",
            display: (row) => {
                return (<div>
                    {row.point && ( <span> lat: <strong> {row.point.lat}</strong>-lng: <strong>{row.point.lng}</strong> </span>)}                   
                    <div className="pointer" onClick={() => this.editGeo(row)} >
                   <strong>{('Cập nhật')}</strong> 
                </div>
                    </div>);

            },
            minWidth: "300"
        }];


        return (
            <Layout
                activeRoute="Định vị Phường Xã"
            >
                <div className="card">
                    <div className="" style={{ display: 'flex', gap: '10px' }}>
                        <Select
                            list={tinhThanh}
                            value={tinhThanhValue}
                            displayAs={(item) => {
                                return item ? item?.ten : "";
                            }}
                            onChange={(value) => this.handleSelectTinhThanh(value)}
                            label="Tỉnh Thành"
                        />
                        <Select
                            list={quanHuyen}
                            value={quanHuyenValue}
                            onChange={(value) => this.handleSelectQuanHuyen(value)}
                            displayAs={(item) => {
                                return item ? item.ten : '';
                            }}
                            label="Quận Huyện"
                        />
                        <Input
                            value={keyword}
                            onChange={(e) => this.setState({ keyword: e.target.value })}
                        />
                    </div>
                    <div className="card-title">
                        Danh Sách địa chỉ
                    </div>
                    <DataTable
                        rows={phuongXaLs}
                        columns={columns}
                    />
                </div>

            </Layout>
        );
    }
}
