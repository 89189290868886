import React, {Fragment} from "react";
import {Form} from "../../components/form/form";
import {Input} from "../../components/input/input";
import {InputNumber} from "../../components/input-number/input-number";
import {DatePicker} from "../../components/date-picker/date-picker";
import {maxVal, minLength, minVal, required} from "../../components/form/validations";
import {customerApi} from "../../api/customer-api";
import {AutoComplete} from "../../components/auto-complete/auto-complete";
import {vipApi} from "../../api/vip-api";
import {Select} from "../../components/select/select";

export class DiscountCodesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            discount: props.discount,
            error: false,
            customer: props.customer
        }
    }


    render() {

        let {onDismiss, onClose} = this.props;
        let {discount, error, customer, saving} = this.state;

        const validations = [
            {
                "code": [required("Mã"), minLength(5, "Mã"), () => ({
                    text: "Mã trùng",
                    valid: !error
                })]
            },
            {
                "discountNumber": [(val) => ({
                        text: "Giảm giá không được vượt quá 100%",
                        valid: discount.codeType == "percent" ? val <= 100 : true
                    }
                ), minVal("Giảm giá", 0)]
            },
            {"customerID": [required("Khách hàng")]},
            {"reason": [required("Lý do")]}
        ];


        return (
            <div className="app-modal-box manage-promotion-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{discount._id ? `Sửa mã ${this.props.discount.code}` : "Thêm mã"}</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <Form
                        onSubmit={() => {
                            this.setState({saving: true});
                            onClose(discount, customer).then(() => {
                                this.setState({saving: false, error: true});
                            });
                        }}
                        formValue={discount}
                        validations={validations}
                        render={(getInvalidByKey) => (
                            <Fragment>
                                <div className="modal-body">

                                    <AutoComplete
                                        asyncGet={(phone) => {
                                            if (phone.length > 3) {
                                                return customerApi.getCustomerByPhone(phone).then((resp) => resp.customers)
                                            }

                                            return Promise.resolve([])
                                        }}
                                        onSelect={(customer) => {
                                            this.setState({
                                                customer,
                                                discount: {...discount, customerID: customer._id}
                                            })
                                        }}
                                        objectKey="customerPhone"
                                        object={customer}
                                        onChange={(value) => {
                                            this.setState({
                                                customer: {
                                                    ...customer,
                                                    customerPhone: value,
                                                    _id: null
                                                }, error: false, discount: {...discount, customerId: null}
                                            })
                                        }}
                                        displayAs={(customer) => `${customer.customerPhone} - ${customer.customerName}`}
                                        noPopup
                                        label="Số Điện Thoại Khách"
                                    />

                                    {customer._id && (
                                        <Fragment>
                                            <Input
                                                label="Mã"
                                                value={discount.code}
                                                onChange={(e) => this.setState({
                                                    discount: {
                                                        ...discount,
                                                        code: e.target.value.toUpperCase().replace(/[^A-Za-z0-9]/g, "")
                                                    },
                                                    error: false
                                                })}
                                                error={getInvalidByKey("code")}
                                            />

                                            <Select
                                                list={["percent", "number"]}
                                                value={discount.codeType}
                                                onChange={(type) => this.setState({
                                                    discount: {
                                                        ...discount,
                                                        codeType: type
                                                    }
                                                })}
                                                displayAs={(value) => value == "percent" ? "%" : "Tiền mặt"}
                                                label="Loại"
                                                error={getInvalidByKey("codeType")}
                                            />

                                            <InputNumber
                                                label="Giảm giá"
                                                value={discount.discountNumber}
                                                onChange={(e) => this.setState({
                                                    discount: {
                                                        ...discount,
                                                        discountNumber: e
                                                    }
                                                })}
                                                error={getInvalidByKey("discountNumber")}
                                            />

                                            <Input
                                                label="Lý do"
                                                textArea
                                                value={discount.reason}
                                                onChange={(e) => this.setState({
                                                    discount: {
                                                        ...discount,
                                                        reason: e.target.value
                                                    }
                                                })}
                                                error={getInvalidByKey("reason")}
                                            />

                                        </Fragment>
                                    )}


                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        <span className="btn-text">Lưu</span>
                                        {saving &&
                                        <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    />


                </div>
            </div>
        );
    }
}
