import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {customerApi} from "../../api/customer-api";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import {blogApi} from "../../api/blog-api";
import sum from "lodash/sum";
import {formatNumber, getTotalBill} from "../../common/common";
import {premisesInfo} from "../../security/premises-info";
import {ColumnViewMore} from "../../components/column-view-more/column-view-more";
import sortBy from "lodash/sortBy";
import moment from "moment";
import {ImgPreview} from "../../components/img-repview/img-preview";
import {ButtonGroup} from "../../components/button-group/button-group";
import {webManageProductApi} from "../../api/web-manage-product-api";
import {modals} from "../../components/modal/modals";
import {BlogPreviewModal} from "./blog-preview-modal";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";

export class BlogRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: null,
            total: 0,
        };
    }

    handleUpdateStatus(row, key, value) {

        let {list} = this.state;
        this.setState({
            list: list.map(f => f._id == row._id ? {...row, [key]: value} : f)
        });
        blogApi.updateBlog(row._id, {...row, [key]: value});
    }

    preview(row) {
        const modal = modals.openModal({
            content: (
                <BlogPreviewModal
                    blog={row}
                    onDismiss={() => modal.close()}
                />
            )
        })
    }

    remove(row) {
        confirmModal.show({
            title: "Xóa bài này?",
            description: "Bạn có đồng ý muốn xóa bài không?"
        }).then(() => {
            blogApi.removeBlog(row._id).then((resp) => {
                this.table.refresh();
            });
        })
    }


    render() {

        let {list, total} = this.state;
        let {history} = this.props;


        let columns = [{
            label: "Ảnh / Thời gian",
            width: "20%",
            display: (row) => (
                <div>
                    <ImgPreview
                        src={row.imagePreview}
                        style={{marginBottom: "10px", width: "100%"}}
                    />

                    {moment(row.created).format("DD/MM/YYYY")}
                </div>
            ),
            sortKey: "created",
            minWidth: "100"
        }, {
            label: "Tiêu đề",
            width: "25%",
            display: (row) => row.title,
            sortKey: "title",
            minWidth: "150"
        }, {
            label: "Danh mục",
            width: "20%",
            display: (row) => row.catalog,
            sortKey: "catalog",
            minWidth: "200"
        }, {
            label: "Nội dung",
            width: "25%",
            display: (row) => (
                <div>
                    {row.description}
                    <br/>
                    <a className="text-primary" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.preview(row)
                    }} style={{paddingTop: "10px", cursor: "pointer", fontSize: "12px"}}>Chi tiết</a>
                </div>
            ),
            sortKey: "description",
            minWidth: "200"
        }, {
            label: "Trạng thái",
            width: "5%",
            display: (row) => row.isHidden ? <span className="text-danger">Đang ẩn</span> : <span className="text-success">Đang hiện</span>,
            sortKey: "isHidden",
            minWidth: "100"
        }, {
            label: "",
            width: "5%",
            display: (row) => (
                <ButtonGroup
                    actions={[{
                        name: "Sửa",
                        icon: <i className="fa fa-pencil"/>,
                        click: () => history.push(`/web-manage-blog/edit/${row._id}`)
                    }, {
                        name: `${row.isHidden ? "Hiện bài" : "Ẩn bài"}`,
                        icon: <i className={`fa fa-eye${row.isHidden ? "" : "-slash"}`}/>,
                        click: () => this.handleUpdateStatus(row, "isHidden", !row.isHidden)
                    }, {
                        name: "Xóa",
                        icon: <i className="fa fa-trash text-danger"/>,
                        click: () => this.remove(row)
                    }]}
                />
            ),
            minWidth: "50"
        }];


        return (
            <Layout
                activeRoute="Blog"
            >
                <div className="blog-route web-manage-catalog-route">
                    <div className="card">
                        <div className="card-title">
                            Quản Lý Blog
                        </div>

                        <div className="card-body">
                            <button type="button" className="btn btn-primary" onClick={() => history.push("/web-manage-blog/new")}>Thêm Bài</button>
                        </div>

                        <PaginationDataTable
                            total={total}
                            columns={columns}
                            rows={list}
                            rowStyling={(row) => {
                                if (row.isHidden) return {background: "rgba(253,57,122, .1)"};
                            }}
                            api={({keyword, page, sortKey, isDesc}) => {
                                return blogApi.getBlogList({
                                    keyword,
                                    skip: (page - 1) * 15,
                                    sortKey,
                                    isDesc
                                }).then(({list, total}) => {
                                    this.setState({list, total});
                                    return Promise.resolve();
                                })
                            }}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}
