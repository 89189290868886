import React, {Fragment} from "react";
import {PaginationDataTable} from "../pagination-data-table/pagination-data-table";
import {customerApi} from "../../api/customer-api";
import {Layout} from "../../components/layout/layout";
import {AutoComplete} from "../../components/auto-complete/auto-complete";
import {InputGroup} from "../../components/input/input-group";
import {Select} from "../../components/select/select";
import {Input} from "../../components/input/input";
import {InputTag2} from "../../components/input-tag/input-tag-2";
import {premisesInfo} from "../../security/premises-info";
import {viaTypes} from "../../common/constance";
import {DatePicker} from "../../components/date-picker/date-picker";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";

export class CustomerChangePhone extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: {
                customerPhone: ""
            },
            newPhone: ""
        }
    }

    submit() {
        this.setState({saving: true});
        let {customer, newPhone} = this.state;
        customerApi.updateCustomerPhone({customerId: customer._id, newPhone}).then(() => {
            confirmModal.alert("Đổi số thành công");
            this.setState({saving: false, customer: {customerPhone: ""}, newPhone: ""})
        })
    }

    render() {

        let {customer, newPhone, saving} = this.state;

        return (
            <Layout
                activeRoute="Đổi số điện thoại"
            >
                <div className="card customers-route">
                    <div className="card-title">
                        Đổi số điện thoại
                    </div>

                    <div className="card-body">
                        <AutoComplete
                            asyncGet={(phone) => {
                                if (phone.length > 3) {
                                    return customerApi.getCustomerByPhone(phone).then((resp) => resp.customers)
                                }

                                return Promise.resolve([])
                            }}
                            onSelect={(customer) => this.setState({customer})}
                            objectKey="customerPhone"
                            object={customer}
                            onChange={(value) => {
                                this.setState({
                                    customer: {
                                        customerPhone: value
                                    }
                                })
                            }}
                            displayAs={(customer) => `${customer.customerPhone} - ${customer.customerName}`}
                            noPopup
                            label="Số cũ"
                            info={customer._id && <span>{customer.customerName}</span>}
                        />



                        <Input
                            value={newPhone}
                            onChange={(e) => this.setState({newPhone: e.target.value})}
                            label="Số mới"
                        />


                        <div className="text-right btn-action">
                            <button type="button"
                                    disabled={saving || !customer._id || newPhone.length < 10}
                                    className="btn btn-primary "
                                    onClick={() => this.submit()}>
                                <span className="btn-text">Đổi số</span>
                                {saving && <span className="loading-icon"><i
                                    className="fa fa-spinner fa-pulse"/></span>}
                            </button>
                        </div>

                    </div>




                </div>
            </Layout>
        );
    }
}
