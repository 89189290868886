import React, {Fragment} from "react";
import {Layout} from "../../components/layout/layout";
import {LeftSide} from "./left-side/left-side";
import {BillView} from "./bill-view/bill-view";
import {BillCustomer} from "./customer/bill-customer";
import {billApi} from "../../api/bill-api";
import {customerApi} from "../../api/customer-api";
import omit from "lodash/omit";
import {formatValue, getTotalBill} from "../../common/common";
import {permissionInfo, premisesInfo} from "../../security/premises-info";
import {Form} from "../../components/form/form";
import {confirmModal} from "../../components/confirm-modal/confirm-modal";
import {PrintService} from "../../common/print-service/print-service";
import {BillPrint} from "./print/bill-print";
import {cache} from "../../common/cache";
import {BillInfo} from "./bill-info/bill-info";
import {securityApi} from "../../api/security-api";
import {userInfo} from "../../security/user-info";
import {promotionApi} from "../../api/promotion-api";
import {RComponent} from "../../components/r-component/r-component";
import {securityFe} from "../../security/security-fe";
import {discountCodeApi} from "../../api/discount-code-api";
import {productApi} from "../../api/product-api";
import {sumBy} from "lodash";
import {Select} from "../../components/select/select";
import {EWalletBill} from "./e-wallet-bill/e-wallet-bill";


export const initBill = {
    items: [],
    customer: {
        customerName: "",
        customerPhone: "",
        customerPlace: ""
    },
    customerInfo: null,
    to: {
        receiverPhone: "",
        receiverName: "",
        receiverPlace: "",
        cardContent: "",
        notes: "",
        paymentType: "Shop",
        shipMoney: 0,
        buyerFrom: null
    },
    deliverTime: new Date(),
    sales: [],
    florists: [],
    ships: [],
    payOwe: false,
    surchargeMember: [],
    surcharge: 0
};

export class BillRoute extends React.Component {

    constructor(props) {
        super(props);

        const user = userInfo.getUser();
        let route = securityFe.getDefaultRoute(user);
        if (route != "/") props.history.push(route);


        this.state = {
            bill: initBill,
            saving: false,
            locations: [],
            sales: [],
            florists: [],
            ships: [],
            activePromotions: [],
            selectedPromotion: null,
            billGiftTypes: [],
            paperBagQty: null,
            viewMode: "Đơn Thường"
        };

        billApi.getPaperBags().then((items) => {
            this.setState({paperBagQty: sumBy(items, i => i.quantity)})
        });

        productApi.getGiftTypes().then((billGiftTypes) => {
            this.setState({billGiftTypes});
        });




        securityApi.getSalesAndFlorist().then((users) => {

            const mapItem = (u) => ({
                user_id: u._id,
                name: u.name,
                username: u.username,
                salaryFormula: u.salaryFormula
            });

            this.setState({
                sales: users.filter(u => u.role == "sale").map(mapItem),
                florists: users.filter(u => u.role == "florist").map(mapItem),
                ships: users.filter(u => u.role == "ship").map(mapItem)
            })
        });

        promotionApi.get().then((promotions) => {
            let activePromotions = [];
            for (let promotion of promotions) {
                let isHave = new Date(promotion.from).getTime() < new Date().getTime() && new Date().getTime() < new Date(promotion.to).getTime();
                if (isHave) activePromotions.push(promotion)
            }

            this.setState({activePromotions});

            if (activePromotions.length > 0) {
                this.setState({
                    bill: {
                        ...this.state.bill, promotion: {
                            promotion_id: activePromotions[0]._id,
                            name: activePromotions[0].name,
                            discount: activePromotions[0].discount,
                        }
                    }
                })
            }
        });

        premisesInfo.onChange(() => {

            billApi.getPaperBags().then((items) => {
                console.log(items);
                this.setState({paperBagQty: sumBy(items, i => i.quantity)})
            });

            this.setState({
                bill: initBill,
                locations: []
            })
        })
    }

    submitBill(bill) {

        this.setState({saving: true});

        let premises = premisesInfo.getPremises();

        const getCurrentPremise = () => {
            const activeID = cache.get("active-premises");
            if (!activeID) {
                cache.set(premises[0]._id, "active-premises");
                return premises[0]._id;
            }
            const found = premises.find(p => p._id == activeID);
            if (found) return found._id;
            else {
                cache.set(premises[0]._id, "active-premises");
                return premises[0]._id
            }
        };

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const endDay = new Date();
        endDay.setHours(23, 59, 59, 99);

        const getCustomerID = () => {
            if (!bill.customer._id) {
                return customerApi.createCustomer(bill.customer).then((customer) => {
                    return customer._id
                })
            }

            return customerApi.updateCustomer(bill.customer._id, omit(bill.customer, "_id")).then(() => {
                return bill.customer._id
            });
        };




        getCustomerID().then((customerID) => {
            billApi.getBillNumbers({from: today, to: endDay}).then(({bills}) => {

                billApi.createBill({
                    ...omit(bill, "customerInfo"),
                    bill_number: `${formatValue(today.getDate())}${formatValue(today.getMonth() + 1)}${today.getFullYear().toString().substring(2)}${formatValue((bills.length || 0) + 1)}`,
                    customerId: customerID,
                    premises_id: getCurrentPremise(),
                    status: "Chờ xử lý",
                    created_by: userInfo.getUser().username,
                    created: new Date(),
                    isNewCustomer: !bill.customer._id,
                    isOwe: bill.to.paymentType == "Nợ",
                    e_wallet_used: bill.to.paymentType == "E-Card"
                }).then((_b) => {

                    if (_b.error) {
                        confirmModal.alert(_b.error);
                        billApi.getPaperBags().then((items) => {
                            this.setState({paperBagQty: sumBy(items, i => i.quantity)})
                        });

                        this.setState({
                            bill: initBill,
                            locations: []
                        })
                    } else {
                        if (_b.discountCode) {
                            discountCodeApi.applyDiscountCode(_b.discountCode.discount_id, _b._id);
                        }

                        billApi.getPaperBags().then((items) => {
                            this.setState({paperBagQty: sumBy(items, i => i.quantity)})
                        });


                        this.setState({
                            bill: {
                                ...initBill,
                                deliverTime: bill.deliverTime
                            },
                            saving: false
                        }, () => {
                            let {activePromotions} = this.state;
                            if (activePromotions.length > 0) {
                                this.setState({
                                    bill: {
                                        ...this.state.bill,
                                        promotion: {
                                            promotion_id: activePromotions[0]._id,
                                            name: activePromotions[0].name,
                                            discount: activePromotions[0].discount,
                                        }

                                    }
                                })
                            }
                        });
                        this.billCustomer.setVipPay(false);

                        PrintService.printBill({
                            body: (
                                <BillPrint
                                    bill={{
                                        ...bill,
                                        bill_number: `${formatValue(today.getDate())}${formatValue(today.getMonth() + 1)}${today.getFullYear().toString().substring(2)}${formatValue((bills.length || 0) + 1)}`,
                                        isOwe: bill.to.paymentType == "Nợ",
                                        created_by: userInfo.getUser().username,
                                        e_wallet_used: bill.to.paymentType == "E-Card"
                                    }}
                                />
                            )
                        });
                    }

                })
            })
        });

    }

    saveDraftBill(bill) {
        this.setState({savingDraft: true});

        let premises = premisesInfo.getPremises();

        const getCurrentPremise = () => {
            const activeID = cache.get("active-premises");
            if (!activeID) {
                cache.set(premises[0]._id, "active-premises");
                return premises[0]._id;
            }
            const found = premises.find(p => p._id == activeID);
            if (found) return found._id;
            else {
                cache.set(premises[0]._id, "active-premises");
                return premises[0]._id
            }
        };

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const endDay = new Date();
        endDay.setHours(23, 59, 59, 99);

        const getCustomerID = () => {
            if (!bill.customer._id) {
                return customerApi.createCustomer(bill.customer).then((customer) => {
                    return customer._id
                })
            }

            return customerApi.updateCustomer(bill.customer._id, omit(bill.customer, "_id")).then(() => {
                return bill.customer._id
            });
        };

        getCustomerID().then((customerID) => {
            billApi.createBillDraft({
                ...bill,
                customerId: customerID,
                premises_id: getCurrentPremise(),
                status: "Chờ xử lý",
                created_by: userInfo.getUser().username,
                created: new Date(),
                isNewCustomer: !bill.customer._id,
                isOwe: bill.to.paymentType == "Nợ"
            }).then(() => {
                confirmModal.alert("Lưu thành công");
                billApi.getPaperBags().then((items) => {
                    this.setState({paperBagQty: sumBy(items, i => i.quantity)})
                });

                this.setState({
                    bill: initBill,
                    savingDraft: false
                }, () => {
                    let {activePromotions} = this.state;
                    if (activePromotions.length > 0) {
                        this.setState({
                            bill: {
                                ...this.state.bill,
                                promotion: {
                                    promotion_id: activePromotions[0]._id,
                                    name: activePromotions[0].name,
                                    discount: activePromotions[0].discount,
                                }

                            }
                        })
                    }
                });
                this.billCustomer.setVipPay(false);

            })

        });
    }

    reformatBill(bill) {
        let total = getTotalBill(bill);

        return {
            ...bill,
            items: (bill.items || []).map((item) => ({
                ...item,
                price: (item.name == "Túi giấy") ? total >= 1000000 ? 0 : 35000 : item.price
            }))
        }
    }

    render() {

        let {saving, locations, florists, sales, ships, savingDraft, activePromotions, billGiftTypes, paperBagQty, viewMode} = this.state;

        const permission = permissionInfo.getPermission();
        const user = userInfo.getUser();

        const bill = this.reformatBill(this.state.bill);





        return (
            <Layout
                activeRoute="Hoá Đơn"
            >
                <div className="bill-route">


                    <div className="card">
                        <div className="card-title">
                            Loại hóa đơn
                        </div>

                        <div className="card-body">
                            <Select
                                style={{marginBottom: 0}}
                                value={viewMode}
                                list={["Đơn Thường"].concat(securityFe.isHavePermission(["bill.create-e-wallet-bill"]) ? ["Mua Thẻ E-Card"] : [])}
                                onChange={(v) => this.setState({viewMode: v})}
                            />
                        </div>
                    </div>

                    { viewMode == "Đơn Thường" ? (
                        <div className="row">
                            <div className="col-md-4">
                                <LeftSide
                                    items={bill.items}
                                    onChangeItems={(items) => this.setState({bill: {...bill, items}})}
                                    paperBagQty={paperBagQty}
                                />
                            </div>

                            <div className="col-md-8">
                                <BillView
                                    activePromotions={activePromotions}
                                    bill={bill}
                                    onChangeBill={(bill) => this.setState({bill})}
                                    onChangeItems={(items) => this.setState({bill: {...bill, items}})}
                                    paperBagQty={paperBagQty}
                                />

                                <Form
                                    formValue={bill.customer}
                                    validations={[]}
                                    render={() => (
                                        <Fragment>
                                            <BillCustomer
                                                ref={elem => this.billCustomer = elem}
                                                bill={bill}
                                                onChangeBill={(bill) => this.setState({bill})}
                                                onChangeLocations={(locations) => this.setState({locations})}
                                                customer={bill.customer}
                                                onChange={(customer) => {
                                                    this.setState({bill: {...bill, customer}})
                                                }}
                                                infoComponent={() => (
                                                    <BillInfo
                                                        bill={bill}
                                                        onChangeBill={(bill) => this.setState({bill})}
                                                        florists={florists}
                                                        sales={sales}
                                                        ships={ships}
                                                        ref={elem => this.billInfo = elem}
                                                        locations={locations}
                                                        deliverTime={bill.deliverTime}
                                                        onChangeDeliverTime={(deliverTime) => this.setState({
                                                            bill: {
                                                                ...bill,
                                                                deliverTime,
                                                                to: {
                                                                    ...bill.to
                                                                }
                                                            }
                                                        })}
                                                        to={bill.to}
                                                        onChange={(to) => this.setState({bill: {...bill, to}})}
                                                        billGiftTypes={billGiftTypes}
                                                    />
                                                )}
                                            />


                                            <div className="text-right btn-action">
                                                <button type="button" className="btn btn-link"
                                                        style={{
                                                            marginRight: "10px"
                                                        }}
                                                        onClick={() => this.saveDraftBill(bill)}
                                                        disabled={bill.items.length == 0 || savingDraft}
                                                >
                                                    <span className="btn-text">Lưu đơn sẵn</span>
                                                    {savingDraft && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}

                                                </button>

                                                <button type="button"
                                                        disabled={
                                                            bill.items.length == 0 ||
                                                            saving ||
                                                            bill.customer.customerName.length == 0 ||
                                                            !bill.customer.gender||
                                                            !bill.customer.city ||
                                                            !bill.customer.ageRanges ||
                                                            bill.customer.customerPhone.length == 0 ||
                                                            bill.to.receiverName.length == 0 ||
                                                            bill.to.receiverPhone.length == 0 ||
                                                            bill.to.receiverPlace.length == 0 ||
                                                            !bill.to.billGiftType ||
                                                            !bill.to.buyerFrom ||
                                                            !bill.to.paymentType
                                                        }
                                                        className="btn btn-primary "
                                                        onClick={() => this.submitBill(bill)}>
                                                    <span className="btn-text">Bán Hàng</span>
                                                    {saving && <span className="loading-icon"><i
                                                        className="fa fa-spinner fa-pulse"/></span>}
                                                </button>
                                            </div>
                                        </Fragment>
                                    )}
                                />
                            </div>
                        </div>
                    ) : <EWalletBill/>}
                </div>
            </Layout>
        );
    }
}
