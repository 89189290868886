import React, {Fragment} from "react";
import {Form} from "../../components/form/form";
import {Input} from "../../components/input/input";
import {maxVal, minVal, required} from "../../components/form/validations";
import {InputNumber} from "../../components/input-number/input-number";
import {DatePicker} from "../../components/date-picker/date-picker";
import { Select } from "../../components/select/select";
export class ManagePromotionCodeModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            promotion: props.promotion
        }
    }

    render() {

        let {promotion, saving} = this.state;
        let {onDismiss, onClose} = this.props;


        const validations = [
            {"ma" : [required("Mã khuyến mại")]},
            {"ten" : [required("Tên khuyến mại")]},
            {"giaTri" : [required("Giá trị"), minVal("Giá trị", 0), maxVal("Giá trị", promotion.loai == 'tm'? 99999999:100) ]},
            {"soLuong" : [required("Số lượng"), minVal("Số lượng", 1)]},
            {"batDau" : [(val) => ({
                    text: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc",
                    valid: new Date(val).getTime() <= new Date(promotion.ketThuc).getTime()
                })
            ]},
            {"ketThuc" : [(val) => ({
                    text: "Ngày kết thúc phải lớn hơn ngày bắt đầu thúc",
                    valid: new Date(val).getTime() >= new Date(promotion.batDau).getTime()
                })
            ]}
        ];
        const listLoai = [{value: 'tm', label: 'Tiền mặt'}, {value:'ck', label: 'Chiết khấu %'}];
        return (
            <div className="app-modal-box manage-promotion-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{promotion._id ? `Sửa mã khuyến mại ${promotion.name}` : "Thêm khuyến mại"}</h5>
                        <button type="button" className="close" onClick={() => onDismiss()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <Form
                        onSubmit={() => {
                           // console.log(1,promotion);
                           this.setState({saving: true});
                           onClose(promotion);
                        }}
                        formValue={promotion}
                        validations={validations}
                        render={(getInvalidByKey) => (
                            <Fragment>
                                <div className="modal-body">
                                    <Input
                                        label="Mã giảm giá"
                                        value={promotion.ma}
                                        onChange={(e) => this.setState({promotion: {...promotion, ma: e.target.value}})}
                                        error={getInvalidByKey("ma")}
                                    />
                                    <Input
                                        label="Tên giảm giá"
                                        value={promotion.ten}
                                        onChange={(e) => this.setState({promotion: {...promotion, ten: e.target.value}})}
                                        error={getInvalidByKey("ten")}
                                    />
                                    <Select 
                                    label="Loại giảm giá"
                                    value={promotion.loai}
                                    onChange={(item) => {                                       
                                        this.setState({promotion: {...promotion, loai: item}})}}
                                    displayAs={(item) => listLoai.find(f => f.value == item)?.label ?? ''}
                                    list={listLoai.map(m => m.value)}
                                    />
                                    <InputNumber
                                        value={promotion.giaTri}
                                        onChange={(value) => this.setState({promotion: {...promotion, giaTri: value}})}
                                        label="Giảm giá"
                                        error={getInvalidByKey("giaTri")}
                                    />
                                    <InputNumber
                                        value={promotion.soLuong}
                                        onChange={(value) => this.setState({promotion: {...promotion, soLuong: value}})}
                                        label="Số lượng"
                                        error={getInvalidByKey("soLuong")}
                                    />

                                    <DatePicker
                                        label="Từ Ngày"
                                        value={new Date(promotion.batDau)}
                                        onChange={(date) => this.setState({promotion: {...promotion, batDau: date}})}
                                        error={getInvalidByKey("batDau")}
                                    />

                                    <DatePicker
                                        label="Tới Ngày"
                                        value={new Date(promotion.ketThuc)}
                                        onChange={(date) => this.setState({promotion: {...promotion, ketThuc: date}})}
                                        error={getInvalidByKey("ketThuc")}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-link" onClick={() => onDismiss()}>Đóng</button>
                                    <button type="submit" className="btn btn-primary">
                                        <span className="btn-text">Lưu</span>
                                        { saving && <span className="loading-icon"><i className="fa fa-spinner fa-pulse"/></span>}
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    />


                </div>
            </div>
        );
    }
}
