import React from 'react';
import {Layout} from "../../components/layout/layout";
import { AboutSetting } from '../setting/about-setting';
import {FooterContent} from "../setting/footer-content";
import {WarningSetting} from "../setting/warning-setting";
export class FooterSettingRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return(
            <Layout
                activeRoute="Thiết lập Footer"
            >
                <div className="footer-setting-route web-manage-catalog-route">

                    <AboutSetting/>
                    <WarningSetting/>

                    <FooterContent/>
                </div>
            </Layout>
        )
    }
}