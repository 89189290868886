import {api} from "./api";

export const dichvuhoaApi = {
    
    get: (id) => {
        return api.get(`/api/dich-vu-hoa/${id}`);
    },
    update: (id, data) => {
        return api.post(`/api/dich-vu-hoa/${id}`, data)
    },
   

};
